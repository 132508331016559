import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { onSendMediaNotificationSuccess } from "../actions/SendNotification";
import { SEND_MEDIA_NOTIFICATION } from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import api from "../drivers";
import get from "lodash/get";

const sendingNotification = async ({ id, mediaType, text }) => {
  const url = `${apisDetail.apisSendNotification}/${mediaType}/${id}`;
  const params = {
    text
  };

  return await api
    .post(url, params)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.sendMediaSuccess" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.sendMediaError" />
      );
      throw error;
    });
};

/**
 * Define Saga action
 */
function* fetchSendNotificationRequest(action) {
  try {
    const sendNotification = yield call(sendingNotification, action);
    yield put(onSendMediaNotificationSuccess(sendNotification));
    action.callback && action.callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Define Saga action
 */

/**
 * Receive Redux Action
 */
function* fetchSendNotification() {
  yield takeEvery(SEND_MEDIA_NOTIFICATION, fetchSendNotificationRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchSendNotification)]);
}
