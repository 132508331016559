import {
  GET_TOTAL_USER,
  GET_TOTAL_USER_SUCCESS,
  GET_TOTAL_USER_FAILED
} from "../../constants/ActionTypes";

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TOTAL_USER: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_TOTAL_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        totalUser: action.payload
      };
    }
    case GET_TOTAL_USER_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
};
