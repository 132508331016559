import {
  GET_PAGE,
  FETCH_ALL_PAGE_SUCCESS,
  DELETE_PAGE,
  DELETE_PAGE_SUCCESS,
  UPDATE_PAGE,
  UPDATE_PAGE_FAILED,
  FETCH_UPDATE_PAGE_SUCCESS,
  CREATE_PAGE,
  FETCH_CREATE_PAGE_SUCCESS,
  GET_DETAIL_PAGE,
  GET_DETAIL_PAGE_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  pagesList: [],
  idPage: "",
  data: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DETAIL_PAGE: {
      return {
        ...state,
        data: {},
        idPage: action.idPage,
        loading: true
      };
    }

    case GET_DETAIL_PAGE_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    }

    case GET_PAGE: {
      return {
        ...state,
        pagesList: action.pagesList,
        loading: true,
      };
    }

    case FETCH_ALL_PAGE_SUCCESS: {
      return {
        ...state,
        pagesList: action.payload,
        loading: false,
      };
    }

    case DELETE_PAGE: {
      return {
        ...state,
        status: 'DELETING',
        idPage: action.idPage
      };
    }

    case DELETE_PAGE_SUCCESS: {
      return {
        ...state,
        status: 'DELETED'
      };
    }

    case UPDATE_PAGE: {
      return {
        ...state,
        updating: true
      };
    }

    case UPDATE_PAGE_FAILED: {
      return {
        ...state,
        updating: false
      }
    }

    case FETCH_UPDATE_PAGE_SUCCESS: {
      return {
        ...state,
        pagesList: action.payload,
        updating: false
      };
    }

    case CREATE_PAGE: {
      return {
        ...state,
        data: action.data
      };
    }

    case FETCH_CREATE_PAGE_SUCCESS: {
      return {
        ...state,
        pagesList: action.payload
      };
    }

    default:
      return state;
  }
};
