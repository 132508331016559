import {
    GET_CONFIG,
    UPDATE_EVENT_KEY,
    UPDATE_EVENT_KEY_SUCCESS,
    UPDATE_EVENT_KEY_FAILED,
    UPDATE_EVENT_LINK,
    UPDATE_EVENT_LINK_SUCCESS,
    UPDATE_EVENT_LINK_FAILED,
    UPDATE_EVENT_BACKGROUND,
    UPDATE_EVENT_BACKGROUND_SUCCESS,
    UPDATE_EVENT_BACKGROUND_FAILED,
    FETCH_ALL_CONFIG_SUCCESS
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    configList: [],
    selectedConfigID: "",
    data: {}
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_CONFIG:
        return {
          ...state,
          gettingList: true,
          action: action.data
        };
      case FETCH_ALL_CONFIG_SUCCESS:
        return {
            ...state,
            gettingList: false,
            data: action.payload.data
        };

        case UPDATE_EVENT_KEY: {
            return {
              ...state,
              updating: true,
              config_name: action.config_name
            };
          }
          case UPDATE_EVENT_KEY_SUCCESS: {
            return {
              ...state,
              updating: false,
              dataResponse: action.payload.data
            };
          }
          case UPDATE_EVENT_KEY_FAILED: {
            return {
              ...state,
              updating: false
            };
        }

        case UPDATE_EVENT_LINK: {
            return {
              ...state,
              updating: true,
              config_name: action.config_name
            };
          }
          case UPDATE_EVENT_LINK_SUCCESS: {
            return {
              ...state,
              updating: false,
              dataResponse: action.payload.data
            };
          }
          case UPDATE_EVENT_LINK_FAILED: {
            return {
              ...state,
              updating: false
            };
        }

        case UPDATE_EVENT_BACKGROUND: {
            return {
              ...state,
              updating: true,
              config_name: action.config_name
            };
          }
          case UPDATE_EVENT_BACKGROUND_SUCCESS: {
            return {
              ...state,
              updating: false,
              dataResponse: action.payload.data
            };
          }
          case UPDATE_EVENT_BACKGROUND_FAILED: {
            return {
              ...state,
              updating: false
            };
        }
        
      default:
        return state;
    }
  };
  