import {
    GET_GREETINGS,
    FETCH_ALL_GREETINGS_SUCCESS,
    DELETE_GREETINGS,
    DELETE_GREETINGS_SUCCESS,
    DELETE_GREETINGS_FAILED
  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    greetingsList: [],
    selectedGreetingsID: "",
    data: {}
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_GREETINGS:
        return {
          ...state,
          gettingList: true,
          actions: {
            searchText: action.payload.name,
            limitItem: action.payload.limit,
          }
        };
      case FETCH_ALL_GREETINGS_SUCCESS:
        return {
            ...state,
            gettingList: false,
            greetingsList: action.payload
        };
        case DELETE_GREETINGS: {
            return {
              ...state,
              loading: true,
              idGreetings: action.idGreetings
            };
          }
          case DELETE_GREETINGS_SUCCESS: {
            return {
              ...state,
              loading: false
            };
          }
      default:
        return state;
    }
  };
  