import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_TOTAL_USER } from "../../constants/ActionTypes";
import {
  getTotalUserSuccess,
  getTotalUserFailed
} from "../actions/UserAnalytic";
import api from "../drivers";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";

const getTotalUser = async () =>
  await api
    .get(apisDetail.apisTotalUser)
    .then(resData => resData.data)
    .catch(error => error);

/**
 * Define Saga action
 */
function* fetchTotalUserRequest() {
  try {
    const result = yield call(getTotalUser);
    yield put(getTotalUserSuccess(result));
  } catch (error) {
    yield put(getTotalUserFailed(error));
  }
}

/**
 * Receive Redux Action
 */
function* fetchTotalUser() {
  yield takeEvery(GET_TOTAL_USER, fetchTotalUserRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchTotalUser)]);
}
