const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'vietnamese',
    locale: 'vi',
    name: 'Tiếng Việt',
    icon: 'vn'
  }
];
export default languageData;
