import {
  GET_VIDEO,
  FETCH_ALL_VIDEO_SUCCESS,
  DELETE_VIDEO,
  DELETE_VIDEO_SUCCESS,
  UPDATE_VIDEO,
  UPDATE_VIDEO_FAILED,
  FETCH_UPDATE_VIDEO_SUCCESS,
  CREATE_VIDEO,
  CREATE_VIDEO_FAILED,
  FETCH_CREATE_VIDEO_SUCCESS,
  GET_DETAIL_VIDEO,
  GET_DETAIL_VIDEO_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  dataResponse: {},
  videosList: [],
  idVideo: "",
  data: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VIDEO: {
      return {
        ...state,
        loadingList: true,
        actions: {
          searchText: action.payload.name,
          limitItem: action.payload.limit,
          selectedTab: action.payload.video_type,
          filteredInfo: action.payload.channelIdsProps,
          sortedInfo: {
            columnKey: action.payload.sort_by,
            field: action.payload.sort_by,
            order: action.payload.order_by
          }
        }
      };
    }
    case FETCH_ALL_VIDEO_SUCCESS: {
      return {
        ...state,
        loadingList: false,
        videosList: action.payload
      };
    }
    case GET_DETAIL_VIDEO: {
      return {
        ...state,
        data: {},
        loadingDetail: true,
        idVideo: action.idVideo
      };
    }
    case GET_DETAIL_VIDEO_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loadingDetail: false
      };
    }
    case DELETE_VIDEO: {
      return {
        ...state,
        loading: true,
        idVideo: action.idVideo
      };
    }
    case DELETE_VIDEO_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case UPDATE_VIDEO: {
      return {
        ...state,
        updating: true,
        idVideo: action.idVideo
      };
    }
    case FETCH_UPDATE_VIDEO_SUCCESS: {
      return {
        ...state,
        updating: false,
        dataResponse: action.payload.data
      };
    }
    case UPDATE_VIDEO_FAILED: {
      return {
        ...state,
        updating: false
      };
    }
    case CREATE_VIDEO: {
      return {
        ...state,
        creating: true,
        data: action.data
      };
    }
    case FETCH_CREATE_VIDEO_SUCCESS: {
      return {
        ...state,
        creating: false,
        dataResponse: action.payload.data
      };
    }
    case CREATE_VIDEO_FAILED: {
      return {
        ...state,
        creating: false
      };
    }
    default:
      return state;
  }
};
