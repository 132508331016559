import {
  GET_ADVERTISES,
  FETCH_ALL_ADVERTISES_SUCCESS,
  baseReturnedAction,
  GET_ADVERTISERS,
  GET_ADVERTISERS_SUCCESS,
  GET_USER_LIST_CAMPAIGNS,
  GET_USER_LIST_CAMPAIGNS_SUCCESS,
  GET_DETAIL_CAMPAIGN,
  GET_DETAIL_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  GET_LIST_CAMPAIGN_ADS,
  GET_LIST_CAMPAIGN_ADS_SUCCESS,
  GET_CAMPAIGN_CHART,
  GET_CAMPAIGN_CHART_SUCCESS
} from "../../constants/ActionTypes";


export const onGetAdvertises = queries => {
  return {
    ...baseReturnedAction,
    isAuthRequestApi: true,
    type: GET_ADVERTISES,
    payload: queries
  };
};

export const fetchAdvertisesSuccess = advertises => {
  return {
    type: FETCH_ALL_ADVERTISES_SUCCESS,
    payload: advertises
  };
};


// get list ad users

export const getListAdvertisers = data => {
  return {
    type: GET_ADVERTISERS,
    payload: data
  }
}

export const getListAdvertisersSuccess = data => {
  return {
    type: GET_ADVERTISERS_SUCCESS,
    payload: data
  }
}

// get list ad users

// get list campaign of user
export const getUserListCampaigns = data => {
  return {
    type: GET_USER_LIST_CAMPAIGNS,
    payload: data
  }
}
export const getUserListCampaignsSuccess = data => {
  return {
    type: GET_USER_LIST_CAMPAIGNS_SUCCESS,
    payload: data
  }
}

// get campaign detail
export const getDetailCampaign = data => {
  return {
    type: GET_DETAIL_CAMPAIGN,
    payload: data
  }
}
export const getDetailCampaignSuccess = data => {
  return {
    type: GET_DETAIL_CAMPAIGN_SUCCESS,
    payload: data
  }
}

// get list campaign ads
export const getListCampaignAds = data => {
  return {
    type: GET_LIST_CAMPAIGN_ADS,
    payload: data
  }
}
export const getListCampaignAdsSuccess = data => {
  return {
    type: GET_LIST_CAMPAIGN_ADS_SUCCESS,
    payload: data
  }
}

// create campaign
export const createCampaign = (data, callback) => {
  return {
    type: CREATE_CAMPAIGN,
    payload: data,
    callback
  }
}

export const createCampaignSuccess = data => {
  return {
    type: CREATE_CAMPAIGN_SUCCESS,
    payload: data
  }
}

// get campaign chart
export const getCampaignChart = data => {
  return {
    type: GET_CAMPAIGN_CHART,
    payload: data
  }
}

export const getCampaignChartSuccess = data => {
  return {
    type: GET_CAMPAIGN_CHART_SUCCESS,
    payload: data
  }
}
// get campaign chart