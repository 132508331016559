import {
  GET_FEEDBACKS,
  FETCH_ALL_FEEDBACKS_SUCCESS,
  DELETE_FEEDBACK_SUCCESS,
  FETCH_UPDATE_FEEDBACK_SUCCESS,
  GET_DETAIL_FEEDBACK_SUCCESS,
  GET_DETAIL_FEEDBACK,
  DELETE_FEEDBACK
} from "../../constants/ActionTypes";

const INIT_STATE = {
  feedbacksList: [],
  feedbackDetail: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DETAIL_FEEDBACK: {
      return {
        ...state,
        feedbackDetail: null
      }
    }
    case GET_DETAIL_FEEDBACK_SUCCESS: {
      return {
        ...state,
        feedbackDetail: action.payload
      };
    }
    case GET_FEEDBACKS:
      return {
        ...state,
        feedbacksList: action.feedbacksList,
        gettingList: true
      };
    case FETCH_ALL_FEEDBACKS_SUCCESS: {
      return {
        ...state,
        feedbacksList: action.payload,
        gettingList: false
      };
    }
    case DELETE_FEEDBACK: {
      return {
        ...state,
        status: 'DELETING'
      }
    }
    case DELETE_FEEDBACK_SUCCESS: {
      return {
        ...state,
        status: 'DELETED'
      };
    }
    case FETCH_UPDATE_FEEDBACK_SUCCESS: {
      return {
        ...state,
        feedbacksList: action.payload
      };
    }
    default:
      return state;
  }
};
