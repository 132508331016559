import { AdsSvg, StatisticSvg, DashboardSvg } from "../images/svg";

export default {
  menus: [
    {
      key: "main/dashboard",
      to: "/main/dashboard",
      className: "gx-menu-group",
      titleKey: "sidebar.dashboard",
      messageKey: "sidebar.dashboard",
      svgIcon: DashboardSvg,
      permission: "dashboard"
      // items: [
      //   // {
      //   //   key: "main/dashboard/statistic",
      //   //   to: "/main/dashboard/statistic",
      //   //   className: "icon own-tachometer",
      //   //   messageKey: "sidebar.advertises.statistics",
      //   //   icon: 'bar_chart',
      //   //   permission: "dashboard.ads"
      //   // },
      //   {
      //     key: "main/dashboard/user-analytics",
      //     to: "/main/dashboard/user-analytics",
      //     className: "icon own-tachometer",
      //     messageKey: "sidebar.dashboard.userAnalytics",
      //     icon: 'bar_chart',
      //     permission: "dashboard.userAnalytics"
      //   }
      // ]
    },
    {
      key: "media",
      className: "gx-menu-group",
      titleKey: "sidebar.media",
      icon: "perm_media",
      permission: "media",
      items: [
        {
          key: "main/media/channel",
          to: "/main/media/channel",
          className: "icon icon-crypto",
          messageKey: "sidebar.media.channel",
          icon: "subscriptions",
          permission: "media"
        },
        {
          key: "main/media/movie",
          to: "/main/media/movie",
          className: "icon icon-crypto",
          messageKey: "sidebar.media.movie",
          icon: "movie",
          permission: "media.movie"
        },
        {
          key: "main/media/interactiveMovie",
          to: "/main/media/interactiveMovie",
          className: "icon icon-crypto",
          messageKey: "sidebar.media.interactiveMovie",
          icon: "videocam",
          permission: "media.interactive_movie"
        },
        {
          key: "main/media/video",
          to: "/main/media/video",
          className: "icon icon-crypto",
          messageKey: "sidebar.media.video",
          icon: "video_library",
          permission: "media.video"
        },
        {
          key: "main/media/music",
          to: "/main/media/music",
          className: "icon icon-crypto",
          messageKey: "sidebar.media.music",
          icon: "music_note",
          permission: "media.music"
        },
        {
          key: "main/media/television",
          to: "/main/media/television",
          className: "icon icon-crypto",
          messageKey: "sidebar.media.television",
          icon: "personal_video",
          permission: "media.television"
        },
        {
          key: "main/media/contentBlacklist",
          to: "/main/media/contentBlacklist",
          className: "content-blacklist",
          messageKey: "sidebar.media.contentBlacklist",
          icon: "lock",
          permission: "media"
        },
        // {
        //   key: "main/media/transcode",
        //   to: "/main/media/transcode",
        //   className: "icon icon-crypto",
        //   messageKey: "sidebar.media.transcodes",
        //   icon: 'code',
        //   permission: 'media.transcode'
        // },
        {
          key: "main/media/page",
          to: "/main/media/page",
          className: "icon icon-crypto",
          messageKey: "sidebar.media.page",
          icon: "filter_none",
          permission: "media.page"
        },
        {
          key: "main/media/ribbonBanner",
          to: "/main/media/ribbonBanner",
          className: "icon icon-crypto",
          messageKey: "sidebar.media.ribbonBanner",
          messageKeyPartner: "sidebar.media.ribbon",
          icon: "collections",
          permission: "media.ribbonsbanners"
        },
        {
          key: "main/media/cast",
          to: "/main/media/cast",
          className: "icon icon-crypto",
          messageKey: "sidebar.media.cast",
          icon: "perm_contact_calendar",
          permission: "media.cast"
        },
        {
          key: "main/media/genre",
          to: "/main/media/genre",
          className: "icon icon-crypto",
          messageKey: "sidebar.media.genre",
          icon: "local_offer",
          permission: "media.genre"
        },
        {
          key: "main/media/config",
          to: "/main/media/config",
          className: "icon icon-setting",
          messageKey: "sidebar.media.config",
          icon: "settings",
          permission: "media.config"
        },
        {
          key: "main/media/greetings",
          to: "/main/media/greetings",
          className: "icon icon-refer",
          messageKey: "sidebar.media.greetings",
          icon: "sticky_note_2",
          permission: "media.greetings"
        },
      ]
    },
    {
      key: "feedback",
      className: "gx-menu-group",
      titleKey: "sidebar.components.discussion",
      icon: "feedback",
      permission: "discussion",
      items: [
        {
          key: "main/feedback/platform",
          to: "/main/feedback/platform",
          className: "icon icon-feedback",
          messageKey: "sidebar.feedback.app",
          icon: "feedback",
          permission: "discussion.feedback"
        },
        {
          key: "main/feedback/user-comment",
          to: "/main/feedback/user-comment",
          className: "icon icon-feedback",
          messageKey: "sidebar.feedback.reviews",
          icon: "comment",
          permission: "discussion.comment"
        },
        {
          key: "main/feedback/movie-review",
          to: "/main/feedback/movie-review",
          className: "icon icon-feedback",
          messageKey: "sidebar.feedback.movie.reviews",
          icon: "announcement",
          permission: "discussion.review.movie"
        },
        // To do
        // {
        //   key: "main/feedback/store-review",
        //   to: "/main/feedback/store-review",
        //   className: "icon icon-feedback",
        //   messageKey: "sidebar.feedback.store.reviews",
        //   icon: 'store',
        //   permission: "discussion.review.store"
        // },
        {
          key: "main/feedback/blacklist",
          to: "/main/feedback/blacklist",
          className: "icon icon-feedback",
          messageKey: "sidebar.feedback.blacklist",
          icon: "block",
          permission: "discussion.blacklist"
        }
      ]
    },
    {
      key: "user",
      className: "gx-menu-group",
      titleKey: "sidebar.users",
      icon: "people",
      permission: "user",
      items: [
        {
          key: "main/user/browse",
          to: "/main/user/browse",
          className: "icon own-users",
          messageKey: "sidebar.users.browse",
          icon: "people",
          permission: "user.manage"
        },
        {
          key: "main/user/event-code",
          to: "/main/user/event-code",
          className: "icon own-users",
          messageKey: "sidebar.users.eventCode",
          icon: "casino",
          permission: "user.event_code"
        }
        // {
        //   key: "main/user/app",
        //   to: "/main/user/browse",
        //   className: "icon own-users",
        //   messageKey: "sidebar.users.app",
        //   icon: 'people',
        //   permission: "user"
        // }
      ]
    },
    {
      key: "main/feed/browse",
      to: "/main/feed/browse",
      className: "gx-menu-group",
      titleKey: "sidebar.news",
      svgIcon: StatisticSvg,
      permission: "news",
      messageKey: "sidebar.news" // Sources --> Categories --> News
      // items: [
      //   {
      //     key: "main/feed/browse",
      //     to: "/main/feed/browse",
      //     className: "icon icon-timeline",
      //     svgIcon: StatisticSvg,
      //     permission: "news.feed"
      //   }
      // ]
    },
    {
      key: "advertise",
      className: "gx-menu-group",
      titleKey: "sidebar.advertises",
      svgIcon: AdsSvg,
      permission: "advertiser",
      items: [
        {
          key: "main/advertise/own/campaign",
          to: "/main/advertise/own/campaign",
          className: "icon",
          svgIcon: StatisticSvg,
          messageKey: "sidebar.advertises.campaign",
          permission: "advertiser.campaign"
        },
        {
          key: "main/user/advertiser",
          to: "/main/user/advertiser",
          className: "icon own-users",
          messageKey: "sidebar.users.partnership",
          icon: "people",
          permission: "user.advertiser"
        }
        // {
        //   key: "main/advertise/",
        //   to: "/main/advertise/campaign",
        //   className: "icon",
        //   svgIcon: StatisticSvg,
        //   messageKey: "sidebar.advertises.campaign" ,
        //   permission: "advertiser.campaign"
        // },
        // {
        //   key: "main/advertise/statistics",
        //   to: "/main/advertise/statistics",
        //   className: "icon",
        //   svgIcon: StatisticSvg,
        //   messageKey: "sidebar.advertises.statistics"
        // },
        // {
        //   key: "main/advertise/zones",
        //   to: "/main/advertise/zones",
        //   className: "icon",
        //   svgIcon: StatisticSvg,
        //   messageKey: "sidebar.advertises.zones"
        // },
        // {
        //   key: "main/advertise/ad-items",
        //   to: "/main/advertise/ad-items",
        //   className: "icon",
        //   svgIcon: StatisticSvg,
        //   messageKey: "sidebar.advertises.adItems"
        // }
      ]
    },
    {
      key: "algolia",
      className: "gx-menu-group",
      titleKey: "sidebar.eCommerce",
      icon: "shopping_cart",
      permission: "market",
      items: [
        {
          key: "main/algolia",
          to: "/main/algolia",
          className: "icon icon-shopping-cart",
          icon: "shopping_cart",
          messageKey: "sidebar.eCommerce.shop",
          permission: "market.shop"
        },
        {
          key: "custom-views/eCommerce/product-grid",
          to: "/custom-views/eCommerce/product-grid",
          className: "icon icon-icon",
          icon: "money_off",
          messageKey: "sidebar.eCommerce.deals",
          permission: "market.deals"
        }
      ]
    }
    // {
    //   key: "documents",
    //   className: "gx-menu-group",
    //   titleKey: "sidebar.documents",
    //   items: [
    //     // {
    //     //   key: "documents/changelog",
    //     //   to: "/documents/changelog",
    //     //   className: "icon icon-timeline-new",
    //     //   messageKey: "sidebar.documents.changelog"
    //     // },
    //     {
    //       key: "documents/installation",
    //       to: "/documents/installation",
    //       className: "icon icon-steps",
    //       messageKey: "sidebar.documents.aboutus"
    //     }
    //   ]
    // }
  ],
  removeStringKey: ["/create", "/update"]
};
