import {
  GET_PEOPLE,
  FETCH_ALL_PEOPLE_SUCCESS,
  DELETE_PEOPLE,
  DELETE_PEOPLE_SUCCESS,
  UPDATE_PEOPLE,
  UPDATE_PEOPLE_FAILED,
  FETCH_UPDATE_PEOPLE_SUCCESS,
  CREATE_PEOPLE,
  CREATE_PEOPLE_FAILED,
  FETCH_CREATE_PEOPLE_SUCCESS,
  GET_DETAIL_PEOPLE,
  GET_DETAIL_PEOPLE_SUCCESS
} from "../../constants/ActionTypes";

export const onGetDetailPeople = idPeople => {
  return {
    type: GET_DETAIL_PEOPLE,
    idPeople
  };
};

export const getDetailPeopleSuccess = peoplesList => {
  return {
    type: GET_DETAIL_PEOPLE_SUCCESS,
    payload: peoplesList
  };
};

export const onGetPeople = queries => {
  return {
    type: GET_PEOPLE,
    payload: queries
  };
};

export const fetchPeoplesSuccess = peoplesList => {
  return {
    type: FETCH_ALL_PEOPLE_SUCCESS,
    payload: peoplesList
  };
};

export const onDeletePeople = (idPeople, callback) => {
  return {
    type: DELETE_PEOPLE,
    idPeople,
    callback
  };
};

export const deletePeopleSuccess = peoplesList => {
  return {
    type: DELETE_PEOPLE_SUCCESS,
    payload: peoplesList
  };
};

export const onUpdatePeople = (idPeople, data, callback) => {
  return {
    type: UPDATE_PEOPLE,
    idPeople,
    data,
    callback
  };
};

export const updatePeopleFailed = error => {
  return {
    type: UPDATE_PEOPLE_FAILED,
    error
  };
};

export const updatePeopleSuccess = peoplesList => {
  return {
    type: FETCH_UPDATE_PEOPLE_SUCCESS,
    payload: peoplesList
  };
};

export const onCreatePeople = (data, callback) => {
  return {
    type: CREATE_PEOPLE,
    data,
    callback
  };
};

export const createPeopleSuccess = peoplesList => {
  return {
    type: FETCH_CREATE_PEOPLE_SUCCESS,
    payload: peoplesList
  };
};

export const createPeopleFailed = error => {
  return {
    type: CREATE_PEOPLE_FAILED,
    error
  };
};
