import {
  GET_COMMENTS,
  FETCH_ALL_COMMENTS_SUCCESS,
  DELETE_COMMENT_SUCCESS,
  UPDATE_COMMENT,
  FETCH_UPDATE_COMMENT_SUCCESS,
  DELETE_COMMENT,
  GET_DETAIL_COMMENT_SUCCESS,
  GET_DETAIL_COMMENT,
  APPROVE_COMMENT,
  APPROVE_COMMENT_SUCCESS
} from "../../constants/ActionTypes";

export const onGetDetailComment = idComment => {
  return {
    type: GET_DETAIL_COMMENT,
    payload: { idComment }
  };
};

export const getDetailCommentSuccess = commentDetail => {
  return {
    type: GET_DETAIL_COMMENT_SUCCESS,
    payload: commentDetail
  };
};

export const onGetComments = queries => {
  return {
    type: GET_COMMENTS,
    payload: queries
  };
};

export const fetchCommentsSuccess = comments => {
  return {
    type: FETCH_ALL_COMMENTS_SUCCESS,
    payload: comments
  };
};

export const onDeleteComment = (idComment, callback) => {
  return {
    type: DELETE_COMMENT,
    idComment,
    callback
  };
};

export const deleteCommentSuccess = () => {
  return {
    type: DELETE_COMMENT_SUCCESS
  };
};

export const onApproveComment = (idComment, approveType, callback) => {
  return {
    type: APPROVE_COMMENT,
    approveType,
    idComment,
    callback
  };
};

export const approveCommentSuccess = () => {
  return {
    type: APPROVE_COMMENT_SUCCESS
  };
};

export const onUpdateComment = (idComment, data) => {
  return {
    type: UPDATE_COMMENT,
    idComment,
    data
  };
};

export const updateCommentSuccess = commentsList => {
  return {
    type: FETCH_UPDATE_COMMENT_SUCCESS,
    payload: commentsList
  };
};
