import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchTelevisionsSuccess,
  updateTelevisionSuccess,
  createTelevisionSuccess,
  getDetailTelevisionSuccess,
  deleteTelevisionSuccess,
  updateTelevisionFailed,
  createTelevisionFailed
} from "../actions/Television";
import {
  GET_TELEVISION,
  DELETE_TELEVISION,
  UPDATE_TELEVISION,
  CREATE_TELEVISION,
  GET_DETAIL_TELEVISION
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import api from "../drivers";
import get from "lodash/get";
import convertOrderBy from "../../util/convertOrderBy";

export const getTelevision = async params => {
  if (params.order_by) params.order_by = convertOrderBy(params.order_by);

  return await api
    .get(apisDetail.apisTelevision, params)
    .then(resData => resData.data)
    .catch(error => error);
};

const getDetailTelevision = async ({ idTelevision }) =>
  await api
    .get(`${apisDetail.apisTelevision}/${idTelevision}`)
    .then(resData => resData.data)
    .catch(error => error);

const deleteTelevisionRequest = async idTelevision =>
  await api
    .delete(`${apisDetail.apisTelevision}/${idTelevision}`)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.deleteData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.deleteDataError" />
      );
      throw error;
    });

const updateTelevision = async ({ idTelevision, data }) =>
  await api
    .put(`${apisDetail.apisTelevision}/${idTelevision}`, data)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.updateData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateDataError" />
      );
      return error;
    });

const createTelevision = async data =>
  await api
    .post(`${apisDetail.apisTelevision}`, data)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.createData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => error);

/**
 * Define Saga action
 */
function* fetchTelevisionRequest({ payload }) {
  try {
    const fetchedTelevision = yield call(getTelevision, payload);
    yield put(fetchTelevisionsSuccess(fetchedTelevision));
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* onGetDetail(action) {
  try {
    const fetchedTelevision = yield call(getDetailTelevision, action);
    yield put(getDetailTelevisionSuccess(fetchedTelevision));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* onCreate({ data, callback }) {
  try {
    yield call(createTelevision, data);
    yield put(createTelevisionSuccess());
    callback && callback();
  } catch (error) {
    yield put(createTelevisionFailed(error));
  }
}

function* onUpdate({ idTelevision, data, callback }) {
  try {
    yield call(updateTelevision, { idTelevision, data });
    yield put(updateTelevisionSuccess());
    callback && callback();
  } catch (error) {
    yield put(updateTelevisionFailed(error));
  }
}

function* deleteTelevision({ idTelevision, callback }) {
  try {
    yield call(deleteTelevisionRequest, idTelevision);
    yield put(deleteTelevisionSuccess());
    callback && callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
 * Define Saga action
 */

/**
 * Receive Redux Action
 */
function* fetchTelevision() {
  yield takeEvery(GET_TELEVISION, fetchTelevisionRequest);
}

function* fetchDetailTelevision() {
  yield takeEvery(GET_DETAIL_TELEVISION, onGetDetail);
}

function* fetchDeleteTelevision() {
  yield takeEvery(DELETE_TELEVISION, deleteTelevision);
}

function* fetchUpdateTelevision() {
  yield takeEvery(UPDATE_TELEVISION, onUpdate);
}

function* fetchCreateTelevision() {
  yield takeEvery(CREATE_TELEVISION, onCreate);
}

export default function* rootSaga() {
  yield all([
    fork(fetchTelevision),
    fork(fetchDeleteTelevision),
    fork(fetchUpdateTelevision),
    fork(fetchCreateTelevision),
    fork(fetchDetailTelevision)
  ]);
}
