import {
  GET_FEEDS,
  FETCH_ALL_FEEDS_SUCCESS,
  DELETE_FEED_SUCCESS,
  UPDATE_FEED,
  FETCH_UPDATE_FEED_SUCCESS,
  CREATE_FEED,
  FETCH_CREATE_FEED_SUCCESS,
  DELETE_FEED,
  GET_DETAIL_FEED_SUCCESS,
  GET_DETAIL_FEED,
  SEND_FEED,
  SEND_FEED_SUCCESS,
  SEND_FEED_FAILED,
  GET_FEEDS_COLLECTION,
  GET_FEEDS_COLLECTION_SUCCESS,
  GET_FEEDS_COLLECTION_FAILED,
  UPDATE_FEEDS_COLLECTION,
  UPDATE_FEEDS_COLLECTION_SUCCESS,
  UPDATE_FEEDS_COLLECTION_FAILED,
  GET_FEEDS_SHORT_LINK,
  GET_FEEDS_SHORT_LINK_SUCCESS,
  GET_FEEDS_SHORT_LINK_FAILED
} from "../../constants/ActionTypes";

export const onGetDetailFeed = idFeed => {
  return {
    type: GET_DETAIL_FEED,
    payload: { idFeed }
  };
};

export const getDetailFeedSuccess = feedDetail => {
  return {
    type: GET_DETAIL_FEED_SUCCESS,
    payload: feedDetail
  };
};

export const onGetFeeds = queries => {
  return {
    type: GET_FEEDS,
    payload: queries
  };
};

export const fetchFeedsSuccess = feeds => {
  return {
    type: FETCH_ALL_FEEDS_SUCCESS,
    payload: feeds
  };
};

export const onDeleteFeed = (idFeed, callback) => {
  return {
    type: DELETE_FEED,
    idFeed,
    callback
  };
};

export const deleteFeedSuccess = feedsList => {
  return {
    type: DELETE_FEED_SUCCESS,
    payload: feedsList
  };
};

export const onUpdateFeed = (idFeed, data) => {
  return {
    type: UPDATE_FEED,
    idFeed,
    data
  };
};

export const updateFeedSuccess = feedsList => {
  return {
    type: FETCH_UPDATE_FEED_SUCCESS,
    payload: feedsList
  };
};

export const onCreateFeed = data => {
  return {
    type: CREATE_FEED,
    payload: { data }
  };
};

export const createFeedSuccess = data => {
  return {
    type: FETCH_CREATE_FEED_SUCCESS,
    payload: data
  };
};

export const onSendFeed = data => {
  return {
    type: SEND_FEED,
    payload: data
  };
};

export const sendFeedSuccess = data => {
  return {
    type: SEND_FEED_SUCCESS,
    payload: data
  };
};

export const sendFeedFailed = err => {
  return {
    type: SEND_FEED_FAILED,
    err
  };
};

export const onGetFeedsCollection = (queries, callback) => {
  return {
    type: GET_FEEDS_COLLECTION,
    payload: queries,
    callback
  };
};

export const onGetFeedsCollectionSuccess = feeds => {
  return {
    type: GET_FEEDS_COLLECTION_SUCCESS,
    payload: feeds
  };
};

export const onGetFeedsCollectionFailed = err => {
  return {
    type: GET_FEEDS_COLLECTION_FAILED,
    err
  };
};

export const onUpdateFeedsCollection = queries => {
  return {
    type: UPDATE_FEEDS_COLLECTION,
    payload: queries
  };
};

export const onUpdateFeedsCollectionSuccess = data => {
  return {
    type: UPDATE_FEEDS_COLLECTION_SUCCESS,
    payload: data
  };
};

export const onUpdateFeedsCollectionFailed = err => {
  return {
    type: UPDATE_FEEDS_COLLECTION_FAILED,
    err
  };
};

export const onGetFeedShortLink = (queries, callback) => {
  return {
    type: GET_FEEDS_SHORT_LINK,
    payload: queries,
    callback
  };
};

export const onGetFeedShortLinkSuccess = data => {
  return {
    type: GET_FEEDS_SHORT_LINK_SUCCESS,
    payload: data
  };
};

export const onGetFeedShortLinkFailed = err => {
  return {
    type: GET_FEEDS_SHORT_LINK_FAILED,
    err
  };
};
