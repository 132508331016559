import {
  GET_CHANNEL_LIST,
  GET_CHANNEL_LIST_SUCCESS,
  GET_CHANNEL_LIST_FAILED
} from "../../constants/ActionTypes";

export const onGetChannelList = () => {
  return {
    type: GET_CHANNEL_LIST
  };
};

export const onGetChannelListSuccess = payload => {
  return {
    type: GET_CHANNEL_LIST_SUCCESS,
    payload
  };
};

export const onGetChannelListFail = error => {
  return {
    type: GET_CHANNEL_LIST_FAILED,
    error
  };
};
