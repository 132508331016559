export const GET_LIST_TRANSCODE_HISTORY = "GET_LIST_TRANSCODE_HISTORY"
export const GET_LIST_TRANSCODE_HISTORY_SUCCESS = "GET_LIST_TRANSCODE_HISTORY_SUCCESS"
export const GET_LIST_TRANSCODE_HISTORY_FAILED = "GET_LIST_TRANSCODE_HISTORY_FAILED"

export const CLEAR_TRANSCODE_HISTORY = "CLEAR_TRANSCODE_HISTORY"

export const START_TRANSCODE = "START_TRANSCODE"
export const START_TRANSCODE_SUCCESS = "START_TRANSCODE_SUCCESS"
export const START_TRANSCODE_FAILED = "START_TRANSCODE_FAILED"

export const STOP_TRANSCODE = "STOP_TRANSCODE"
export const STOP_TRANSCODE_SUCCESS = "STOP_TRANSCODE_SUCCESS"
export const STOP_TRANSCODE_FAILED = "STOP_TRANSCODE_FAILED"

export const GET_TRANSCODE_STATUS = "GET_TRANSCODE_STATUS"
export const GET_TRANSCODE_STATUS_SUCCESS = "GET_TRANSCODE_STATUS_SUCCESS"
export const GET_TRANSCODE_STATUS_FAILED = "GET_TRANSCODE_STATUS_FAILED"