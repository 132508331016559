import React from 'react'
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { apisDetail } from "../../../constants/APIsURL";
import { fetchError } from "../../actions/Common";
import api from "../../drivers";
import { GET_ZONES } from '../../../constants/ActionTypes';
import { fetchZonesSuccess } from '../../actions/adbutler/Zones';
// import { NotificationManager } from "react-notifications";
// import { FormattedMessage } from "react-intl";

export const getZones = async params => {
  return await api
    .get(`${apisDetail.apisAdbutler}/zones`, params)
    .then(resData => resData.data)
}
// -------------------------
// Define Saga actions
// -------------------------
function* onGetZones({ payload }) {
  try {
    const result = yield call(getZones, payload);
    yield put(fetchZonesSuccess(result));
  } catch (error) {
    yield put(fetchError(error));
  }
}


/**
 * Receive redux action
 */

function* fetchZones() {
  yield takeEvery(GET_ZONES, onGetZones);
}

export default function* rootSaga() {
  yield all([
    fork(fetchZones)
  ]);
}
