import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import get from "lodash/get";
import api from "../drivers";

import { apisDetail } from "../../constants/APIsURL";
import {
  GET_CHANNEL_DETAIL,
  GET_VIEWER_REPORT,
  UPDATE_CHANNEL,
  GET_MATOMO_EMAIL,
  UPDATE_MATOMO_EMAIL,
  onGetChannelDetailSuccess,
  onGetChannelDetailFailed,
  onUpdateChannelSuccess,
  onUpdateChannelFailed,
  onGetViewerReportSuccess,
  onGetViewerReportFailed,
  onGetMatomoEmailSuccess,
  onGetMatomoEmailFail,
  onUpdateMatomoEmailSuccess,
  onUpdateMatomoEmailFail
} from "../actions/Channel";
import { FormattedMessage } from "react-intl";
import { NotificationManager } from "react-notifications";

const apiGetMatomo =
  "https://vision.weallnet.com?module=API&method=ScheduledReports.getReports&format=json";
const apiUpdateMatomo =
  "https://vision.weallnet.com?module=API&method=ScheduledReports.updateReport&period=month&format=json";
// const apiGetMatomo = "https://dev-vision.weallnet.com:8443?module=API&method=ScheduledReports.getReports&format=json";
// const apiUpdateMatomo = "https://dev-vision.weallnet.com:8443?module=API&method=ScheduledReports.updateReport&period=month&format=json";

export const apiGetChannel = async () =>
  await api.get(apisDetail.apisChannel).then(resData => resData.data);

export const apiUpdateChannel = async data =>
  await api.put(apisDetail.apisChannel, data).then(resData => resData.data);

export const getViewerReport = async params => {
  return await api
    .get(apisDetail.apisViewerReport, params)
    .then(resData => resData.data)
    .catch(error => {
      NotificationManager.error(
        get(error, "message", "response.data.error.message")
      );
      throw error;
    });
};

export const getMatomoEmail = async params => {
  const tokenAuth = "01cd7585eabffae9f805dde462ce263d";
  const apiURL =
    apiGetMatomo +
    "&idSite=" +
    params.idSite +
    "&token_auth=" +
    tokenAuth +
    "&idReport=" +
    params.idReport;

  return await api
    .get(apiURL)
    .then(resData => {
      if(resData.data.result == "error") {
        NotificationManager.warning(
          get("", "response.data.error.message", "Warning"),
          <FormattedMessage id="notification.matomoEmailWarning" />
        );
      }
      return resData.data;
    })
    .catch(error => {
      NotificationManager.warning(
        get(error, "response.data.error.message", "Warning"),
        <FormattedMessage id="notification.matomoEmailError" />
      );
      throw error;
    });
};

export const updateMatomoEmail = async params => {
  const tokenAuth = "01cd7585eabffae9f805dde462ce263d";
  const apiURL =
    apiUpdateMatomo +
    "&idSite=" +
    params.paramsURL.idSite +
    "&token_auth=" +
    tokenAuth +
    "&idReport=" +
    params.paramsURL.idReport;

  return await api
    .post(apiURL, params.formSendData)
    .then(resData => resData.data)
    .catch(error => {
      NotificationManager.warning(
        get(error, "message", "response.data.error.message", "Warning"),
        <FormattedMessage id="notification.matomoUpdateEmailError" />
      );
      throw error;
    });
};

/**
 * handle watch action
 */
function* handleGetChannelDetail({ payload }) {
  try {
    const result = yield call(apiGetChannel, payload);
    yield put(onGetChannelDetailSuccess(result));
  } catch (error) {
    NotificationManager.error(
      get(error, "message", "Get channel detail error"),
      <FormattedMessage id="notification.error" />
    );
    yield put(onGetChannelDetailFailed(error));
  }
}

function* handleUpdateChannel({ payload }) {
  try {
    const result = yield call(apiUpdateChannel, payload);
    NotificationManager.success(
      <FormattedMessage id="notification.updateData" />,
      <FormattedMessage id="notification.success" />
    );
    yield put(onUpdateChannelSuccess(result));
  } catch (error) {
    NotificationManager.error(
      get(error, "message", "Update channel detail failed"),
      <FormattedMessage id="notification.updateDataError" />
    );
    yield put(onUpdateChannelFailed(error));
  }
}

function* handleGetViewerReport({ payload }) {
  try {
    const fetchedViewerReport = yield call(getViewerReport, payload);
    yield put(onGetViewerReportSuccess(fetchedViewerReport));
  } catch (error) {
    yield put(onGetViewerReportFailed(error));
  }
}

function* handleGetMatomoEmail({ payload }) {
  try {
    const fetchedMatomoEmail = yield call(getMatomoEmail, payload);
    yield put(onGetMatomoEmailSuccess(fetchedMatomoEmail));
  } catch (error) {
    yield put(onGetMatomoEmailFail(error));
  }
}

function* handleUpdateMatomoEmail({ payload }) {
  // console.log(payload);
  try {
    const updatedMatomoEmail = yield call(updateMatomoEmail, payload);
    yield put(onUpdateMatomoEmailSuccess(updatedMatomoEmail));
  } catch (error) {
    yield put(onUpdateMatomoEmailFail(error));
  }
}

/**
 * Watch action
 */
function* watchGetChannelDetail() {
  yield takeEvery(GET_CHANNEL_DETAIL, handleGetChannelDetail);
}
function* watchUpdateChannel() {
  yield takeEvery(UPDATE_CHANNEL, handleUpdateChannel);
}
function* watchGetViewerReport() {
  yield takeEvery(GET_VIEWER_REPORT, handleGetViewerReport);
}
function* watchGetMatomoEmail() {
  yield takeEvery(GET_MATOMO_EMAIL, handleGetMatomoEmail);
}
function* watchUpdateMatomoEmail() {
  yield takeEvery(UPDATE_MATOMO_EMAIL, handleUpdateMatomoEmail);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetChannelDetail),
    fork(watchUpdateChannel),
    fork(watchGetViewerReport),
    fork(watchGetMatomoEmail),
    fork(watchUpdateMatomoEmail)
  ]);
}
