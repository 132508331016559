import {
  GET_SHORT_LINK,
  GET_SHORT_LINK_SUCCESS,
  GET_SHORT_LINK_FAILED
} from "../../constants/ActionTypes";

const INIT_STATE = {
  gettingShortLink: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SHORT_LINK: {
      return {
        ...state,
        gettingShortLink: true
      };
    }
    case GET_SHORT_LINK_SUCCESS: {
      return {
        ...state,
        shortLinkData: action.payload,
        gettingShortLink: false
      };
    }
    case GET_SHORT_LINK_FAILED: {
      return {
        ...state,
        gettingShortLink: false
      };
    }

    default:
      return state;
  }
};
