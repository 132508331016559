import {
  GET_COLLECTION,
  FETCH_ALL_COLLECTION_SUCCESS,
  DELETE_COLLECTION,
  DELETE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION,
  FETCH_UPDATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_FAILED,
  CREATE_COLLECTION,
  FETCH_CREATE_COLLECTION_SUCCESS,
  CREATE_COLLECTION_FAILED,
  GET_DETAIL_COLLECTION,
  GET_DETAIL_COLLECTION_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  collectionsList: [],
  idCollection: "",
  data: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DETAIL_COLLECTION: {
      return {
        ...state,
        data: {},
        idCollection: action.idCollection,
        loading: true
      };
    }
    case GET_DETAIL_COLLECTION_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    }
    case GET_COLLECTION: {
      return {
        ...state,
        loading: true,
        actions: {
          searchText: action.payload.name,
          limitItem: action.payload.limit,
          filteredInfo: action.payload.filterProps,
          sortedInfo: {
            field: action.payload.sort_by,
            columnKey: action.payload.sort_by,
            order: action.payload.order_by
          }
        }
      };
    }
    case FETCH_ALL_COLLECTION_SUCCESS: {
      return {
        ...state,
        collectionsList: action.payload,
        loading: false
      };
    }
    case DELETE_COLLECTION: {
      return {
        ...state,
        status: "DELETING",
        idCollection: action.idCollection,
        loading: true
      };
    }
    case DELETE_COLLECTION_SUCCESS: {
      return {
        ...state,
        status: "DELETED",
        loading: false
      };
    }
    case UPDATE_COLLECTION: {
      return {
        ...state,
        updating: true
      };
    }
    case FETCH_UPDATE_COLLECTION_SUCCESS: {
      return {
        ...state,
        updating: false
      };
    }
    case UPDATE_COLLECTION_FAILED: {
      return {
        ...state,
        updating: false
      };
    }
    case CREATE_COLLECTION: {
      return {
        ...state,
        data: action.data,
        creating: true
      };
    }
    case FETCH_CREATE_COLLECTION_SUCCESS: {
      return {
        ...state,
        collectionsList: action.payload,
        creating: false
      };
    }
    case CREATE_COLLECTION_FAILED: {
      return {
        ...state,
        creating: false
      };
    }
    default:
      return state;
  }
};
