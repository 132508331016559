import {
  GET_MUSIC,
  FETCH_ALL_MUSIC_SUCCESS,
  DELETE_MUSIC,
  DELETE_MUSIC_SUCCESS,
  UPDATE_MUSIC,
  FETCH_UPDATE_MUSIC_SUCCESS,
  UPDATE_MUSIC_FAILED,
  CREATE_MUSIC,
  CREATE_MUSIC_FAILED,
  CREATE_LIST_SONG,
  FETCH_CREATE_MUSIC_SUCCESS,
  FETCH_CREATE_LIST_SONG_SUCCESS,
  GET_DETAIL_MUSIC,
  GET_DETAIL_MUSIC_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  musicsList: [],
  idMusic: "",
  data: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DETAIL_MUSIC: {
      return {
        ...state,
        data: {},
        idMusic: action.idMusic,
        loadingDetail: true
      };
    }
    case GET_DETAIL_MUSIC_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loadingDetail: false,
        listSong: []
      };
    }
    case GET_MUSIC: {
      return {
        ...state,
        loading: true,
        actions: {
          searchText: action.payload.name,
          selectedTab: action.payload.music_type,
          limitItem: action.payload.limit,
          filteredInfo: action.payload.channelIdsProps,
          sortedInfo: {
            field: action.payload.sort_by,
            columnKey: action.payload.sort_by,
            order: action.payload.order_by
          }
        }
      };
    }
    case FETCH_ALL_MUSIC_SUCCESS: {
      return {
        ...state,
        musicsList: action.payload,
        loading: false,
        listSong: []
      };
    }
    case DELETE_MUSIC: {
      return {
        ...state,
        status: "DELETING",
        idMusic: action.idMusic,
        loading: true
      };
    }
    case DELETE_MUSIC_SUCCESS: {
      return {
        ...state,
        status: "DELETED",
        loading: false,
        listSong: []
      };
    }
    case UPDATE_MUSIC: {
      return {
        ...state,
        idMusic: action.idMusic,
        updating: true
      };
    }
    case FETCH_UPDATE_MUSIC_SUCCESS: {
      return {
        ...state,
        updating: false,
        dataResponse: action.payload.data
      };
    }
    case UPDATE_MUSIC_FAILED: {
      return {
        ...state,
        updating: false
      };
    }
    case CREATE_MUSIC: {
      return {
        ...state,
        data: action.data,
        creating: true
      };
    }
    case CREATE_MUSIC_FAILED: {
      return {
        ...state,
        creating: false
      };
    }
    case CREATE_LIST_SONG: {
      return {
        ...state,
        loadingCreateSongs: true
      };
    }
    case FETCH_CREATE_MUSIC_SUCCESS: {
      return {
        ...state,
        listSong: [],
        creating: false,
        dataResponse: action.payload.data
      };
    }
    case FETCH_CREATE_LIST_SONG_SUCCESS: {
      return {
        ...state,
        listSong: action.payload,
        loadingCreateSongs: false
      };
    }
    default:
      return state;
  }
};
