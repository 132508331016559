import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchError } from "../actions/Common";
import { apisDetail } from "../../constants/APIsURL";
import api from "../drivers";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import {
  GET_BLACKLIST,
  CREATE_BLACKLIST,
  DELETE_BLACKLIST
} from "../../constants/ActionTypes";
import {
  getBlacklistSuccess,
  createBlacklistSuccess,
  deleteBlacklistSuccess
} from "../actions/Blacklist";

const getBlacklist = async params =>
  await api
    .get(apisDetail.apisBlacklistKeyword, params)
    .then(resData => resData.data)
    .catch(error => error);

const createBlacklist = async data =>
  await api
    .post(`${apisDetail.apisBlacklistKeyword}`, data)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.createData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => error);

const deleteBlacklist = async words => {
  return await api
    .post(`${apisDetail.apisDeleteBlacklistKeyword}`, words)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.deleteData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => error);
  }
function* fetchBlacklistRequest({ payload }) {
  try {
    const fetchedBlacklist = yield call(getBlacklist, payload);
    yield put(getBlacklistSuccess(fetchedBlacklist));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchCreateBlacklistRequest({ data }) {
  try {
    yield call(createBlacklist, data);
    yield put(createBlacklistSuccess());
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchDeleteBlacklistRequest({words}) {
  try {
    yield call(deleteBlacklist, words);
    yield put(deleteBlacklistSuccess());
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchBlacklist() {
  yield takeEvery(GET_BLACKLIST, fetchBlacklistRequest);
}

function* fetchCreateBlacklist() {
  yield takeEvery(CREATE_BLACKLIST, fetchCreateBlacklistRequest);
}

function* fetchDeleteBlacklist() {
  yield takeEvery(DELETE_BLACKLIST, fetchDeleteBlacklistRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchBlacklist),
    fork(fetchCreateBlacklist),
    fork(fetchDeleteBlacklist)
  ]);
}
