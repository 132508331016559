import axios from "axios";
import qs from "query-string";
import _ from "lodash";

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "vn"
  }
});

instance.interceptors.request.use(
  config => config,
  error => Promise.reject(error)
);

instance.interceptors.response.use(
  r => r,
  error => Promise.reject(error)
);

const _requestResponse = response => {
  const status = _.get(response, "status");
  switch (status) {
    case 403:
      return { message: "Invalid token", kickOut: true };
    case 404:
      return { message: "Cannot connect to server" };
    default:
      return response;
  }
};

const _requestError = err => {
  const message = _.get(err, "response.data.error.message");
  if (typeof message === "string") {
    err.message = message;
  } else {
    err.message = "Whoops! Something went wrong";
  }
  throw err;
};

const get = (endpoints, params) => {
  return instance
    .get(`${endpoints}?${qs.stringify(params)}`)
    .then(_requestResponse)
    .catch(_requestError);
};

const post = (endpoints, params) => {
  return instance
    .post(`${endpoints}`, params)
    .then(_requestResponse)
    .catch(_requestError);
};

const patch = (endpoints, params) => {
  return instance
    .patch(`${endpoints}`, params)
    .then(_requestResponse)
    .catch(_requestError);
};

const del = endpoints => {
  return instance
    .delete(`${endpoints}`)
    .then(_requestResponse)
    .catch(_requestError);
};

const changeBaseURL = baseURL => {
  if (typeof baseURL === "undefined") {
    return;
  }
  instance.defaults.baseURL = baseURL;
};

const changeHeaders = headers => {
  if (typeof headers === "undefined") {
    return;
  }
  const defaultHeaders = _.get(instance, "defaults.headers");
  const cleanHeaders = _.omitBy(
    {
      ...defaultHeaders,
      ...headers
    },
    _.isEmpty
  );
  instance.defaults.headers = cleanHeaders;
};

const put = (endpoints, params) => {
  return instance
    .put(`${endpoints}`, params)
    .then(_requestResponse)
    .catch(_requestError);
};

export default {
  get,
  post,
  patch,
  delete: del,
  changeBaseURL,
  changeHeaders,
  put
};
