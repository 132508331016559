import {
  GET_FEEDBACKS,
  FETCH_ALL_FEEDBACKS_SUCCESS,
  DELETE_FEEDBACK_SUCCESS,
  UPDATE_FEEDBACK,
  FETCH_UPDATE_FEEDBACK_SUCCESS,
  DELETE_FEEDBACK,
  GET_DETAIL_FEEDBACK_SUCCESS,
  GET_DETAIL_FEEDBACK
} from "../../constants/ActionTypes";

export const onGetDetailFeedback = idFeedback => {
  return {
    type: GET_DETAIL_FEEDBACK,
    payload: { idFeedback }
  };
};

export const getDetailFeedbackSuccess = feedbackDetail => {
  return {
    type: GET_DETAIL_FEEDBACK_SUCCESS,
    payload: feedbackDetail
  };
};

export const onGetFeedbacks = queries => {
  return {
    type: GET_FEEDBACKS,
    payload: queries
  };
};

export const fetchFeedbacksSuccess = feedbacks => {
  return {
    type: FETCH_ALL_FEEDBACKS_SUCCESS,
    payload: feedbacks
  };
};

export const onDeleteFeedback = idFeedback => {
  return {
    type: DELETE_FEEDBACK,
    idFeedback
  };
};

export const deleteFeedbackSuccess = feedsList => {
  return {
    type: DELETE_FEEDBACK_SUCCESS,
    payload: feedsList
  };
};

export const onUpdateFeedback = (idFeedback, data) => {
  return {
    type: UPDATE_FEEDBACK,
    idFeedback,
    data
  };
};

export const updateFeedbackSuccess = feedbacksList => {
  return {
    type: FETCH_UPDATE_FEEDBACK_SUCCESS,
    payload: feedbacksList
  };
};
