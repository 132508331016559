import {
  START_TRANSCODE,
  START_TRANSCODE_SUCCESS,
  START_TRANSCODE_FAILED,
  CLEAR_TRANSCODE_HISTORY,
  GET_LIST_TRANSCODE_HISTORY,
  GET_LIST_TRANSCODE_HISTORY_FAILED,
  GET_LIST_TRANSCODE_HISTORY_SUCCESS,
  GET_TRANSCODE_STATUS_SUCCESS
} from "../../constants/actionTypes/transcode";

const INIT_STATE = {
  history: [],
  selectedTranscodeID: "",
  data: {}
};

function handleGetTranscodeStatus({state, action}) {
  return {
    ...state,
    transcodeStatus: action.payload.status
  }
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case START_TRANSCODE:
      return {
        ...state,
        startingTranscode: true
      };
    case START_TRANSCODE_SUCCESS:
      return {
        ...state,
        startingTranscode: false
      };
    case START_TRANSCODE_FAILED:
      return {
        ...state,
        startingTranscode: false
      };
    case GET_LIST_TRANSCODE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        history: action.payload
      };
    case GET_LIST_TRANSCODE_HISTORY:
      return {
        ...state,
        loading: true
      };
    case GET_LIST_TRANSCODE_HISTORY_FAILED:
      return {
        ...state,
        loading: false
      };
    case CLEAR_TRANSCODE_HISTORY:
      return {
        ...state,
        history: [],
        transcodeStatus: null
      };
    case GET_TRANSCODE_STATUS_SUCCESS:
      return handleGetTranscodeStatus({state, action});
    default:
      return state;
  }
};
