import {
  GET_REVIEWS,
  FETCH_ALL_REVIEWS_SUCCESS,
  DELETE_REVIEW_SUCCESS,
  UPDATE_REVIEW,
  FETCH_UPDATE_REVIEW_SUCCESS,
  DELETE_REVIEW,
  GET_DETAIL_REVIEW_SUCCESS,
  GET_DETAIL_REVIEW,
  APPROVE_REVIEW,
  APPROVE_REVIEW_SUCCESS
} from "../../constants/ActionTypes";

export const onGetDetailReview = idReview => {
  return {
    type: GET_DETAIL_REVIEW,
    payload: { idReview }
  };
};

export const getDetailReviewSuccess = reviewDetail => {
  return {
    type: GET_DETAIL_REVIEW_SUCCESS,
    payload: reviewDetail
  };
};

export const onGetReviews = (queries, blacklist) => {
  return {
    type: GET_REVIEWS,
    payload: queries,
    blacklist
  };
};

export const fetchReviewsSuccess = reviews => {
  return {
    type: FETCH_ALL_REVIEWS_SUCCESS,
    payload: reviews
  };
};

export const onDeleteReview = (idReview, callback) => {
  return {
    type: DELETE_REVIEW,
    idReview,
    callback
  };
};

export const deleteReviewSuccess = () => {
  return {
    type: DELETE_REVIEW_SUCCESS
  };
};

export const onApproveReview = (idReview, approveType, callback) => {
  return {
    type: APPROVE_REVIEW,
    approveType,
    idReview,
    callback
  };
};

export const onApproveReviewSuccess = () => {
  return {
    type: APPROVE_REVIEW_SUCCESS
  };
};

export const onUpdateReview = (idReview, data) => {
  return {
    type: UPDATE_REVIEW,
    idReview,
    data
  };
};

export const updateReviewSuccess = reviewsList => {
  return {
    type: FETCH_UPDATE_REVIEW_SUCCESS,
    payload: reviewsList
  };
};
