import {
  GET_MOVIE_BLACKLIST,
  GET_MOVIE_BLACKLIST_SUCCESS,
  GET_MUSIC_BLACKLIST,
  GET_MUSIC_BLACKLIST_SUCCESS,
  GET_VIDEO_BLACKLIST,
  GET_VIDEO_BLACKLIST_SUCCESS,
  APPROVE_MEDIA_BLACKLIST,
  APPROVE_MEDIA_BLACKLIST_SUCCESS,
  GET_REPORT_CONTENT,
  GET_REPORT_CONTENT_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  dataList: [],
  reportContent: [],
  dataDetail: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MOVIE_BLACKLIST: {
      return {
        ...state,
        loadingList: true,
        actions: {
          page: action.payload.page,
          searchText: action.payload.name,
          selectedTab: action.payload.content_type,
          limitItem: action.payload.limit,
          filterInfo: action.payload.channelIdsProps,
          sortedInfo: {
            field: action.payload.sort_by,
            columnKey: action.payload.sort_by,
            order: action.payload.order_by
          }
        }
      };
    }
    case GET_MOVIE_BLACKLIST_SUCCESS: {
      return {
        ...state,
        dataList: action.payload,
        loadingList: false
      };
    }
    case GET_VIDEO_BLACKLIST: {
      return {
        ...state,
        loadingList: true,
        actions: {
          searchText: action.payload.name,
          selectedTab: action.payload.content_type,
          limitItem: action.payload.limit,
          filterInfo: action.payload.channelIdsProps,
          sortedInfo: {
            field: action.payload.sort_by,
            columnKey: action.payload.sort_by,
            order: action.payload.order_by
          }
        }
      };
    }
    case GET_VIDEO_BLACKLIST_SUCCESS: {
      return {
        ...state,
        dataList: action.payload,
        loadingList: false
      };
    }
    case GET_MUSIC_BLACKLIST: {
      return {
        ...state,
        loadingList: true,
        actions: {
          searchText: action.payload.name,
          selectedTab: action.payload.content_type,
          limitItem: action.payload.limit,
          filterInfo: action.payload.channelIdsProps,
          sortedInfo: {
            field: action.payload.sort_by,
            columnKey: action.payload.sort_by,
            order: action.payload.order_by
          }
        }
      };
    }
    case GET_MUSIC_BLACKLIST_SUCCESS: {
      return {
        ...state,
        dataList: action.payload,
        loadingList: false
      };
    }
    case GET_REPORT_CONTENT: {
      return {
        ...state,
        loadingList: true
      };
    }
    case GET_REPORT_CONTENT_SUCCESS: {
      return {
        ...state,
        reportContent: action.payload,
        loadingList: false
      };
    }
    case APPROVE_MEDIA_BLACKLIST: {
      return {
        ...state,
        approving: false
      };
    }
    case APPROVE_MEDIA_BLACKLIST_SUCCESS: {
      return {
        ...state,
        approving: false
      };
    }
    default:
      return state;
  }
};
