import {
  CHANGE_MEDIA_TYPE,
  CHANGE_MEDIA_TYPE_SUCCESS,
  CHANGE_MEDIA_TYPE_FAILED
} from "../../constants/ActionTypes";

const INIT_STATE = {
  data: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_MEDIA_TYPE: {
      return {
        changing: true
      };
    }
    case CHANGE_MEDIA_TYPE_SUCCESS: {
      return {
        changing: false
      };
    }
    case CHANGE_MEDIA_TYPE_FAILED: {
      return {
        changing: false
      };
    }
    default:
      return state;
  }
};
