import {
  GET_MEDIA_INFORMATION,
  GET_MEDIA_INFORMATION_SUCCESS,
  GET_MEDIA_INFORMATION_ERROR
} from "../../constants/ActionTypes";

export const onGetMediaInformation = (playUrl, callback) => {
  return {
    type: GET_MEDIA_INFORMATION,
    playUrl,
    callback
  }
}

export const fetchMediaInformationSuccess = payload => {
  return {
    type: GET_MEDIA_INFORMATION_SUCCESS,
    payload
  }
}

export const fetchMediaInformationError = error => {
  return {
    type: GET_MEDIA_INFORMATION_ERROR,
    error
  }
}