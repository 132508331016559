import {
  UPDATE_STATUS,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAILED
} from "../../constants/ActionTypes";

const INIT_STATE = {
  id: "",
  type: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_STATUS: {
      return {
        ...state,
        id: action.id,
        type: action.mediaType,
        statusType: action.statusType,
        updating: true
      };
    }

    case UPDATE_STATUS_SUCCESS: {
      return {
        ...state,
        updating: false
      };
    }

    case UPDATE_STATUS_FAILED: {
      return {
        ...state,
        updating: false
      };
    } 

    default:
      return state;
  }
};
