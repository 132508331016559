import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchTagsSuccess,
  getDetailTagSuccess,
  createTagSuccess,
  updateTagSuccess,
  deleteTagSuccess,
  createTagFailed,
  updateTagFailed
} from "../actions/Tag";
import {
  GET_TAG,
  DELETE_TAG,
  UPDATE_TAG,
  CREATE_TAG,
  GET_DETAIL_TAG
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import api from "../drivers";
import get from "lodash/get";
import convertOrderBy from "../../util/convertOrderBy";

export const getTag = async params => {
  if (params.order_by) params.order_by = convertOrderBy(params.order_by);

  return await api
    .get(apisDetail.apisTag, params)
    .then(resData => resData.data)
    .catch(error => error);
};

const getDetailTag = async ({ idTag }) =>
  await api
    .get(`${apisDetail.apisTag}/${idTag}`)
    .then(resData => resData.data)
    .catch(error => error);

const deleteTag = async ({ idTag }) =>
  await api
    .delete(`${apisDetail.apisTag}/${idTag}`)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.deleteData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.deleteDataError" />
      );
      return error;
    });

const updateTag = async ({ idTag, data }) =>
  await api
    .put(`${apisDetail.apisTag}/${idTag}`, data)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.updateData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateDataError" />
      );
      return error;
    });

const createTag = async data =>
  await api
    .post(`${apisDetail.apisTag}`, data)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.createData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => error);

/**
 * Define Saga action
 */
function* fetchTagRequest({ payload }) {
  try {
    const fetchedTag = yield call(getTag, payload);
    yield put(fetchTagsSuccess(fetchedTag));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* onGetDetail(action) {
  try {
    const fetchedTag = yield call(getDetailTag, action);
    yield put(getDetailTagSuccess(fetchedTag));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* onCreate({ data, callback }) {
  try {
    yield call(createTag, data);
    yield put(createTagSuccess());
    callback && callback();
  } catch (error) {
    yield put(createTagFailed(error));
  }
}

function* onUpdate({ idTag, data, callback }) {
  try {
    yield call(updateTag, { idTag, data });
    yield put(updateTagSuccess());
    callback && callback();
  } catch (error) {
    yield put(updateTagFailed(error));
  }
}

function* onDelete(action) {
  try {
    yield call(deleteTag, action);
    yield put(deleteTagSuccess(action.idTag));
    action.callback && action.callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
 * Define Saga action
 */

/**
 * Receive Redux Action
 */

function* fetchTag() {
  yield takeEvery(GET_TAG, fetchTagRequest);
}

function* fetchDetailTag() {
  yield takeEvery(GET_DETAIL_TAG, onGetDetail);
}

function* fetchDeleteTag() {
  yield takeEvery(DELETE_TAG, onDelete);
}

function* fetchUpdateTag() {
  yield takeEvery(UPDATE_TAG, onUpdate);
}

function* fetchCreateTag() {
  yield takeEvery(CREATE_TAG, onCreate);
}

export default function* rootSaga() {
  yield all([
    fork(fetchTag),
    fork(fetchDeleteTag),
    fork(fetchUpdateTag),
    fork(fetchCreateTag),
    fork(fetchDetailTag)
  ]);
}
