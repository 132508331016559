import {
  GET_LIST_TRANSCODE_HISTORY,
  GET_LIST_TRANSCODE_HISTORY_SUCCESS,
  GET_LIST_TRANSCODE_HISTORY_FAILED,
  CLEAR_TRANSCODE_HISTORY,
  START_TRANSCODE,
  START_TRANSCODE_SUCCESS,
  START_TRANSCODE_FAILED,
  GET_TRANSCODE_STATUS,
  GET_TRANSCODE_STATUS_SUCCESS,
  GET_TRANSCODE_STATUS_FAILED,
  STOP_TRANSCODE,
  STOP_TRANSCODE_SUCCESS,
  STOP_TRANSCODE_FAILED
} from "../../constants/actionTypes/transcode";

export const getTranscodeHistory = queries => {
  return {
    type: GET_LIST_TRANSCODE_HISTORY,
    payload: queries
  };
};

export const getTranscodeHistorySuccess = transcodes => {
  return {
    type: GET_LIST_TRANSCODE_HISTORY_SUCCESS,
    payload: transcodes
  };
};

export const getTranscodeHistoryFailed = error => {
  return {
    type: GET_LIST_TRANSCODE_HISTORY_FAILED,
    error
  };
};

export const clearTranscodeHistory = () => ({
  type: CLEAR_TRANSCODE_HISTORY
});

export const startTranscode = (queries, callback) => ({
  type: START_TRANSCODE,
  payload: queries,
  callback
});

export const startTranscodeSuccess = data => ({
  type: START_TRANSCODE_SUCCESS,
  payload: data
});

export const startTranscodeFailed = error => ({
  type: START_TRANSCODE_FAILED,
  error
});

export const stopTranscode = queries => ({
  type: STOP_TRANSCODE,
  payload: queries
});

export const stopTranscodeSuccess = () => ({
  type: STOP_TRANSCODE_SUCCESS,
});

export const stopTranscodeFailed = error => ({
  type: STOP_TRANSCODE_FAILED,
  error
});

/**
 * 
 * @param {job_id} payload 
 */
export const onGetTranscodeStatus = (payload, callback) => ({
  type: GET_TRANSCODE_STATUS,
  payload,
  callback
});

export const onGetTranscodeStatusSuccess = data => ({
  type: GET_TRANSCODE_STATUS_SUCCESS,
  payload: data
});

export const onGetTranscodeStatusFailed = error => ({
  type: GET_TRANSCODE_STATUS_FAILED,
  error
});
