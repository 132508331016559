import {
  GET_MUSIC,
  FETCH_ALL_MUSIC_SUCCESS,
  DELETE_MUSIC,
  DELETE_MUSIC_SUCCESS,
  UPDATE_MUSIC,
  FETCH_UPDATE_MUSIC_SUCCESS,
  UPDATE_MUSIC_FAILED,
  CREATE_MUSIC,
  CREATE_MUSIC_FAILED,
  CREATE_LIST_SONG,
  FETCH_CREATE_LIST_SONG_SUCCESS,
  FETCH_CREATE_MUSIC_SUCCESS,
  GET_DETAIL_MUSIC,
  GET_DETAIL_MUSIC_SUCCESS
} from "../../constants/ActionTypes";

export const onGetDetailMusic = idMusic => {
  return {
    type: GET_DETAIL_MUSIC,
    idMusic
  };
};

export const getDetailMusicSuccess = music => {
  return {
    type: GET_DETAIL_MUSIC_SUCCESS,
    payload: music
  };
};

export const onGetMusic = queries => {
  return {
    type: GET_MUSIC,
    payload: queries
  };
};

export const fetchMusicsSuccess = musicsList => {
  return {
    type: FETCH_ALL_MUSIC_SUCCESS,
    payload: musicsList
  };
};

export const onDeleteMusic = (idMusic, callback) => {
  return {
    type: DELETE_MUSIC,
    idMusic,
    callback
  };
};

export const deleteMusicSuccess = musicsList => {
  return {
    type: DELETE_MUSIC_SUCCESS,
    payload: musicsList
  };
};

export const onUpdateMusic = (idMusic, data, callback) => {
  return {
    type: UPDATE_MUSIC,
    idMusic,
    data,
    callback
  };
};

export const updateMusicFailed = error => {
  return {
    type: UPDATE_MUSIC_FAILED,
    error
  };
};

export const updateMusicSuccess = payload => {
  return {
    type: FETCH_UPDATE_MUSIC_SUCCESS,
    payload
  };
};

export const onCreateListSong = (data, callback) => {
  return {
    type: CREATE_LIST_SONG,
    data,
    callback
  };
};

export const createListSongSuccess = listSong => {
  return {
    type: FETCH_CREATE_LIST_SONG_SUCCESS,
    payload: listSong
  };
};

export const onCreateMusic = (data, callback) => {
  return {
    type: CREATE_MUSIC,
    data,
    callback
  };
};

export const createMusicFailed = error => {
  return {
    type: CREATE_MUSIC_FAILED,
    error
  };
};

export const createMusicSuccess = payload => {
  return {
    type: FETCH_CREATE_MUSIC_SUCCESS,
    payload
  };
};
