import {
  GET_USERS,
  FETCH_ALL_USERS_SUCCESS,
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_SUCCESS,
  GET_LIST_PERMISSIONS,
  GET_LIST_PERMISSIONS_SUCCESS,
  BAN_USER,
  BAN_USER_SUCCESS,
  BAN_USER_FAILED,
  RANDOM_PROFILE,
  RANDOM_PROFILE_SUCCESS,
  GET_EVENT_RESULT,
  GET_EVENT_RESULT_SUCCESS,
  UPDATE_EVENT_RESULT_HISTORY,
  UPDATE_EVENT_RESULT_HISTORY_SUCCESS,
  UPDATE_EVENT_RESULT_HISTORY_FAILED
} from "../../constants/ActionTypes";

export const onGetUsers = queries => {
  return {
    type: GET_USERS,
    payload: queries
  };
};

export const onGetEventResult = (queries, callback) => {
  return {
    type: GET_EVENT_RESULT,
    payload: queries,
    callback
  };
};

export const onGetEventResultSuccess = (queries, callback) => {
  return {
    type: GET_EVENT_RESULT_SUCCESS,
    payload: queries,
    callback
  };
};

export const onUpdateEventResultHistory = (data, callback) => {
  console.log("queries", data);
  return {
    type: UPDATE_EVENT_RESULT_HISTORY,
    data,
    callback
  };
};

export const onUpdateEventResultHistorySuccess = (data, callback) => {
  return {
    type: UPDATE_EVENT_RESULT_HISTORY_SUCCESS,
    data,
    callback
  };
};

export const onUpdateEventResultHistoryFailed = error => {
  return {
      type: UPDATE_EVENT_RESULT_HISTORY_FAILED,
      error
  };
};

export const onRandomProfile = (queries, callback) => {
  return {
    type: RANDOM_PROFILE,
    payload: queries,
    callback
  }
};

export const onRandomProfileSuccess = (queries, callback) => ({
  type: RANDOM_PROFILE_SUCCESS,
  payload: queries,
  callback
})

export const fetchUsersSuccess = users => {
  return {
    type: FETCH_ALL_USERS_SUCCESS,
    payload: users
  };
};

export const getUserProfile = userId => {
  return {
    type: GET_USER_PROFILE,
    userId
  };
};

export const getUserProfileSuccess = data => {
  return {
    type: GET_USER_PROFILE_SUCCESS,
    payload: data
  };
};

export const updateUserProfile = ({ userId, data }, callback) => {
  return {
    type: UPDATE_USER_PROFILE,
    payload: {
      userId,
      data
    },
    callback
  };
};

export const updateUserProfileSuccess = data => {
  return {
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: data
  };
};

export const getListPermissions = () => {
  return {
    type: GET_LIST_PERMISSIONS
  };
};

export const getListPermissionsSuccess = data => {
  return {
    type: GET_LIST_PERMISSIONS_SUCCESS,
    payload: data
  };
};

export const banUser = (data, callback) => {
  return {
    type: BAN_USER,
    payload: data,
    callback
  };
};

export const banUserSuccess = () => {
  return {
    type: BAN_USER_SUCCESS
  };
};

export const banUserFailed = error => {
  return {
    type: BAN_USER_FAILED,
    error
  };
};

