import React from "react";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  onUpdateStatusSuccess,
  onUpdateStatusFailed
} from "../actions/UpdateStatus";
import { UPDATE_STATUS } from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import api from "../drivers";
import get from "lodash/get";

const updatingStatus = async params => {
  return await api
    .post(apisDetail.apisUpdateStatus, params)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.updateStatusSuccess" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateStatusError" />
      );
      throw error;
    });
};

/**
 * Define Saga action
 */
function* fetchUpdateStatusRequest({ payload, callback }) {
  try {
    const updateStatus = yield call(updatingStatus, payload);
    yield put(onUpdateStatusSuccess(updateStatus));
    callback && callback();
  } catch (error) {
    yield put(onUpdateStatusFailed(error));
  }
}
/**
 * Define Saga action
 */

/**
 * Receive Redux Action
 */
function* fetchUpdateStatus() {
  yield takeEvery(UPDATE_STATUS, fetchUpdateStatusRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchUpdateStatus)]);
}
