import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchMoviesSuccess,
  getDetailMovieSuccess,
  createMovieSuccess,
  createMovieFailed,
  updateMovieSuccess,
  updateMovieFailed,
  deleteMovieSuccess,
  getEpisodeSuccess
} from "../../appRedux/actions/Movie";
import {
  GET_MOVIE,
  GET_EPISODE,
  DELETE_MOVIE,
  UPDATE_MOVIE,
  CREATE_MOVIE,
  GET_DETAIL_MOVIE
} from "constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import api from "../drivers";
import get from "lodash/get";
import convertOrderBy from "../../util/convertOrderBy";

export const getMovies = async params => {
  if (params.order_by) params.order_by = convertOrderBy(params.order_by);
  if (params.movie_type === "onContent") delete params.movie_type;
  delete params.channelIdsProps; // remove unused params
  
  return await api
    .get(apisDetail.apisMovie, params)
    .then(resData => resData.data);
};

export const getEpisode = async params => {
  if (params.order_by) params.order_by = convertOrderBy(params.order_by);
  
  return await api
    .get(apisDetail.apisMovie, params)
    .then(resData => resData.data);
};

const getDetailMovieRequest = async idMovie =>
  await api
    .get(`${apisDetail.apisMovie}/${idMovie}`)
    .then(resData => resData.data);

const deleteMovieRequest = async idMovie => {
  const url = `${apisDetail.apisMovie}/${idMovie}`;
  return await api
    .delete(url)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.deleteData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.deleteDataError" />
      );
      throw error;
    });
};

const updateMovieRequest = async ({ idMovie, data }) =>
  await api
    .put(`${apisDetail.apisMovie}/${idMovie}`, data)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.updateData" />,
        <FormattedMessage id="notification.success" />
      );
      return resData.data
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateDataError" />
      );
      throw error;
    });

const createMovie = async data => {
  const url = `${apisDetail.apisMovie}`;
  return await api
    .post(url, data)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.createData" />,
        <FormattedMessage id="notification.success" />
      );
      return resData.data
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateDataError" />
      );
      throw error;
    });
};

function* fetchMovieRequest({ payload }) {
  try {
    const fetchedMovie = yield call(getMovies, payload);
    yield put(fetchMoviesSuccess(fetchedMovie));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchEpisodeRequest({ payload }) {
  try {
    const fetchedEpisode = yield call(getEpisode, payload);
    yield put(getEpisodeSuccess(fetchedEpisode));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getDetailMovie({ payload }) {
  const { idMovie } = payload;
  try {
    const fetchedMovie = yield call(getDetailMovieRequest, idMovie);
    yield put(getDetailMovieSuccess(fetchedMovie));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* createMovieRequest({ payload, callback }) {
  const { data } = payload;
  try {
    const fetchedMovie = yield call(createMovie, data);
    yield put(createMovieSuccess(fetchedMovie));
    callback && callback();
  } catch (error) {
    yield put(createMovieFailed(error));
  }
}

function* updateMovie({ idMovie, data, callback }) {
  try {
    const updatedMovie = yield call(updateMovieRequest, { idMovie, data });
    yield put(updateMovieSuccess(updatedMovie));
    callback && callback();
  } catch (error) {
    yield put(updateMovieFailed(error));
  }
}

function* deleteMovie({ idMovie, callback }) {
  try {
    yield call(deleteMovieRequest, idMovie);
    yield put(deleteMovieSuccess());
    callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* fetchMovie() {
  yield takeEvery(GET_MOVIE, fetchMovieRequest);
}

export function* fetchDetailMovie() {
  yield takeEvery(GET_DETAIL_MOVIE, getDetailMovie);
}

export function* watchDeleteMovie() {
  yield takeEvery(DELETE_MOVIE, deleteMovie);
}

export function* fetchUpdateMovie() {
  yield takeEvery(UPDATE_MOVIE, updateMovie);
}

export function* fetchCreateMovie() {
  yield takeEvery(CREATE_MOVIE, createMovieRequest);
}

export function* fetchEpisode() {
  yield takeEvery(GET_EPISODE, fetchEpisodeRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchMovie),
    fork(fetchEpisode),
    fork(fetchDetailMovie),
    fork(watchDeleteMovie),
    fork(fetchUpdateMovie),
    fork(fetchCreateMovie)
  ]);
}
