import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    fetchConfigsSuccess,
    updateEventKeySuccess,
    updateEventKeyFailed,
    updateEventLinkSuccess,
    updateEventLinkFailed,
    updateEventBackgroundSuccess,
    updateEventBackgroundFailed
} from "../actions/Config";
import {
    GET_CONFIG,
    UPDATE_EVENT_KEY,
    UPDATE_EVENT_KEY_SUCCESS,
    UPDATE_EVENT_KEY_FAILED,
    UPDATE_EVENT_LINK,
    UPDATE_EVENT_LINK_SUCCESS,
    UPDATE_EVENT_LINK_FAILED,
    UPDATE_EVENT_BACKGROUND,
    UPDATE_EVENT_BACKGROUND_SUCCESS,
    UPDATE_EVENT_BACKGROUND_FAILED,
    FETCH_ALL_CONFIG_SUCCESS
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import api from "../drivers";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import get from "lodash/get";

const getConfigs = async () =>
  await api
    .get(apisDetail.apisConfig)
    .then(resData => {
        return resData;
    })
    .catch(error => error);
  
const updateEventKeyRequest = async ({ config_name, data }) =>
    await api
      .put(`${apisDetail.apisUpdateEventKey}/${config_name}`, data)
      .then(resData => {
        NotificationManager.success(
          <FormattedMessage id="notification.updateData" />,
          <FormattedMessage id="notification.success" />
        );
        return resData;
      })
      .catch(error => {
        NotificationManager.error(
          get(error, "response.data.error.message"),
          <FormattedMessage id="notification.updateDataError" />
        );
        throw error;
      });

const updateEventLinkRequest = async ({ config_name, data }) =>
      await api
        .put(`${apisDetail.apisUpdateEventLink}/${config_name}`, data)
        .then(resData => {
          NotificationManager.success(
            <FormattedMessage id="notification.updateData" />,
            <FormattedMessage id="notification.success" />
          );
          return resData;
        })
        .catch(error => {
          NotificationManager.error(
            get(error, "response.data.error.message"),
            <FormattedMessage id="notification.updateDataError" />
          );
          throw error;
        });

const updateEventBackgroundRequest = async ({ config_name, data }) =>
      await api
        .put(`${apisDetail.apisUpdateEventBackground}/${config_name}`, data)
        .then(resData => {
          NotificationManager.success(
            <FormattedMessage id="notification.updateData" />,
            <FormattedMessage id="notification.success" />
          );
          return resData;
        })
        .catch(error => {
          NotificationManager.error(
            get(error, "response.data.error.message"),
            <FormattedMessage id="notification.updateDataError" />
          );
          throw error;
        });
// -------------------------
// Define Saga actions
// -------------------------
function* fetchConfigRequest() {
  try {
    const fetchedConfigs = yield call(getConfigs);
    yield put(fetchConfigsSuccess(fetchedConfigs));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* updateEventKey({ config_name, data, callback }) {
    try {
      const updatedEventKey = yield call(updateEventKeyRequest, { config_name, data });
      yield put(updateEventKeySuccess(updatedEventKey));
      callback && callback();
    } catch (error) {
      yield put(updateEventKeyFailed(error));
    }
  }

  function* updateEventLink({ config_name, data, callback }) {
    try {
      const updatedEventLink = yield call(updateEventLinkRequest, { config_name, data });
      yield put(updateEventLinkSuccess(updatedEventLink));
      callback && callback();
    } catch (error) {
      yield put(updateEventLinkFailed(error));
    }
  }

  
  function* updateEventBackground({ config_name, data, callback }) {
    try {
      const updatedEventBackground = yield call(updateEventBackgroundRequest, { config_name, data });
      yield put(updateEventBackgroundSuccess(updatedEventBackground));
      callback && callback();
    } catch (error) {
      yield put(updateEventBackgroundFailed(error));
    }
  }
// receive action
function* fetchConfigs() {
  yield takeEvery(GET_CONFIG, fetchConfigRequest);
}

export function* fetchUpdateEventKey() {
    yield takeEvery(UPDATE_EVENT_KEY, updateEventKey);
  }

export function* fetchUpdateEventLink() {
    yield takeEvery(UPDATE_EVENT_LINK, updateEventLink);
  }

  export function* fetchUpdateEventBackground() {
    yield takeEvery(UPDATE_EVENT_BACKGROUND, updateEventBackground);
  }
  

export default function* rootSaga() {
  yield all([
    fork(fetchConfigs),
    fork(fetchUpdateEventKey),
    fork(fetchUpdateEventLink),
    fork(fetchUpdateEventBackground)
  ]);
}
