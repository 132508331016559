import {
  GET_BLACKLIST,
  GET_BLACKLIST_SUCCESS,
  CREATE_BLACKLIST,
  CREATE_BLACKLIST_SUCCESS,
  DELETE_BLACKLIST,
  DELETE_BLACKLIST_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  list: [],
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BLACKLIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_BLACKLIST_SUCCESS: {
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    }
    case CREATE_BLACKLIST: {
      return {
        ...state,
        loading: true,
        status: 'DOING',
      }
    }
    case CREATE_BLACKLIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        status: 'DONE',
      };
    }
    case DELETE_BLACKLIST: {
      return {
        ...state,
        loading: true,
        status: 'DOING',
      };
    }
    case DELETE_BLACKLIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        status: 'DONE',
      };
    }
    default:
      return state;
  }
};
