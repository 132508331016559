// const apisENV = process.env.BASE_URL || "https://api.weallnet.com/api";
// const apisENV = "https://dev-api.weallnet.com/api";
// const apisENV = "https://api.weallnet.com/api";
const apisENV = process.env.REACT_APP_NODE_ENV === "production"
  ? "https://api.weallnet.com/api"
  : "https://dev-api.weallnet.com/api";
const apiMatomo = "https://matomo.weallnet.com";

export const apisDetail = {
  headers: {
    AUTHORIZATION: "Authorization"
  },
  userLogin: `${apisENV}/cms/v1/login`,
  apisMovie: `${apisENV}/cms/v1/movie`,
  apisInteractivePartList: `${apisENV}/cms/v1/interactive_bulk`,
  apisPage: `${apisENV}/cms/v1/page`,
  apisMusic: `${apisENV}/cms/v1/music`,
  apisMusicList: `${apisENV}/cms/v1/music_bulk`,
  apisTelevision: `${apisENV}/cms/v1/television`,
  apisCollection: `${apisENV}/cms/v1/collection`,
  apisPeople: `${apisENV}/cms/v1/people`,
  apisTag: `${apisENV}/cms/v1/tag`,
  apisVideo: `${apisENV}/cms/v1/video`,
  apisTranscode: `${apisENV}/cms/v1/transcode`,
  apisUsers: `${apisENV}/cms/v1/profile`,
  apisRandomUsers: `${apisENV}/cms/v1/profile-random`,

  //config
  apisConfig: `${apisENV}/cms/v1/config`,
  apisUpdateEventKey: `${apisENV}/cms/v1/config/event-key`,
  apisUpdateEventLink: `${apisENV}/cms/v1/config/event-link`,
  apisUpdateEventBackground: `${apisENV}/cms/v1/config/event-background`,

  //greetings
  apisGreetings: `${apisENV}/cms/v1/greetings`,

  //event result
  apisGetEventResult:`${apisENV}/cms/v1/get-list-event-result`,
  apisUpdateEventResultHistory: `${apisENV}/cms/v1/update-event-result-history`,

  apisFeeds: `${apisENV}/cms/v1/feed`,
  apisFeedsGetCollection: `${apisENV}/cms/v1/feed/collection-detail/top-news`,
  apisFeedsUpdateCollection: `${apisENV}/cms/v1/feed/update-collection`,
  apisSendFeeds: `${apisENV}/cms/v1/feed/send_notification`,
  apisSendNotification: `${apisENV}/cms/v1/media/send_notification`,
  apisUpdateStatus: `${apisENV}/cms/v1/media/update_status`,
  apisCategories: `${apisENV}/cms/v1/category`,
  apisFeedbacks: `${apisENV}/comment/v1/cms/feedback`,
  apisComments: `${apisENV}/comment/v1/cms/cmt`,
  apisReviews: `${apisENV}/comment/v1/cms/review`,
  apisBlacklistReviews: `${apisENV}/comment/v1/cms/blacklist/review`,
  apisBlacklistFeedbacks: `${apisENV}/comment/v1/cms/blacklist/feedback`,
  apisBlacklistComments: `${apisENV}/comment/v1/cms/blacklist/cmt`,
  apisBlacklistKeyword: `${apisENV}/comment/v1/cms/blacklist`,
  apisReportContent: `${apisENV}/comment/v1/cms/content/list-report`,
  apisDeleteBlacklistKeyword: `${apisENV}/comment/v1/cms/delete_blacklist`,
  apisReports: "https://api.adbutler.com/v2/reports",
  apisAdbutler: `${apisENV}/cms/v1/adbutler`,
  apisAdbutlerZones: `${apisENV}/cms/v1/adbutler/zones`,
  apiUploadImage: `${apisENV}/cms/v1/upload_media_image`,
  apiUploadAd: `${apisENV}/cms/v1/upload_ad_file`,
  apisAdvertisement: `${apisENV}/cms/v1/ad`,
  apisAnalytic: `${apisENV}/cms/v1/ad/analytic`,
  apisGenerateS3: `${apisENV}/cms/v1/generate_s3_upload`,
  apisPublishS3File: `${apisENV}/cms/v1/publish_s3_file`,
  apisChannel: `${apisENV}/cms/v1/channel`,
  apisMediaInformation: `${apisENV}/cms/v1/extract_metadata`,
  apisTotalUser: `${apisENV}/cms/v1/total_user`,
  apisMediaApprove: `${apisENV}/cms/v1/media/approve`,
  apisMediaExchange: `${apisENV}/cms/v1/media/exchange`,
  apisViewerReport: `${apisENV}/cms/v1/interactive/viewer-report`,
  apisAnalyticMatomo: `${apiMatomo}`,
  apisFireBaseDynamicLink:
    "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyC4R92IwI94bmxW1GgngtfT8uqhC7X5un0"
};
