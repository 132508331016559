import React, { PureComponent } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import { Layout } from "antd";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import sidebar from "../../assets/data/sidebar";
import _ from "lodash";
import stringTruncateAfter from "../../util/stringTruncateAfter";

const { Footer } = Layout;

class SidebarContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      menus: sidebar.menus
    };
  }

  getNoHeaderClass = navStyle => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  _renderMenus = () => {
    const { permission, userRole } = this.props;
    console.log("vao nek", this.props);
    if (!permission) return null;
    const { menus } = this.state;

    return _.map(menus, item => {
      if (
        // item.permission !== "dashboard" &&
        permission.indexOf(item.permission) === -1
      )
        return null;

      if (!item.items) {
        return (
          <MenuItem key={item.key}>
            <Link to={item.to}>
              <div className="menu-item">
                {item.icon && (
                  <i className="icon material-icons">{item.icon}</i>
                )}
                {item.svgIcon && (
                  <i className="icon">
                    <div className="svg-icon">{item.svgIcon}</div>
                  </i>
                )}
                <FormattedMessage id={item.messageKey} />
              </div>
            </Link>
          </MenuItem>
        );
      }

      let subMenu = item.items;
      if (userRole !== "partner") {
        subMenu = subMenu.filter(
          item => item.messageKey !== "sidebar.media.channel"
        );
      }

      return (
        <SubMenu
          key={item.key}
          className={this.getNavStyleSubMenuClass(item.className)}
          title={
            <span className="menu-item">
              {item.icon && <i className="icon material-icons">{item.icon}</i>}
              {item.svgIcon && (
                <i className="icon">
                  <div className="svg-icon">{item.svgIcon}</div>
                </i>
              )}
              <FormattedMessage id={item.titleKey} />
            </span>
          }
        >
          {_.map(
            subMenu,
            sub =>
              (userRole === "super_admin" ||
                permission.indexOf(sub.permission) !== -1 ||
                sub.permission === "dashboard") && (
                <MenuItem key={sub.key}>
                  <Link to={sub.to}>
                    <div className="menu-item">
                      {sub.icon && (
                        <i className="icon material-icons">{sub.icon}</i>
                      )}
                      {sub.svgIcon && (
                        <i className="icon">
                          <div className="svg-icon">{sub.svgIcon}</div>
                        </i>
                      )}
                      {userRole === "partner" && sub.messageKeyPartner ? (
                        <FormattedMessage id={sub.messageKeyPartner} />
                      ) : (
                        <FormattedMessage id={sub.messageKey} />
                      )}
                    </div>
                  </Link>
                </MenuItem>
              )
          )}
        </SubMenu>
      );
    });
  };

  render() {
    const { themeType, navStyle, pathname } = this.props;
    let selectedKeys = pathname ? pathname.substr(1) : "";

    sidebar.removeStringKey.map((item, key) => {
      selectedKeys = stringTruncateAfter(selectedKeys, item);
    });
    const defaultOpenKeys = selectedKeys.split("/")[1];

    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div
            className={`gx-sidebar-notifications ${this.getNoHeaderClass(
              navStyle
            )}`}
          >
            <UserProfile />
            {/* <AppsNavigation /> */}
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode="inline"
            >
              {this._renderMenus()}
            </Menu>
          </CustomScrollbars>
          <Footer>
            <div className="copyright">
              © <span className="copyright__highlight">Forever</span> Vesnet
              JSC. All rights reserved.
            </div>
          </Footer>
        </div>
      </Auxiliary>
    );
  }
}

const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;
SidebarContent.propTypes = {};

const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  return {
    navStyle,
    themeType,
    locale,
    pathname,
    permission: _.get(auth, "authUser.permission"),
    userRole: _.get(auth, "authUser.user_profile.role")
  };
};

export default connect(mapStateToProps)(SidebarContent);
