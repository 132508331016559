import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchUsersSuccess,
  getUserProfileSuccess,
  getListPermissionsSuccess,
  onGetEventResultSuccess,
  updateUserProfileSuccess,
  banUserSuccess,
  banUserFailed,
  onRandomProfileSuccess,
  onUpdateEventResultHistorySuccess,
  onUpdateEventResultHistoryFailed,
} from "../actions/User";
import {
  BAN_USER,
  GET_USERS,
  GET_USER_PROFILE,
  GET_LIST_PERMISSIONS,
  UPDATE_USER_PROFILE,
  RANDOM_PROFILE, 
  GET_EVENT_RESULT,
  UPDATE_EVENT_RESULT_HISTORY,
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import api from "../drivers";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import get from "lodash/get";

const getUsers = async params =>
  await api
    .get(apisDetail.apisUsers, params)
    .then(resData => resData.data)
    .catch(error => error);

const getBanUser = async data => 
  await api
    .post(`${apisDetail.apisUsers}/restrict`, data)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.banUserSuccess" />,
        <FormattedMessage id="notification.success" />
      );
      return resData.data;
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateDataError" />
      );
      throw error;
    });

const apiGetUserProfile = async userId =>
  await api
    .get(`${apisDetail.apisUsers}/${userId}`)
    .then(resData => resData.data)
    .catch(error => error);

const apiGetListPermissions = async () =>
  await api
    .get(`${apisDetail.apisUsers}/permission`)
    .then(resData => resData.data)
    .catch(error => error);

const apiUpdateUserProfile = async ({ userId, data }) =>
  await api
    .put(`${apisDetail.apisUsers}/${userId}`, data)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.updateData" />,
        <FormattedMessage id="notification.success" />
      );
      return resData.data;
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateDataError" />
      );
      throw error;
    });

const apiRandomProfile = async params => {
  return await api 
  .get(`${apisDetail.apisRandomUsers}`, params)   
  .then((resData) => {
    return resData;
  });
}

const apiGetEventResult = async params => {
  return await api 
  .get(`${apisDetail.apisGetEventResult}`, params)   
  .then((resData) => {
    return resData;
  });
}
  
const apiUpdateEventResultHistory = async ({ data }) =>

    await api
      .put(`${apisDetail.apisUpdateEventResultHistory}`, data)
      .then(resData => {
        console.log("data", data);
        return resData;
      })
      .catch(error => {
        throw error;
      });


// -------------------------
// Define Saga actions
// -------------------------
function* fetchUsersRequest({ payload }) {
  try {
    const fetchedUsers = yield call(getUsers, payload);
    yield put(fetchUsersSuccess(fetchedUsers));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchBanUserRequest({ payload, callback }) {
  try {
    const fetchedBanUser = yield call(getBanUser, payload);
    yield put(banUserSuccess(fetchedBanUser));
    callback && callback();
  } catch (error) {
    yield put(banUserFailed(error));
  }
}

function* onGetUserProfile({ userId }) {
  try {
    const fetechedProfile = yield call(apiGetUserProfile, userId);
    yield put(getUserProfileSuccess(fetechedProfile));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* onGetListPermissions() {
  try {
    const data = yield call(apiGetListPermissions);
    yield put(getListPermissionsSuccess(data));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* onUpdateUserProfile({ payload, callback }) {
  try {
    const data = yield call(apiUpdateUserProfile, payload);
    yield put(updateUserProfileSuccess(data));
    callback && callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* onRandomProfile({ payload, callback}) {
  try {
    const result = yield call(apiRandomProfile, payload);
    yield put(onRandomProfileSuccess(result))
    callback && callback(result);
  } catch (error) {
  }
}

function* onGetEventResult({ payload, callback}) {
  try {
    const result = yield call(apiGetEventResult, payload);
    yield put(onGetEventResultSuccess(result))
    callback && callback(result);
  } catch (error) {
  }
}

function* updateEventResultHistory({data, callback }) {
  try {
    const updatedEventResultHistory = yield call(apiUpdateEventResultHistory, {  data });
    yield put(onUpdateEventResultHistorySuccess(updatedEventResultHistory));
    callback && callback();
  } catch (error) {
    yield put(onUpdateEventResultHistoryFailed(error));
  }
}


// receive action
function* fetchUsers() {
  yield takeEvery(GET_USERS, fetchUsersRequest);
}

function* fetchBanUser() {
  yield takeEvery(BAN_USER, fetchBanUserRequest);
}

function* fetchUserProfile() {
  yield takeEvery(GET_USER_PROFILE, onGetUserProfile);
}

function* fetchEventResult() {
  yield takeEvery(GET_EVENT_RESULT, onGetEventResult);
}

function* fetchListPermissions() {
  yield takeEvery(GET_LIST_PERMISSIONS, onGetListPermissions);
}

function* fetchUpdateprofile() {
  yield takeEvery(UPDATE_USER_PROFILE, onUpdateUserProfile);
}

export function* fetchUpdateEventResultHistory() {
  yield takeEvery(UPDATE_EVENT_RESULT_HISTORY, updateEventResultHistory);
}

export function* watchRandomProfile() {
  yield takeEvery(RANDOM_PROFILE, onRandomProfile)
}
export default function* rootSaga() {
  yield all([
    fork(fetchUsers),
    fork(fetchBanUser),
    fork(fetchUserProfile),
    fork(fetchUpdateprofile),
    fork(fetchListPermissions),
    fork(watchRandomProfile),
    fork(fetchEventResult),
    fork(fetchUpdateEventResultHistory)
  ]);
}
