import {
  GET_TELEVISION,
  FETCH_ALL_TELEVISION_SUCCESS,
  DELETE_TELEVISION,
  DELETE_TELEVISION_SUCCESS,
  UPDATE_TELEVISION,
  UPDATE_TELEVISION_FAILED,
  FETCH_UPDATE_TELEVISION_SUCCESS,
  CREATE_TELEVISION,
  CREATE_TELEVISION_FAILED,
  FETCH_CREATE_TELEVISION_SUCCESS,
  GET_DETAIL_TELEVISION,
  GET_DETAIL_TELEVISION_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  televisionsList: [],
  idTelevision: "",
  data: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DETAIL_TELEVISION: {
      return {
        ...state,
        data: {},
        idTelevision: action.idTelevision,
        loading: true
      };
    }

    case GET_DETAIL_TELEVISION_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    }

    case GET_TELEVISION: {
      return {
        ...state,
        loading: true,
        actions: { searchText: action.payload.name }
      };
    }

    case FETCH_ALL_TELEVISION_SUCCESS: {
      return {
        ...state,
        televisionsList: action.payload,
        loading: false
      };
    }

    case DELETE_TELEVISION: {
      return {
        ...state,
        status: "DELETING",
        idTelevision: action.idTelevision,
        loading: true
      };
    }

    case DELETE_TELEVISION_SUCCESS: {
      return {
        ...state,
        status: "DELETED",
        loading: false
      };
    }

    case UPDATE_TELEVISION: {
      return {
        ...state,
        updating: true
      };
    }

    case FETCH_UPDATE_TELEVISION_SUCCESS: {
      return {
        ...state,
        updating: false
      };
    }

    case UPDATE_TELEVISION_FAILED: {
      return {
        ...state,
        updating: false
      };
    }

    case CREATE_TELEVISION: {
      return {
        ...state,
        data: action.data,
        creating: true
      };
    }

    case FETCH_CREATE_TELEVISION_SUCCESS: {
      return {
        ...state,
        televisionsList: action.payload,
        creating: false
      };
    }

    case CREATE_TELEVISION_FAILED: {
      return {
        ...state,
        creating: false
      };
    }

    default:
      return state;
  }
};
