import React, { Component } from "react";
import { connect } from "react-redux";
import URLSearchParams from "url-search-params";
import { Redirect, Route, Switch } from "react-router-dom";
import { LocaleProvider, ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import { setInitUrl } from "appRedux/actions/Auth";
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType
} from "appRedux/actions/Setting";
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import get from 'lodash/get'
import ReactPlaceholder from "react-placeholder";

import "react-placeholder/lib/reactPlaceholder.css";
import CircularProgress from "components/CircularProgress";
import { NotificationManager, NotificationContainer } from "react-notifications";
import { FormattedMessage } from "react-intl";
import {userSignOut} from "appRedux/actions/Auth";
import api from '../../appRedux/drivers'
import { apisDetail } from "../../constants/APIsURL";
import { onGetMyProfile } from "../../appRedux/actions/Auth";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class App extends Component {
  state = {
    waiting: true
  }
  setLayoutType = layoutType => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove("full-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("full-layout");
      document.body.classList.add("framed-layout");
    }
  };

  setNavStyle = navStyle => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add("full-scroll");
      document.body.classList.add("horizontal-layout");
    } else {
      document.body.classList.remove("full-scroll");
      document.body.classList.remove("horizontal-layout");
    }
  };

  UNSAFE_componentWillMount() {
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);

    if (params.has("theme")) {
      this.props.setThemeType(params.get("theme"));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get("nav-style"));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get("layout-type"));
    }
  }

  componentDidMount() {
    window.WEALLNET_ENV = process.env;
    window.NODE_ENV = process.env.NODE_ENV;
    window.REACT_APP_NODE_ENV = process.env.REACT_APP_NODE_ENV;
    this._authorizeApp();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.authLoader !== nextProps.authLoader) {
      const expired = get(nextProps, 'authUser.access_token.expired')
      if (expired) {
        if (new Date().getTime() > expired) {
          console.log('====== Refresh Token ======')
          NotificationManager.error(
            '',
            <FormattedMessage id="notification.tokenExpired" />
          );
          this.props.history.push('/signin')
          this.props.userSignOut()
          return
        } 
        // add token to header
        const token = get(nextProps, 'authUser.access_token.id')
        token && api.changeHeaders({
          [apisDetail.headers.AUTHORIZATION]: token
        });
        // this.props.onGetMyProfile(get(nextProps, 'authUser.user_profile.id'));
      } else {
        this.props.history.push('/signin')        
        this.props.userSignOut()
      }
    }
  }
  

  _authorizeApp = () => {
    const { location, initURL, authUser } = this.props;
    if (location.pathname === "/") {
      if (authUser === null) {
        return <Redirect to={"/signin"} />;
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
        return <Redirect to={"/main/dashboard"} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }
  };


  renderContent = () => {
    const { match, authUser } = this.props;
    if (this.props.authLoader) {
      const content = <CircularProgress/>
      return (
        <ReactPlaceholder type="text" rows={7} ready={content !== null}>
          {content}
        </ReactPlaceholder>
      )
    }
    return (
      <Switch>
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/signup" component={SignUp} />
        <RestrictedRoute
          path={`${match.url}`}
          authUser={authUser}
          component={MainApp}
        />
      </Switch>
    )
  }

  render() {
    const { layoutType, navStyle, locale } = this.props;
    this.setLayoutType(layoutType);
    this.setNavStyle(navStyle);
    const currentAppLocale = AppLocale[locale.locale];
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <React.Fragment>
            {this.renderContent()}
            <NotificationContainer />
          </React.Fragment>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale, navStyle, layoutType } = settings;
  const { authUser, initURL, loader: authLoader } = auth;
  return { locale, navStyle, layoutType, authUser, initURL, authLoader };
};

export default connect(
  mapStateToProps,
  { setInitUrl, setThemeType, onNavStyleChange, onLayoutTypeChange, userSignOut, onGetMyProfile }
)(App);
