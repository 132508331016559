import {
  GET_CATEGORIES,
  FETCH_ALL_CATEGORIES_SUCCESS
} from "../../constants/ActionTypes";

export const onGetCategories = queries => {
  return {
    type: GET_CATEGORIES,
    payload: queries
  };
};

export const fetchCategoriesSuccess = categories => {
  return {
    type: FETCH_ALL_CATEGORIES_SUCCESS,
    payload: categories
  };
};
