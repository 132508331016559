import {
  GET_CHANNEL_LIST,
  GET_CHANNEL_LIST_SUCCESS,
  GET_CHANNEL_LIST_FAILED
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loading: false,
  list: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHANNEL_LIST:
      return {
        ...state,
        loading: true
      };
    case GET_CHANNEL_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false
      };
    case GET_CHANNEL_LIST_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};
