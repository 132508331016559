import {
  GET_PEOPLE,
  FETCH_ALL_PEOPLE_SUCCESS,
  DELETE_PEOPLE,
  DELETE_PEOPLE_SUCCESS,
  UPDATE_PEOPLE,
  UPDATE_PEOPLE_FAILED,
  FETCH_UPDATE_PEOPLE_SUCCESS,
  CREATE_PEOPLE,
  CREATE_PEOPLE_FAILED,
  FETCH_CREATE_PEOPLE_SUCCESS,
  GET_DETAIL_PEOPLE,
  GET_DETAIL_PEOPLE_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  peoplesList: [],
  idPeople: "",
  data: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DETAIL_PEOPLE: {
      return {
        ...state,
        data: {},
        idPeople: action.idPeople,
        loading: true
      };
    }

    case GET_DETAIL_PEOPLE_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    }

    case GET_PEOPLE: {
      return {
        ...state,
        loading: true,
        actions: { searchText: action.payload.name }
      };
    }

    case FETCH_ALL_PEOPLE_SUCCESS: {
      return {
        ...state,
        peoplesList: action.payload,
        loading: false
      };
    }

    case DELETE_PEOPLE: {
      return {
        ...state,
        status: "DELETING",
        idPeople: action.idPeople,
        loading: true
      };
    }

    case DELETE_PEOPLE_SUCCESS: {
      return {
        ...state,
        status: "DELETED",
        loading: false
      };
    }

    case UPDATE_PEOPLE: {
      return {
        ...state,
        updating: true
      };
    }

    case UPDATE_PEOPLE_FAILED: {
      return {
        ...state,
        updating: false
      };
    }

    case FETCH_UPDATE_PEOPLE_SUCCESS: {
      return {
        ...state,
        updating: false
      };
    }

    case CREATE_PEOPLE: {
      return {
        ...state,
        data: action.data,
        creating: true
      };
    }

    case FETCH_CREATE_PEOPLE_SUCCESS: {
      return {
        ...state,
        peoplesList: action.payload,
        creating: false
      };
    }

    case CREATE_PEOPLE_FAILED: {
      return {
        ...state,
        creating: false
      };
    }

    default:
      return state;
  }
};
