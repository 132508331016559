import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchPagesSuccess,
  getDetailPageSuccess,
  createPageSuccess,
  updatePageSuccess,
  deletePageSuccess,
  updatePageFailed
} from "../actions/Page";
import {
  GET_PAGE,
  DELETE_PAGE,
  UPDATE_PAGE,
  CREATE_PAGE,
  GET_DETAIL_PAGE
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import api from "../drivers";
import get from "lodash/get";

const getPage = async params =>
  await api.get(apisDetail.apisPage, params).then(resData => resData.data);

const getDetailPage = async ({ idPage }) =>
  await api
    .get(`${apisDetail.apisPage}/${idPage}`)
    .then(resData => resData.data);

const deletePageRequest = async idPage =>
  await api
    .delete(`${apisDetail.apisPage}/${idPage}`)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.deleteData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.deleteDataError" />
      );
      throw error;
    });

const updatePage = async ({ idPage, data }) =>
  await api
    .put(`${apisDetail.apisPage}/${idPage}`, data)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.updateData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateDataError" />
      );
      throw error;
    });

const createPage = async data =>
  await api.post(`${apisDetail.apisPage}`, data).then(() => {
    NotificationManager.success(
      <FormattedMessage id="notification.createData" />,
      <FormattedMessage id="notification.success" />
    );
  });

/**
 * Define Saga action
 */
function* fetchPageRequest({ payload }) {
  try {
    const fetchedPage = yield call(getPage, payload);
    yield put(fetchPagesSuccess(fetchedPage));
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* onGetDetail(action) {
  try {
    const fetchedPage = yield call(getDetailPage, action);
    yield put(getDetailPageSuccess(fetchedPage));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* onCreatePage({ data, callback }) {
  try {
    yield call(createPage, data);
    yield put(createPageSuccess());
    callback && callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* onUpdatePage({ idPage, data, callback }) {
  try {
    yield call(updatePage, { idPage, data });
    yield put(updatePageSuccess());
    callback && callback();
  } catch (error) {
    yield put(updatePageFailed(error));
  }
}

function* deletePage({ idPage }) {
  try {
    yield call(deletePageRequest, idPage);
    yield put(deletePageSuccess());
  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
 * Define Saga action
 */

/**
 * Receive Redux Action
 */
function* fetchPage() {
  yield takeEvery(GET_PAGE, fetchPageRequest);
}

function* fetchDetailPage() {
  yield takeEvery(GET_DETAIL_PAGE, onGetDetail);
}

function* fetchDeletePage() {
  yield takeEvery(DELETE_PAGE, deletePage);
}

function* fetchUpdatePage() {
  yield takeEvery(UPDATE_PAGE, onUpdatePage);
}

function* fetchCreatePage() {
  yield takeEvery(CREATE_PAGE, onCreatePage);
}
/**
 * Receive Redux Action
 */

export default function* rootSaga() {
  yield all([
    fork(fetchPage),
    fork(fetchDeletePage),
    fork(fetchUpdatePage),
    fork(fetchCreatePage),
    fork(fetchDetailPage)
  ]);
}
