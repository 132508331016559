import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchVideosSuccess,
  getDetailVideoSuccess,
  createVideoSuccess,
  createVideoFailed,
  updateVideoSuccess,
  updateVideoFailed,
  deleteVideoSuccess
} from "../actions/Video";
import {
  GET_VIDEO,
  DELETE_VIDEO,
  UPDATE_VIDEO,
  CREATE_VIDEO,
  GET_DETAIL_VIDEO
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import api from "../drivers";
import get from "lodash/get";
import convertOrderBy from "../../util/convertOrderBy";

export const getVideo = async params => {
  if (params.order_by) params.order_by = convertOrderBy(params.order_by);
  delete params.video_type; // remove unused params
  delete params.channelIdsProps; // remove unused params

  return await api
    .get(apisDetail.apisVideo, params)
    .then(resData => resData.data);
};

const getDetailVideoRequest = async idVideo =>
  await api
    .get(`${apisDetail.apisVideo}/${idVideo}`)
    .then(resData => resData.data);

const deleteVideoRequest = async idVideo =>
  await api
    .delete(`${apisDetail.apisVideo}/${idVideo}`)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.deleteData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.deleteDataError" />
      );
      throw error;
    });

const updateVideoRequest = async ({ idVideo, data }) =>
  await api
    .put(`${apisDetail.apisVideo}/${idVideo}`, data)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.updateData" />,
        <FormattedMessage id="notification.success" />
      );
      return resData;
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateDataError" />
      );
      throw error;
    });

const createVideoRequest = async data =>
  await api
    .post(`${apisDetail.apisVideo}`, data)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.createData" />,
        <FormattedMessage id="notification.success" />
      );
      return resData;
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateDataError" />
      );
      throw error;
    });

/**
 * Define Saga action
 */
function* fetchVideoRequest({ payload }) {
  try {
    const fetchedVideo = yield call(getVideo, payload);
    yield put(fetchVideosSuccess(fetchedVideo));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getDetailVideo({ idVideo }) {
  try {
    const fetchedVideo = yield call(getDetailVideoRequest, idVideo);
    yield put(getDetailVideoSuccess(fetchedVideo));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* createVideo({ data, callback }) {
  try {
    const createdVideo = yield call(createVideoRequest, data);
    yield put(createVideoSuccess(createdVideo));
    callback && callback();
  } catch (error) {
    yield put(createVideoFailed(error));
  }
}

function* updateVideo({ idVideo, data, callback }) {
  try {
    const updatedVideo = yield call(updateVideoRequest, { idVideo, data });
    yield put(updateVideoSuccess(updatedVideo));
    callback && callback();
  } catch (error) {
    yield put(updateVideoFailed(error));
  }
}

function* deleteVideo({ idVideo, callback }) {
  try {
    yield call(deleteVideoRequest, idVideo);
    yield put(deleteVideoSuccess());
    callback && callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Receive Redux Action
 */
export function* fetchVideo() {
  yield takeEvery(GET_VIDEO, fetchVideoRequest);
}

export function* fetchDetailVideo() {
  yield takeEvery(GET_DETAIL_VIDEO, getDetailVideo);
}

export function* fetchDeleteVideo() {
  yield takeEvery(DELETE_VIDEO, deleteVideo);
}

export function* fetchUpdateVideo() {
  yield takeEvery(UPDATE_VIDEO, updateVideo);
}

export function* fetchCreateVideo() {
  yield takeEvery(CREATE_VIDEO, createVideo);
}

export default function* rootSaga() {
  yield all([
    fork(fetchVideo),
    fork(fetchDeleteVideo),
    fork(fetchUpdateVideo),
    fork(fetchCreateVideo),
    fork(fetchDetailVideo)
  ]);
}
