import {
  GET_TAG,
  FETCH_ALL_TAG_SUCCESS,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  UPDATE_TAG,
  UPDATE_TAG_FAILED,
  FETCH_UPDATE_TAG_SUCCESS,
  CREATE_TAG,
  CREATE_TAG_FAILED,
  FETCH_CREATE_TAG_SUCCESS,
  GET_DETAIL_TAG,
  GET_DETAIL_TAG_SUCCESS,
  baseReturnedAction
} from "../../constants/ActionTypes";

export const onGetDetailTag = idTag => {
  return {
    type: GET_DETAIL_TAG,
    idTag
  };
};

export const getDetailTagSuccess = tagsList => {
  return {
    type: GET_DETAIL_TAG_SUCCESS,
    payload: tagsList
  };
};

export const onGetTag = queries => {
  return {
    type: GET_TAG,
    payload: queries
  };
};

export const fetchTagsSuccess = tagsList => {
  return {
    type: FETCH_ALL_TAG_SUCCESS,
    payload: tagsList
  };
};

export const onDeleteTag = (idTag, callback) => {
  return {
    type: DELETE_TAG,
    idTag,
    callback
  };
};

export const deleteTagSuccess = idTag => {
  return {
    type: DELETE_TAG_SUCCESS,
    payload: idTag
  };
};

export const onUpdateTag = (idTag, data, callback) => {
  return {
    type: UPDATE_TAG,
    idTag,
    data,
    callback
  };
};

export const updateTagSuccess = tagsList => {
  return {
    type: FETCH_UPDATE_TAG_SUCCESS,
    payload: tagsList
  };
};

export const updateTagFailed = error => {
  return {
    type: UPDATE_TAG_FAILED,
    error
  };
};

export const onCreateTag = (data, callback) => {
  return {
    type: CREATE_TAG,
    data,
    callback
  };
};

export const createTagSuccess = tagsList => {
  return {
    type: FETCH_CREATE_TAG_SUCCESS,
    payload: tagsList
  };
};

export const createTagFailed = error => {
  return {
    type: CREATE_TAG_FAILED,
    error
  };
};
