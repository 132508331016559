import React from 'react'
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchFeedbacksSuccess, getDetailFeedbackSuccess, deleteFeedbackSuccess } from "../actions/Feedback";
import { GET_FEEDBACKS, GET_DETAIL_FEEDBACK, DELETE_FEEDBACK, UPDATE_FEEDBACK } from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import api from "../drivers";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import get from 'lodash/get'

const getFeedbacks = async params => {
  return await api
    .get(apisDetail.apisFeedbacks, params)
    .then(resData => resData.data)
    .catch(error => error);
}

const getDetailFeedbackRequest = async idFeedback => {
  const url = `${apisDetail.apisFeedbacks}/${idFeedback}`;
  return await api
    .get(url)
    .then(resData => resData.data)
    .catch(error => error);
};

const deleteFeedbackRequest = async (idFeedback) => {
  const url = `${apisDetail.apisFeedbacks}/${idFeedback}`;
  return await api
    .delete(url)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.deleteData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, 'response.data.error.message', 'Error'),
        <FormattedMessage id="notification.deleteDataError" />
      );
      throw error
    });
};

const updateFeedback = async (action) => {
  const {idFeedback, data} = action
  const url = `${apisDetail.apisFeedbacks}/${idFeedback}`;
  return await api
    .put(url, data)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.successMessage"/>,
        <FormattedMessage id="notification.updateData" />
      );
    })
    .catch(error => error);
};

// -------------------------
// Define Saga actions
// -------------------------
function* fetchFeedbacksRequest({ payload }) {
  try {
    const fetchedFeedbacks = yield call(getFeedbacks, payload);
    yield put(fetchFeedbacksSuccess(fetchedFeedbacks));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getDetailFeedback({ payload }) {
  const { idFeedback } = payload;
  try {
    const fetchedFeedback = yield call(getDetailFeedbackRequest, idFeedback);
    yield put(getDetailFeedbackSuccess(fetchedFeedback));
  } catch (error) {
    yield put(fetchError(error));
  }
}


function* deleteFeedback({ idFeedback }) {
  try {
    yield call(deleteFeedbackRequest, idFeedback);
    yield put(deleteFeedbackSuccess())
  } catch (error) {
    yield put(fetchError(error))
  }
}

/**
 * Receive redux action
 */

function* fetchFeedbacks() {
  yield takeEvery(GET_FEEDBACKS, fetchFeedbacksRequest);
}

function* fetchDetailFeedback() {
  yield takeEvery(GET_DETAIL_FEEDBACK, getDetailFeedback);
}

function* fetchDeleteFeedback() {
  yield takeEvery(DELETE_FEEDBACK, deleteFeedback);
}

function* fetchUpdateFeedback() {
  yield takeEvery(UPDATE_FEEDBACK, updateFeedback);
}

export default function* rootSaga() {
  yield all([
    fork(fetchFeedbacks),
    fork(fetchDetailFeedback),
    fork(fetchDeleteFeedback),
    fork(fetchUpdateFeedback)
  ]);
}
