import {
  CHANGE_MEDIA_TYPE,
  CHANGE_MEDIA_TYPE_SUCCESS,
  CHANGE_MEDIA_TYPE_FAILED
} from "../../constants/ActionTypes";

export const onChangeMediaType = (payload, callback) => {
  return {
    type: CHANGE_MEDIA_TYPE,
    payload,
    callback
  };
};

export const onChangeMediaTypeSuccess = () => {
  return {
    type: CHANGE_MEDIA_TYPE_SUCCESS,
  };
};

export const onChangeMediaTypeFailed = error => {
  return {
    type: CHANGE_MEDIA_TYPE_FAILED,
    error
  };
};
