import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import get from "lodash/get";
import { connect } from "react-redux";

import asyncComponent from "../../util/asyncComponent";
import Dashboard from "./dashboard";
// const Widgets = asyncComponent(() => import("./Widgets"))
// const Metrics = asyncComponent(() => import("./Metrics"))
// const Layouts = asyncComponent(() => import("./Layouts"))

const Media = asyncComponent(() => import("./media"));
const User = asyncComponent(() => import("./user"));
const Feed = asyncComponent(() => import("./feed"));
const Feedback = asyncComponent(() => import("./feedback"));
const Advertise = asyncComponent(() => import("./advertise"));
const EmptyPage = asyncComponent(() => import("./empty"));
const Page404 = asyncComponent(() => import("./noMatchPage/page404"));
const WelcomePage = asyncComponent(() => import("./noMatchPage/welcomePage"));
const UserAnalytics = asyncComponent(() => import("./dashboard/UserAnalytics"));

const Main = ({ match, permission, userRole }) => {
  return (
    <Switch>
      {(userRole === "super_admin" ||
        permission.indexOf("advertiser") !== -1) && (
        <Route path={`${match.url}/advertise`} component={Advertise} />
      )}
      {(userRole === "super_admin" ||
        permission.indexOf("discussion") !== -1) && (
        <Route path={`${match.url}/feedback`} component={Feedback} />
      )}
      {(userRole === "super_admin" || permission.indexOf("news") !== -1) && (
        <Route path={`${match.url}/feed`} component={Feed} />
      )}
      {(userRole === "super_admin" || permission.indexOf("user") !== -1) && (
        <Route path={`${match.url}/user`} component={User} />
      )}
      {(userRole === "super_admin" || permission.indexOf("media") !== -1) && (
        <Route path={`${match.url}/media`} component={Media} />
      )}
      {(userRole === "super_admin" || permission.indexOf("market") !== -1) && (
        <Route
          path={`${match.url}/algolia`}
          component={asyncComponent(() => import("../algolia"))}
        />
      )}
      {(userRole === "super_admin" || permission.indexOf("dashboard") !== -1) && (
        <Route path={`${match.url}/dashboard`} component={UserAnalytics} />
      )}
      {/* <Route path={`${match.url}/empty`} component={EmptyPage} />
      <Redirect to={`${match.url}/empty`} />*/}
      <Route component={WelcomePage} /> 
    </Switch>
  );
};

export default connect(({ auth }) => ({
  permission: get(auth, "authUser.permission", []),
  userRole: get(auth, "authUser.user_profile.role")
}))(Main);
