import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "react-router-redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "../reducers";
// import createHistory from "history/createBrowserHistory";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import createApiAuthMiddle from "../drivers/middleware";
import rootSaga from "../sagas";
import thunk from "redux-thunk";
const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware, routeMiddleware, createApiAuthMiddle];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// Init persist reducers with persistConfig
const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    'auth'
  ]
};
const persistedReducer = persistReducer(persistConfig, reducers(history));

export default function configureStore(initialState) {
  // add redux logger in dev mode
  if (process.env.NODE_ENV === 'development') {
    const {logger} = require('redux-logger');
    middlewares.push(logger)
  }
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  persistStore(store);
  sagaMiddleware.run(rootSaga);
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      const nextRootReducer = require("../reducers");
      store.replaceReducer(nextRootReducer(history));
    });
  }
  return store;
}

export { history };
