import {
  SEND_MEDIA_NOTIFICATION,
  SEND_MEDIA_NOTIFICATION_SUCCESS
} from "../../constants/ActionTypes";

export const onSendMediaNotification = (id, mediaType, text, callback) => {
  return {
    type: SEND_MEDIA_NOTIFICATION,
    id,
    mediaType,
    text,
    callback
  };
};

export const onSendMediaNotificationSuccess = () => {
  return {
    type: SEND_MEDIA_NOTIFICATION_SUCCESS
  };
};
