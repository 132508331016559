import React from "react";
import { Button, Form, Input, message } from "antd";
import { connect } from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn
} from "appRedux/actions/Auth";
import { FormattedMessage } from "react-intl";
import CircularProgress from "components/CircularProgress";
import Images from "assets/images";
import _ from "lodash";
import { quotesTextList } from "../constants/common.js";

const random = Math.floor(Math.random() * quotesTextList.length);
const quote = quotesTextList[random];

class SignIn extends React.PureComponent {
  constructor(props) {
    super(props);
    this.timer = undefined;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._alertMessage(nextProps);
    this._authorizedFlow(nextProps);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  _authorizedFlow = props => {
    const authUser = _.get(props, "authUser");
    const push = _.get(props, "history.push");
    if (!_.isEmpty(authUser) && typeof push === "function") {
      const permission = _.get(props, "authUser.permission");
      const mediaPages = this.filterItems(permission, "media.");
      let redirectPage = "/main";
      // if (!_.isEmpty(mediaPages)) {
      //   if (mediaPages[0].slice(6) === "ribbonsbanners") {
      //     redirectPage = redirectPage + "/media/ribbonBanner";
      //   } else {
      //     redirectPage = redirectPage + "/media/" + mediaPages[0].slice(6);
      //   }
      // }
      push(redirectPage);
    }
  };

  filterItems = (array, query) => {
    return array.filter(function(el) {
      return el.toLowerCase().indexOf(query.toLowerCase()) > -1;
    });
  };

  _alertMessage = props => {
    const { showMessage, hideMessage, alertMessage = "" } = props;
    if (
      showMessage &&
      alertMessage !== "" &&
      typeof hideMessage === "function"
    ) {
      message.error(alertMessage.toString());
      this.timer = setTimeout(() => {
        hideMessage();
      }, 100);
    }
  };

  _handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.showAuthLoader();
        this.props.userSignIn(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loader } = this.props;

    return (
      <div id="gx-app-login-wrap" className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <h2 className="quote-text">
            <span className="material-icons quote-text__icon-first _icon">
              format_quote
            </span>
            {quote.text}
            <span className="material-icons _icon">format_quote</span>
            <span className="quote-text__author">&mdash; {quote.author}</span>
          </h2>
          <div className="gx-app-login-main-content">
            <div
              className="gx-app-logo-content"
              style={{ padding: "0", minHeight: "272px" }}
            >
              <div className="gx-app-logo-wid">
                <img src={Images.APP_LOGO} alt="Neature" />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                onSubmit={this._handleSubmit}
                className="gx-signin-form gx-form-row0"
              >
                <FormItem>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        type: "email",
                        message: "The input is not valid E-mail!"
                      }
                    ]
                  })(<Input placeholder="Email" />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("password", {
                    rules: [
                      { required: true, message: "Please input your Password!" }
                    ]
                  })(<Input type="password" placeholder="Password" />)}
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <FormattedMessage id="app.userAuth.signIn" />
                  </Button>
                </FormItem>
              </Form>
            </div>
            {loader ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : (
              undefined
            )}
          </div>
        </div>
      </div>
    );
  }
}

const FormItem = Form.Item;
const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn
})(WrappedNormalLoginForm);
