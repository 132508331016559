import api from "./index";
import { apisDetail } from "../../constants/APIsURL";
// import { SIGNOUT_USER_SUCCESS } from "../../constants/ActionTypes";
import _ from "lodash";

export default function createApiAuthMiddle({ dispatch, getState }) {
  return next => action => {
    // const type = _.get(action, "type");
    // const isAuthRequestApi = _.get(action, "isAuthRequestApi");
    const authToken = _.get(getState(), "auth.authUser.access_token.id");
    // if (type === SIGNOUT_USER_SUCCESS) {
    //   api.changeHeaders({
    //     [apisDetail.headers.AUTHORIZATION]: ""
    //   });
    // } else if (isAuthRequestApi === true && authToken !== "") {
    //   api.changeHeaders({
    //     [apisDetail.headers.AUTHORIZATION]: authToken
    //   });
    // }
    api.changeHeaders({
      [apisDetail.headers.AUTHORIZATION]: authToken
    });
    return next(action);
  };
}
