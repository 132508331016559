import {
  GET_REVIEWS,
  FETCH_ALL_REVIEWS_SUCCESS,
  DELETE_REVIEW_SUCCESS,
  FETCH_UPDATE_REVIEW_SUCCESS,
  GET_DETAIL_REVIEW_SUCCESS,
  GET_DETAIL_REVIEW,
  FETCH_ERROR,
  DELETE_REVIEW,
  APPROVE_REVIEW,
  APPROVE_REVIEW_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  reviewsList: [],
  reviewDetail: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DETAIL_REVIEW: {
      return {
        ...state,
        reviewDetail: null
      };
    }
    case GET_DETAIL_REVIEW_SUCCESS: {
      return {
        ...state,
        reviewDetail: action.payload
      };
    }
    case GET_REVIEWS:
      return {
        ...state,
        loading: true
      };
    case FETCH_ALL_REVIEWS_SUCCESS: {
      return {
        ...state,
        reviewsList: action.payload,
        loading: false
      };
    }
    case DELETE_REVIEW: {
      return {
        ...state,
        status: "DELETING"
      };
    }
    case DELETE_REVIEW_SUCCESS: {
      return {
        ...state,
        status: "DELETED"
      };
    }
    case APPROVE_REVIEW: {
      return {
        ...state,
        status: "APPROVE"
      };
    }
    case APPROVE_REVIEW_SUCCESS: {
      return {
        ...state,
        state: "APPROVED"
      };
    }
    case FETCH_UPDATE_REVIEW_SUCCESS: {
      return {
        ...state,
        reviewsList: action.payload
      };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, error: action.payload, message: "" };
    }
    default:
      return state;
  }
};
