import {
    GET_GREETINGS,
    FETCH_ALL_GREETINGS_SUCCESS,
    DELETE_GREETINGS,
    DELETE_GREETINGS_SUCCESS,
    DELETE_GREETINGS_FAILED
} from "../../constants/ActionTypes";
  
export const onGetGreetings = queries => {
    return {
        type: GET_GREETINGS,
        payload: queries
    };
};

export const fetchGreetingsSuccess = data => {
    return {
        type: FETCH_ALL_GREETINGS_SUCCESS,
        payload: data
    };
};


export const onDeleteGreetings = (greetings_id, callback) => {
    console.log("id" ,greetings_id);
    return {
        type: DELETE_GREETINGS,
        greetings_id: greetings_id,
        callback
    };
}

export const onDeleteGreetingsSuccess = payload => {
    return {
        type: DELETE_GREETINGS_SUCCESS,
        payload
    };
};
  


