import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchPeoplesSuccess,
  getDetailPeopleSuccess,
  updatePeopleSuccess,
  createPeopleSuccess,
  deletePeopleSuccess,
  updatePeopleFailed,
  createPeopleFailed
} from "../actions/People";
import {
  GET_PEOPLE,
  DELETE_PEOPLE,
  UPDATE_PEOPLE,
  CREATE_PEOPLE,
  GET_DETAIL_PEOPLE
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import api from "../drivers";
import get from "lodash/get";
import convertOrderBy from "../../util/convertOrderBy";

export const getPeople = async params => {
  if (params.order_by) params.order_by = convertOrderBy(params.order_by);

  return await api
    .get(apisDetail.apisPeople, params)
    .then(resData => resData.data);
};

const getDetailPeople = async ({ idPeople }) =>
  await api
    .get(`${apisDetail.apisPeople}/${idPeople}`)
    .then(resData => resData.data);

const deletePeopleRequest = async idPeople =>
  await api
    .delete(`${apisDetail.apisPeople}/${idPeople}`)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.deleteData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.deleteDataError" />
      );
      throw error;
    });

const updatePeople = async ({ idPeople, data }) =>
  await api
    .put(`${apisDetail.apisPeople}/${idPeople}`, data)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.updateData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateDataError" />
      );
      throw error;
    });

const createPeople = async data =>
  await api
    .post(`${apisDetail.apisPeople}`, data)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.createPeopleSuccess" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.createDataError" />
      );
      throw error;
    });

/**
 * Define Saga action
 */
function* fetchPeopleRequest({ payload }) {
  try {
    const fetchedPeople = yield call(getPeople, payload);
    yield put(fetchPeoplesSuccess(fetchedPeople));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* onGetDetail(action) {
  try {
    const fetchedPeople = yield call(getDetailPeople, action);
    yield put(getDetailPeopleSuccess(fetchedPeople));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* onCreatePeople({ data, callback }) {
  try {
    yield call(createPeople, data);
    yield put(createPeopleSuccess());
    callback && callback();
  } catch (error) {
    yield put(createPeopleFailed(error));
  }
}

function* onUpdatePeople({ idPeople, data, callback }) {
  try {
    yield call(updatePeople, { idPeople, data });
    yield put(updatePeopleSuccess());
    callback && callback();
  } catch (error) {
    yield put(updatePeopleFailed(error));
  }
}

function* deletePeople({ idPeople, callback }) {
  try {
    yield call(deletePeopleRequest, idPeople);
    yield put(deletePeopleSuccess());
    callback && callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
 * Define Saga action
 */

/**
 * Receive Redux Action
 */

function* fetchPeople() {
  yield takeEvery(GET_PEOPLE, fetchPeopleRequest);
}

function* fetchDetailPeople() {
  yield takeEvery(GET_DETAIL_PEOPLE, onGetDetail);
}

function* fetchDeletePeople() {
  yield takeEvery(DELETE_PEOPLE, deletePeople);
}

function* fetchUpdatePeople() {
  yield takeEvery(UPDATE_PEOPLE, onUpdatePeople);
}

function* fetchCreatePeople() {
  yield takeEvery(CREATE_PEOPLE, onCreatePeople);
}

export default function* rootSaga() {
  yield all([
    fork(fetchPeople),
    fork(fetchDeletePeople),
    fork(fetchUpdatePeople),
    fork(fetchCreatePeople),
    fork(fetchDetailPeople)
  ]);
}
