import React from "react";
export const AdsSvg = (
  <svg x="0px" y="0px" viewBox="0 0 512 512">
    <g>
      <g>
        <path d="M504.5,78.5H464V36c0-4.142-3.357-7.5-7.5-7.5H7.5C3.358,28.5,0,31.858,0,36v322.667c0,4.142,3.358,7.5,7.5,7.5    s7.5-3.358,7.5-7.5V93.5h434v73.083c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5V143.5h33v325h-49.5c-4.143,0-7.5,3.358-7.5,7.5    c0,4.142,3.357,7.5,7.5,7.5h57c4.143,0,7.5-3.358,7.5-7.5V86C512,81.858,508.643,78.5,504.5,78.5z M112,78.5H15v-35h97V78.5z     M449,78.5H127v-35h322V78.5z M497,128.5h-33v-35h33V128.5z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M456.5,191.5c-4.143,0-7.5,3.358-7.5,7.5v219.5H15V391c0-4.142-3.358-7.5-7.5-7.5S0,386.858,0,391v35    c0,4.142,3.358,7.5,7.5,7.5H48V476c0,4.142,3.358,7.5,7.5,7.5h360.542c4.143,0,7.5-3.358,7.5-7.5c0-4.142-3.357-7.5-7.5-7.5H63    v-35h393.5c4.143,0,7.5-3.358,7.5-7.5V199C464,194.858,460.643,191.5,456.5,191.5z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M307,325.167H147c-15.164,0-27.5,12.336-27.5,27.5v20c0,15.164,12.336,27.5,27.5,27.5h160c15.163,0,27.5-12.336,27.5-27.5    v-20C334.5,337.503,322.163,325.167,307,325.167z M319.5,372.667c0,6.893-5.607,12.5-12.5,12.5H147c-6.893,0-12.5-5.607-12.5-12.5    v-20c0-6.893,5.607-12.5,12.5-12.5h160c6.893,0,12.5,5.607,12.5,12.5V372.667z" />
      </g>
    </g>
    <g>
      <g>
        <circle cx="36.5" cy="61" r="7.5" />
      </g>
    </g>
    <g>
      <g>
        <circle cx="92.5" cy="61" r="7.5" />
      </g>
    </g>
    <g>
      <g>
        <circle cx="64.5" cy="61" r="7.5" />
      </g>
    </g>
    <g>
      <g>
        <path d="M179.387,278.543l-47.143-131.999c-1.064-2.979-3.883-4.971-7.047-4.978c-0.005,0-0.01,0-0.016,0    c-3.157,0-5.978,1.978-7.052,4.948c-0.118,0.326-11.919,32.936-23.571,65.345c-23.999,66.752-24.003,67.306-24.019,69.142    c-0.037,4.142,3.292,7.529,7.434,7.565c0.022,0,0.044,0,0.067,0c3.603,0,6.622-2.546,7.338-5.948    c0.804-2.715,6.424-18.597,13.993-39.754h51.346l14.544,40.724c1.095,3.067,3.983,4.979,7.063,4.979    c0.837,0,1.689-0.141,2.522-0.439C178.747,286.735,180.78,282.443,179.387,278.543z M104.746,227.865    c6.457-17.994,13.704-38.104,20.392-56.621l20.222,56.621H104.746z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M198.039,141.567h-2.572c-4.142,0-7.5,3.358-7.5,7.5v132c0,4.142,3.358,7.5,7.5,7.5h2.572c40.528,0,73.5-32.972,73.5-73.5    C271.539,174.539,238.567,141.567,198.039,141.567z M202.967,273.36V156.772c29.959,2.511,53.571,27.697,53.571,58.294    C256.538,245.663,232.926,270.85,202.967,273.36z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M336.854,204.995h-15.816c-12.17,0-22.071-9.901-22.071-22.071v-4.286c0-12.17,9.901-22.071,22.071-22.071h9.429    c12.17,0,22.071,9.901,22.071,22.071c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5c0-20.441-16.63-37.071-37.071-37.071h-9.429    c-20.441,0-37.071,16.63-37.071,37.071v4.286c0,20.441,16.63,37.071,37.071,37.071h15.816c8.648,0,15.684,7.036,15.684,15.684    v15.816c0,12.17-9.901,22.072-22.071,22.072h-9.429c-12.17,0-22.071-9.901-22.071-22.072v-4.714c0-4.142-3.358-7.5-7.5-7.5    c-4.143,0-7.5,3.358-7.5,7.5v4.714c0,20.441,16.63,37.072,37.071,37.072h9.429c20.441,0,37.071-16.63,37.071-37.072v-15.816    C367.538,218.76,353.773,204.995,336.854,204.995z" />
      </g>
    </g>
  </svg>
);

export const StatisticSvg = (
  <svg x="0px" y="0px" viewBox="0 0 442 442">
    <g>
      <path d="M171,336H70c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.523,0,10-4.477,10-10S176.523,336,171,336z" />
      <path d="M322,336H221c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.522,0,10-4.477,10-10S327.522,336,322,336z" />
      <path d="M322,86H70c-5.523,0-10,4.477-10,10s4.477,10,10,10h252c5.522,0,10-4.477,10-10S327.522,86,322,86z" />
      <path d="M322,136H221c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.522,0,10-4.477,10-10S327.522,136,322,136z" />
      <path d="M322,186H221c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.522,0,10-4.477,10-10S327.522,186,322,186z" />
      <path d="M322,236H221c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.522,0,10-4.477,10-10S327.522,236,322,236z" />
      <path d="M322,286H221c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.522,0,10-4.477,10-10S327.522,286,322,286z" />
      <path d="M171,286H70c-5.523,0-10,4.477-10,10s4.477,10,10,10h101c5.523,0,10-4.477,10-10S176.523,286,171,286z" />
      <path
        d="M171,136H70c-5.523,0-10,4.477-10,10v101c0,5.523,4.477,10,10,10h101c5.523,0,10-4.477,10-10V146
		C181,140.477,176.523,136,171,136z M161,237H80v-81h81V237z"
      />
      <path
        d="M422,76h-30V46c0-11.028-8.972-20-20-20H20C8.972,26,0,34.972,0,46v320c0,27.57,22.43,50,50,50h342c27.57,0,50-22.43,50-50
		V96C442,84.972,433.028,76,422,76z M422,366c0,16.542-13.458,30-30,30H50c-16.542,0-30-13.458-30-30V46h352v305
		c0,13.785,11.215,25,25,25c5.522,0,10-4.477,10-10s-4.478-10-10-10c-2.757,0-5-2.243-5-5V96h30V366z"
      />
    </g>
  </svg>
);

export const DashboardSvg = (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 428.948 428.948"
  >
    <g>
      <g>
        <path
          d="M221.988,280.48c9.664,0,17.516-7.889,17.516-17.563c0-9.653-7.852-17.487-17.516-17.487
			l-79.361-58.371l61.844,76.985C205.045,273.208,212.68,280.48,221.988,280.48z"
        />
        <rect
          x="127.008"
          y="332.197"
          width="174.933"
          height="41.513"
        />
        <path
          d="M366.13,118.057c-40.51-40.509-94.369-62.818-151.656-62.818
			c-57.288,0-111.148,22.309-151.657,62.818S0,212.425,0,269.713c0,36.99,9.319,72.539,26.816,103.997h40.086l29.319-16.929
			l-12.948-22.428l-37.7,21.768c-13.319-25.932-20.843-55.307-20.843-86.408c0-5.504,0.25-10.951,0.711-16.338l44.952,7.926
			l4.497-25.504l-45.481-8.019c8.127-35.883,26.451-67.937,51.755-92.948l30.815,36.725l19.839-16.646l-31.049-37.002
			c28.471-21.379,63.128-34.938,100.757-37.485v49.117h25.896V80.422c37.629,2.549,72.286,16.107,100.758,37.486l-31.05,37.001
			l19.838,16.646l30.816-36.726c25.303,25.012,43.627,57.066,51.754,92.948l-45.48,8.021l4.498,25.502l44.949-7.927
			c0.461,5.388,0.711,10.834,0.711,16.338c0,31.103-7.521,60.479-20.842,86.409l-37.701-21.766l-12.947,22.427l29.318,16.927h40.088
			c17.498-31.458,26.816-67.007,26.816-103.997C428.949,212.424,406.638,158.564,366.13,118.057z"
        />
      </g>
    </g>
  </svg>
);
