import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE
} from "../../constants/ActionTypes";
import get from "lodash/get";
import { NotificationManager } from "react-notifications";

export const fetchStart = () => {
  return {
    type: FETCH_START
  };
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  };
};

export const fetchError = error => {
  if (
    get(error, "response.status") === 403 &&
    get(error, "response.statusText") === "Forbidden"
  ) {
    NotificationManager.error(
      get(error, "response.statusText"),
      `ErrorCode: ${get(error, "response.status")}`
    );
  }
  return {
    type: FETCH_ERROR,
    payload: error
  };
};

export const showMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};
