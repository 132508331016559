import {
  SEND_MEDIA_NOTIFICATION,
  SEND_MEDIA_NOTIFICATION_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  id: "",
  type: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SEND_MEDIA_NOTIFICATION: {
      return {
        ...state,
        id: action.id,
        type: action.id,
        text: action.text,
        sending: true
      };
    }

    case SEND_MEDIA_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        sending: false
      };
    }    

    default:
      return state;
  }
};
