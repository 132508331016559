import {
  UPDATE_STATUS,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAILED
} from "../../constants/ActionTypes";

export const onUpdateStatus = (data, callback) => {
  return {
    type: UPDATE_STATUS,
    payload: data,
    callback
  };
};

export const onUpdateStatusSuccess = () => {
  return {
    type: UPDATE_STATUS_SUCCESS
  };
};

export const onUpdateStatusFailed = error => {
  return {
    type: UPDATE_STATUS_FAILED,
    error
  };
};
