import {
  GET_INTERACTIVE_MOVIE,
  GET_INTERACTIVE_MOVIE_SUCCESS,
  CREATE_INTERACTIVE_PART_LIST,
  CREATE_INTERACTIVE_PART_LIST_SUCCESS,
  CREATE_INTERACTIVE_PART_LIST_FAILED,
  CREATE_INTERACTIVE_MOVIE,
  CREATE_INTERACTIVE_MOVIE_FAILED,
  CREATE_INTERACTIVE_MOVIE_SUCCESS,
  GET_DETAIL_INTERACTIVE_MOVIE,
  GET_DETAIL_INTERACTIVE_MOVIE_SUCCESS,
  GET_DETAIL_INTERACTIVE_PART_MOVIE,
  GET_DETAIL_INTERACTIVE_PART_MOVIE_SUCCESS,
  UPDATE_INTERACTIVE_MOVIE,
  UPDATE_INTERACTIVE_MOVIE_SUCCESS,
  UPDATE_INTERACTIVE_MOVIE_FAILED,
  DELETE_INTERACTIVE_MOVIE,
  DELETE_INTERACTIVE_MOVIE_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  moviesList: [],
  idMovie: "",
  dataResponse: {},
  interactivePartsResponse: {},
  movieDetail: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INTERACTIVE_MOVIE: {
      return {
        ...state,
        loadingList: true,
        actions: {
          searchText: action.payload.name,
          selectedTab: action.payload.movie_type,
          limitItem: action.payload.limit,
          filteredInfo: action.payload.channelIdsProps,
          sortedInfo: {
            field: action.payload.sort_by,
            columnKey: action.payload.sort_by,
            order: action.payload.order_by
          }
        }
      };
    }
    case GET_INTERACTIVE_MOVIE_SUCCESS: {
      return {
        ...state,
        moviesList: action.payload,
        loadingList: false
      };
    }

    case CREATE_INTERACTIVE_PART_LIST: {
      return {
        ...state,
        creatingList: true
      };
    }
    case CREATE_INTERACTIVE_PART_LIST_SUCCESS: {
      return {
        ...state,
        creatingList: false,
        interactivePartsResponse: action.payload,
      };
    }
    case CREATE_INTERACTIVE_PART_LIST_FAILED: {
      return {
        ...state,
        creatingList: false
      };
    }

    case CREATE_INTERACTIVE_MOVIE: {
      return {
        ...state,
        creating: true
      };
    }
    case CREATE_INTERACTIVE_MOVIE_SUCCESS: {
      return {
        ...state,
        dataResponse: action.payload,
        creating: false
      };
    }
    case CREATE_INTERACTIVE_MOVIE_FAILED: {
      return {
        ...state,
        creating: false
      };
    }   

    case GET_DETAIL_INTERACTIVE_MOVIE: {
      return {
        ...state,
        idMovie: action.idMovie,
        loadingDetail: true
      };
    }
    case GET_DETAIL_INTERACTIVE_MOVIE_SUCCESS: {
      return {
        ...state,
        movieDetail: action.payload,
        loadingDetail: false
      };
    }

    case GET_DETAIL_INTERACTIVE_PART_MOVIE: {
      return {
        ...state,
        idInteractivePart: action.idMovie,
        interactivePartDetail: {},
        loadingPartDetail: true
      };
    }
    case GET_DETAIL_INTERACTIVE_PART_MOVIE_SUCCESS: {
      return {
        ...state,
        interactivePartDetail: action.payload,
        loadingPartDetail: false
      };
    }

    case DELETE_INTERACTIVE_MOVIE: {
      return {
        ...state,
        idInteractiveMovie: action.idMovie,
        loadingList: true
      };
    }
    case DELETE_INTERACTIVE_MOVIE_SUCCESS: {
      return {
        ...state,
        loadingList: false
      };
    }

    case UPDATE_INTERACTIVE_MOVIE: {
      return {
        ...state,
        updating: true
      };
    }
    case UPDATE_INTERACTIVE_MOVIE_SUCCESS: {
      return {
        ...state,
        dataResponse: action.payload,
        updating: false
      };
    }
    case UPDATE_INTERACTIVE_MOVIE_FAILED: {
      return {
        ...state,
        updating: false
      };
    }
     
    default:
      return state;
  }
};
