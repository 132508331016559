import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  getTranscodeHistorySuccess,
  startTranscodeSuccess,
  startTranscodeFailed,
  getTranscodeHistory,
  onGetTranscodeStatusSuccess,
  onGetTranscodeStatusFailed,
  stopTranscodeFailed,
  stopTranscodeSuccess
} from "../actions/Transcode";
import {
  GET_LIST_TRANSCODE_HISTORY,
  START_TRANSCODE,
  GET_TRANSCODE_STATUS,
  STOP_TRANSCODE
} from "../../constants/actionTypes/transcode";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import api from "../drivers";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import get from "lodash/get";

const ApiGetTranscodeHistory = async params =>
  await api
    .get(`${apisDetail.apisTranscode}/history`, params)
    .then(resData => resData.data);

const apiStartTranscode = async params =>
  await api
    .post(`${apisDetail.apisTranscode}/start`, params)
    .then(resData => {
      // NotificationManager.success(
      //   get(resData, 'data.message', ""),
      //   <FormattedMessage id="notification.success" />
      // )
      return resData.data;
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.error" />
      );
      throw error;
    });

const apiStopTranscode = async params =>
  await api
    .post(`${apisDetail.apisTranscode}/stop`, params)
    .then(resData => {
      NotificationManager.success(
        get(resData, 'data.message', ""),
        <FormattedMessage id="notification.stopTranscodeSuccess" />
      )
      return resData.data;
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.stopTranscodeFailed" />
      );
      throw error;
    });

const apiGetTranscodeStatus = async params =>
  await api
    .get(`${apisDetail.apisTranscode}/status`, params)
    .then(resData => {
      return resData.data;
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.error" />
      );
      throw error;
    });
// -------------------------
// Define Saga actions
// -------------------------
function* fetchTranscodeHistory({ payload }) {
  try {
    const fetchedTranscode = yield call(ApiGetTranscodeHistory, payload);
    yield put(getTranscodeHistorySuccess(fetchedTranscode));
  } catch (error) {
    // yield put(fetchError(error));
  }
}

function* onStartTranscode({ payload, callback }) {
  try {
    const result = yield call(apiStartTranscode, payload);
    yield put(startTranscodeSuccess(result));
    yield put(getTranscodeHistory(payload));
    callback && callback();
  } catch (error) {
    yield put(startTranscodeFailed(error));
  }
}

function* handleGetTranscodeStatus({ payload, callback }) {
  try {
    const result = yield call(apiGetTranscodeStatus, payload);
    yield put(
      onGetTranscodeStatusSuccess({ status: result, jobId: payload.job_id })
    );
  } catch (error) {
    yield put(onGetTranscodeStatusFailed(error));
    callback && callback();
    // yield put(fetchError(error));
  }
}

function* onStopTranscode({ payload }) {
  try {
    const result = yield call(apiStopTranscode, payload);
    yield put(stopTranscodeSuccess(result));
  } catch (error) {
    yield put(stopTranscodeFailed(error));
  }
}

// watch action redux
function* watchGetListTranscode() {
  yield takeEvery(GET_LIST_TRANSCODE_HISTORY, fetchTranscodeHistory);
}

function* watchStartTranscode() {
  yield takeEvery(START_TRANSCODE, onStartTranscode);
}

function* watchGetTranscodeStatus() {
  yield takeEvery(GET_TRANSCODE_STATUS, handleGetTranscodeStatus);
}

function* watchStopTranscode() {
  yield takeEvery(STOP_TRANSCODE, onStopTranscode);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetListTranscode),
    fork(watchStartTranscode),
    fork(watchGetTranscodeStatus),
    fork(watchStopTranscode)
  ]);
}
