import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchCollectionsSuccess,
  getDetailCollectionSuccess,
  createCollectionSuccess,
  updateCollectionSuccess,
  deleteCollectionSuccess,
  updateCollectionFailed,
  createCollectionFailed
} from "../actions/Collection";
import {
  GET_COLLECTION,
  DELETE_COLLECTION,
  UPDATE_COLLECTION,
  CREATE_COLLECTION,
  GET_DETAIL_COLLECTION
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import api from "../drivers";
import get from "lodash/get";
import convertOrderBy from "../../util/convertOrderBy";

export const getCollection = async params => {
  if (params.order_by) params.order_by = convertOrderBy(params.order_by);
  delete params.filterProps; // remove unused params

  return await api
    .get(apisDetail.apisCollection, params)
    .then(resData => resData.data);
};

const getDetailCollection = async ({ idCollection }) =>
  await api
    .get(`${apisDetail.apisCollection}/${idCollection}`)
    .then(resData => resData.data);

const deleteCollectionRequest = async idCollection =>
  await api
    .delete(`${apisDetail.apisCollection}/${idCollection}`)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.deleteData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.deleteDataError" />
      );
      throw error;
    });

const updateCollection = async ({ idCollection, data }) =>
  await api
    .put(`${apisDetail.apisCollection}/${idCollection}`, data)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.updateData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateDataError" />
      );
      throw error;
    });

const createCollection = async data =>
  await api
    .post(`${apisDetail.apisCollection}`, data)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.createData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.createDataError" />
      );
      throw error;
    });
/**
 * Define Saga action
 */
function* fetchCollectionRequest({ payload }) {
  try {
    const fetchedCollection = yield call(getCollection, payload);
    yield put(fetchCollectionsSuccess(fetchedCollection));
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* onGetDetail(action) {
  try {
    const fetchedCollection = yield call(getDetailCollection, action);
    yield put(getDetailCollectionSuccess(fetchedCollection));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* onCreate({ data, callback }) {
  try {
    yield call(createCollection, data);
    yield put(createCollectionSuccess());
    callback && callback();
  } catch (error) {
    yield put(createCollectionFailed(error));
  }
}

function* onUpdate({ idCollection, data, callback }) {
  try {
    yield call(updateCollection, { idCollection, data });
    yield put(updateCollectionSuccess());
    callback && callback();
  } catch (error) {
    yield put(updateCollectionFailed(error));
  }
}

function* deleteCollection({ idCollection, callback }) {
  try {
    yield call(deleteCollectionRequest, idCollection);
    yield put(deleteCollectionSuccess());
    callback && callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}
/**
 * Define Saga action
 */

/**
 * Receive Redux Action
 */

export function* fetchCollection() {
  yield takeEvery(GET_COLLECTION, fetchCollectionRequest);
}

export function* fetchDetailCollection() {
  yield takeEvery(GET_DETAIL_COLLECTION, onGetDetail);
}

export function* fetchDeleteCollection() {
  yield takeEvery(DELETE_COLLECTION, deleteCollection);
}

export function* fetchUpdateCollection() {
  yield takeEvery(UPDATE_COLLECTION, onUpdate);
}

export function* fetchCreateCollection() {
  yield takeEvery(CREATE_COLLECTION, onCreate);
}

export default function* rootSaga() {
  yield all([
    fork(fetchCollection),
    fork(fetchDeleteCollection),
    fork(fetchUpdateCollection),
    fork(fetchCreateCollection),
    fork(fetchDetailCollection)
  ]);
}
