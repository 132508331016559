import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import asyncComponent from "../util/asyncComponent";
// import CustomViews from "./customViews";
// import Extensions from "./extensions";
// import ExtraComponents from "./extraComponents";
// import InBuiltApps from "./inBuiltApps";
import Main from "./main";
// import Documents from "./documents";

// const Main = asyncComponent(() => import('./main'))
const Documents = asyncComponent(() => import("./documents"));
const Page404 = asyncComponent(() => import("./main/noMatchPage/page404"));

const App = ({ match }) => (
  <div id="gx-main-content-wrapper" className="gx-main-content-wrapper">
    <Switch>
      {/* <Redirect exact from={match.url} to={`${match.url}main/dashboard`}/> */}
      <Redirect exact from={match.url} to={`${match.url}main`} />
      <Route path={`${match.url}main`} component={Main} />
      {/* <Route path={`${match.url}custom-views`} component={CustomViews} /> */}
      {/* <Route path={`${match.url}extensions`} component={Extensions} /> */}
      {/* <Route
        path={`${match.url}extra-components`}
        component={ExtraComponents}
      /> */}
      {/* <Route path={`${match.url}in-built-apps`} component={InBuiltApps} /> */}
      <Route component={Page404} />
      <Route path={`${match.url}documents`} component={Documents} />
    </Switch>
  </div>
);

export default App;
