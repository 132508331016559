import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  onGetInteractiveMovieSuccess,
  onCreateInteractivePartListSuccess,
  onCreateInteractivePartFailed,
  createInteractiveMovieSuccess,
  createInteractiveMovieFailed,
  getDetailInteractiveMovieSuccess,
  getDetailInteractivePartMovieSuccess,
  updateInteractiveMovieSuccess,
  updateInteractiveMovieFailed,
  deleteInteractiveMovieSuccess
} from "../../appRedux/actions/InteractiveMovie";
import {
  GET_INTERACTIVE_MOVIE,
  CREATE_INTERACTIVE_PART_LIST,
  CREATE_INTERACTIVE_MOVIE,
  GET_DETAIL_INTERACTIVE_MOVIE,
  GET_DETAIL_INTERACTIVE_PART_MOVIE,
  UPDATE_INTERACTIVE_MOVIE,
  DELETE_INTERACTIVE_MOVIE
} from "constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import api from "../drivers";
import get from "lodash/get";
import convertOrderBy from "../../util/convertOrderBy";

export const getMovies = async params => {
  if (params.order_by) params.order_by = convertOrderBy(params.order_by);
  if (params.movie_type === "onContent") delete params.movie_type;
  delete params.channelIdsProps; // remove unused params

  return await api
    .get(apisDetail.apisMovie, params)
    .then(resData => resData.data);
};

const createInteractivePart = async data => {
  const url = `${apisDetail.apisInteractivePartList}`;
  return await api
    .post(url, data)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.createData" />,
        <FormattedMessage id="notification.success" />
      );
      return resData.data;
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateDataError" />
      );
      throw error;
    });
};

const getDetailInteractiveMovieRequest = async idMovie =>
  await api
    .get(`${apisDetail.apisMovie}/${idMovie}`)
    .then(resData => resData.data);

const deleteInteractiveMovieRequest = async idMovie => {
  const url = `${apisDetail.apisMovie}/${idMovie}`;
  return await api
    .delete(url)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.deleteData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.deleteDataError" />
      );
      throw error;
    });
};

const updateInteractiveMovieRequest = async ({ idMovie, data }) =>
  await api
    .put(`${apisDetail.apisMovie}/${idMovie}`, data)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.updateData" />,
        <FormattedMessage id="notification.success" />
      );
      return resData.data
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateDataError" />
      );
      throw error;
    });

const createInteractiveMovie = async data => {
  const url = `${apisDetail.apisMovie}`;
  return await api
    .post(url, data)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.createData" />,
        <FormattedMessage id="notification.success" />
      );
      return resData.data;
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateDataError" />
      );
      throw error;
    });
};

function* fetchMovieRequest({ payload }) {
  try {
    const fetchedMovie = yield call(getMovies, payload);
    yield put(onGetInteractiveMovieSuccess(fetchedMovie));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* createInteractivePartRequest({ payload, callback }) {
  try {
    const createdInteractivePart = yield call(createInteractivePart, payload);
    yield put(onCreateInteractivePartListSuccess(createdInteractivePart));
    callback && callback();
  } catch (error) {
    yield put(onCreateInteractivePartFailed(error));
  }
}

function* getDetailInteractiveMovie({ payload }) {
  const { idMovie } = payload;
  try {
    const fetchedMovie = yield call(getDetailInteractiveMovieRequest, idMovie);
    yield put(getDetailInteractiveMovieSuccess(fetchedMovie));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getDetailInteractivePartMovie({ payload }) {
  const { idMovie } = payload;
  try {
    const fetchedMovie = yield call(getDetailInteractiveMovieRequest, idMovie);
    yield put(getDetailInteractivePartMovieSuccess(fetchedMovie));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* createInteractiveMovieRequest({ payload, callback }) {
  const { data } = payload;
  try {
    const fetchedInteractive = yield call(createInteractiveMovie, data);
    yield put(createInteractiveMovieSuccess(fetchedInteractive));
    callback && callback();
  } catch (error) {
    yield put(createInteractiveMovieFailed(error));
  }
}

function* updateInteractiveMovie({ idMovie, data, callback }) {
  try {
    const updatedMovie = yield call(updateInteractiveMovieRequest, { idMovie, data });
    yield put(updateInteractiveMovieSuccess(updatedMovie));
    callback && callback();
  } catch (error) {
    yield put(updateInteractiveMovieFailed(error));
  }
}

function* deleteInteractiveMovie({ idMovie, callback }) {
  try {
    yield call(deleteInteractiveMovieRequest, idMovie);
    yield put(deleteInteractiveMovieSuccess());
    callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* fetchMovie() {
  yield takeEvery(GET_INTERACTIVE_MOVIE, fetchMovieRequest);
}

export function* fetchCreateInteractiveMovie() {
  yield takeEvery(CREATE_INTERACTIVE_MOVIE, createInteractiveMovieRequest);
}

export function* fetchCreateInteractivePart() {
  yield takeEvery(CREATE_INTERACTIVE_PART_LIST, createInteractivePartRequest);
}

export function* fetchDetailInteractiveMovie() {
  yield takeEvery(GET_DETAIL_INTERACTIVE_MOVIE, getDetailInteractiveMovie);
}

export function* fetchDetailInteractivePartMovie() {
  yield takeEvery(GET_DETAIL_INTERACTIVE_PART_MOVIE, getDetailInteractivePartMovie);
}

export function* fetchDeleteInteractiveMovie() {
  yield takeEvery(DELETE_INTERACTIVE_MOVIE, deleteInteractiveMovie);
}

export function* fetchUpdateInteractiveMovie() {
  yield takeEvery(UPDATE_INTERACTIVE_MOVIE, updateInteractiveMovie);
}

export default function* rootSaga() {
  yield all([
    fork(fetchMovie),
    fork(fetchCreateInteractiveMovie),
    fork(fetchCreateInteractivePart),
    fork(fetchDetailInteractiveMovie),
    fork(fetchDetailInteractivePartMovie),
    fork(fetchUpdateInteractiveMovie),
    fork(fetchDeleteInteractiveMovie)
  ]);
}
