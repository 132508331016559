import {
  GET_MOVIE,
  FETCH_ALL_MOVIE_SUCCESS,
  DELETE_MOVIE,
  DELETE_MOVIE_SUCCESS,
  UPDATE_MOVIE,
  FETCH_UPDATE_MOVIE_SUCCESS,
  UPDATE_MOVIE_FAILED,
  CREATE_MOVIE,
  CREATE_MOVIE_FAILED,
  FETCH_CREATE_MOVIE_SUCCESS,
  GET_DETAIL_MOVIE,
  GET_DETAIL_MOVIE_SUCCESS,
  GET_EPISODE,
  GET_EPISODE_SUCCESS
} from "../../constants/ActionTypes";

export const onGetDetailMovie = idMovie => {
  return {
    type: GET_DETAIL_MOVIE,
    payload: { idMovie }
  };
};

export const getDetailMovieSuccess = movieDetail => {
  return {
    type: GET_DETAIL_MOVIE_SUCCESS,
    payload: movieDetail
  };
};

export const onGetMovie = queries => {
  return {
    type: GET_MOVIE,
    payload: queries
  };
};

export const fetchMoviesSuccess = moviesList => {
  return {
    type: FETCH_ALL_MOVIE_SUCCESS,
    payload: moviesList
  };
};

export const onGetEpisode = queries => {
  return {
    type: GET_EPISODE,
    payload: queries
  };
};

export const getEpisodeSuccess = episodeList => {
  return {
    type: GET_EPISODE_SUCCESS,
    payload: episodeList
  };
};

export const onDeleteMovie = (idMovie, callback) => {
  return {
    type: DELETE_MOVIE,
    idMovie,
    callback
  };
};

export const deleteMovieSuccess = moviesList => {
  return {
    type: DELETE_MOVIE_SUCCESS,
    payload: moviesList
  };
};

export const onUpdateMovie = (idMovie, data, callback) => {
  return {
    type: UPDATE_MOVIE,
    idMovie,
    data,
    callback
  };
};

export const updateMovieSuccess = moviesList => {
  return {
    type: FETCH_UPDATE_MOVIE_SUCCESS,
    payload: moviesList
  };
};

export const updateMovieFailed = error => {
  return {
    type: UPDATE_MOVIE_FAILED,
    error
  };
};

export const onCreateMovie = (data, callback) => {
  return {
    type: CREATE_MOVIE,
    payload: { data },
    callback
  };
};

export const createMovieSuccess = data => {
  return {
    type: FETCH_CREATE_MOVIE_SUCCESS,
    payload: data
  };
};

export const createMovieFailed = error => {
  return {
    type: CREATE_MOVIE_FAILED,
    error
  };
};
