import {
    GET_CONFIG,
    UPDATE_EVENT_KEY,
    UPDATE_EVENT_KEY_SUCCESS,
    UPDATE_EVENT_KEY_FAILED,
    UPDATE_EVENT_LINK,
    UPDATE_EVENT_LINK_SUCCESS,
    UPDATE_EVENT_LINK_FAILED,
    UPDATE_EVENT_BACKGROUND,
    UPDATE_EVENT_BACKGROUND_SUCCESS,
    UPDATE_EVENT_BACKGROUND_FAILED,
    FETCH_ALL_CONFIG_SUCCESS
} from "../../constants/ActionTypes";
  
export const onGetConfig = () => {
    return {
        type: GET_CONFIG
    };
};

export const fetchConfigsSuccess = data => {
    return {
        type: FETCH_ALL_CONFIG_SUCCESS,
        payload: data
    };
};


export const onUpdateEventKey = (configName, data, callback) => {
    console.log("configName", configName);
    return {
        type: UPDATE_EVENT_KEY,
        config_name: configName,
        data,
        callback
    };
}

export const updateEventKeySuccess = payload => {
    return {
        type: UPDATE_EVENT_KEY_SUCCESS,
        payload
    };
};
  
export const updateEventKeyFailed = error => {
    return {
        type: UPDATE_EVENT_KEY_FAILED,
        error
    };
};

export const onUpdateEventLink = (configName, data, callback) => {
    return {
        type: UPDATE_EVENT_LINK,
        config_name: configName,
        data,
        callback
    };
}

export const updateEventLinkSuccess = payload => {
    return {
        type: UPDATE_EVENT_LINK_SUCCESS,
        payload
    };
};
  
export const updateEventLinkFailed = error => {
    return {
        type: UPDATE_EVENT_LINK_FAILED,
        error
    };
};


export const onUpdateEventBackground = (configName, data, callback) => {
    return {
        type: UPDATE_EVENT_BACKGROUND,
        config_name: configName,
        data,
        callback
    };
}

export const updateEventBackgroundSuccess = payload => {
    return {
        type: UPDATE_EVENT_BACKGROUND_SUCCESS,
        payload
    };
};
  
export const updateEventBackgroundFailed = error => {
    return {
        type: UPDATE_EVENT_BACKGROUND_FAILED,
        error
    };
};
