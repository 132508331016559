import {
  GET_MOVIE_BLACKLIST,
  GET_MOVIE_BLACKLIST_SUCCESS,
  GET_MUSIC_BLACKLIST,
  GET_MUSIC_BLACKLIST_SUCCESS,
  GET_VIDEO_BLACKLIST,
  GET_VIDEO_BLACKLIST_SUCCESS,
  APPROVE_MEDIA_BLACKLIST,
  APPROVE_MEDIA_BLACKLIST_SUCCESS,
  GET_REPORT_CONTENT,
  GET_REPORT_CONTENT_SUCCESS
} from "../../constants/ActionTypes";

export const onGetMovieBlacklist = queries => {
  return {
    type: GET_MOVIE_BLACKLIST,
    payload: queries
  };
};

export const onGetMovieBlacklistSuccess = moviesList => {
  return {
    type: GET_MOVIE_BLACKLIST_SUCCESS,
    payload: moviesList
  };
};

export const onGetMusicBlacklist = queries => {
  return {
    type: GET_MUSIC_BLACKLIST,
    payload: queries
  };
};

export const onGetMusicBlacklistSuccess = musicList => {
  return {
    type: GET_MUSIC_BLACKLIST_SUCCESS,
    payload: musicList
  };
};

export const onGetVideoBlacklist = queries => {
  return {
    type: GET_VIDEO_BLACKLIST,
    payload: queries
  };
};

export const onGetVideoBlacklistSuccess = videoList => {
  return {
    type: GET_VIDEO_BLACKLIST_SUCCESS,
    payload: videoList
  };
};

export const onApproveMediaBlacklist = (queries, callback) => {
  return {
    type: APPROVE_MEDIA_BLACKLIST,
    payload: queries,
    callback
  };
};

export const onApproveMediaBlacklistSuccess = () => {
  return {
    type: APPROVE_MEDIA_BLACKLIST_SUCCESS
  };
};

export const onGetReportContent = queries => {
  return {
    type: GET_REPORT_CONTENT,
    payload: queries
  };
};

export const onGetReportContentSuccess = contentList => {
  return {
    type: GET_REPORT_CONTENT_SUCCESS,
    payload: contentList
  };
};
