import React from "react";
import { FormattedMessage } from "react-intl";
import { NotificationManager } from "react-notifications";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  onChangeMediaTypeSuccess,
  onChangeMediaTypeFailed
} from "../actions/MediaType";
import { CHANGE_MEDIA_TYPE } from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import api from "../drivers";
import get from "lodash/get";

const changeMediaType = async payload =>
  await api
    .post(`${apisDetail.apisMediaExchange}`, payload)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.changeMediaTypeSuccess" />,
        <FormattedMessage id="notification.success" />
      );
      return resData.data;
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.changeMediaTypeFailed" />
      );
      throw error;
    });

/**
 * Define Saga action
 */
function* fetchChangeMediaTypeRequest({ payload, callback }) {
  try {
    const fetched = yield call(changeMediaType, payload);
    yield put(onChangeMediaTypeSuccess(fetched));
    callback && callback();
  } catch (error) {
    yield put(onChangeMediaTypeFailed(error));
  }
}
/**
 * Define Saga action
 */

/**
 * Receive Redux Action
 */
export function* fetchChangeMediaType() {
  yield takeEvery(CHANGE_MEDIA_TYPE, fetchChangeMediaTypeRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchChangeMediaType)]);
}
