import {
  GET_CHANNEL_DETAIL,
  GET_CHANNEL_DETAIL_FAILED,
  GET_CHANNEL_DETAIL_SUCCESS,
  UPDATE_CHANNEL_FAILED,
  UPDATE_CHANNEL,
  UPDATE_CHANNEL_SUCCESS,
  GET_VIEWER_REPORT,
  GET_VIEWER_REPORT_SUCCESS,
  GET_VIEWER_REPORT_FAILED,
  GET_MATOMO_EMAIL,
  GET_MATOMO_EMAIL_SUCCESS,
  GET_MATOMO_EMAIL_FAIL,
  UPDATE_MATOMO_EMAIL,
  UPDATE_MATOMO_EMAIL_SUCCESS,
  UPDATE_MATOMO_EMAIL_FAIL
} from "../actions/Channel";

const INIT_STATE = {
  loading: false,
  detail: null,
  report: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHANNEL_DETAIL:
      return {
        ...state,
        loading: true
      };
    case GET_CHANNEL_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        detail: null,
        error: action.error
      };
    case GET_CHANNEL_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        detail: action.payload
      };

    case UPDATE_CHANNEL:
      return {
        ...state,
        updating: true
      };
    case UPDATE_CHANNEL_FAILED:
      return {
        ...state,
        updating: false
      };
    case UPDATE_CHANNEL_SUCCESS:
      return {
        ...state,
        updating: false
      };
    case GET_VIEWER_REPORT:
      return {
        ...state,
        loadingReport: true
      };
    case GET_VIEWER_REPORT_SUCCESS:
      return {
        ...state,
        report: action.payload,
        loadingReport: false
      };
    case GET_VIEWER_REPORT_FAILED:
      return {
        ...state,
        loadingReport: false
      };

    case GET_MATOMO_EMAIL:
      return {
        ...state,
        gettingMatomoEmail: true
      };
    case GET_MATOMO_EMAIL_SUCCESS:
      return {
        ...state,
        gettingMatomoEmail: false,
        matomoEmailDetail: action.payload
      };
    case GET_MATOMO_EMAIL_FAIL:
      return {
        ...state,
        gettingMatomoEmail: false,
        error: action.error
      };

    case UPDATE_MATOMO_EMAIL:
      return {
        ...state,
        updatingMatomoEmail: true
      };
    case UPDATE_MATOMO_EMAIL_SUCCESS:
      return {
        ...state,
        updatingMatomoEmail: false,
        matomoRespond: action.payload
      };
    case UPDATE_MATOMO_EMAIL_FAIL:
      return {
        ...state,
        gettingMatomoEmail: false,
        error: action.error
      };
    default:
      return state;
  }
};
