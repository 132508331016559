import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchCategoriesSuccess } from "../actions/Category";
import { GET_CATEGORIES } from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import api from "../drivers";

const getCategories = async () =>
  await api
    .get(apisDetail.apisCategories)
    .then(resData => resData.data)
    .catch(error => error);

// -------------------------
// Define Saga actions
// -------------------------
function* fetchCategoriesRequest({ payload }) {
  try {
    const fetchedCategories = yield call(getCategories, payload);
    yield put(fetchCategoriesSuccess(fetchedCategories));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchCategories() {
  yield takeEvery(GET_CATEGORIES, fetchCategoriesRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchCategories)
  ]);
}
