import {
  GET_TAG,
  FETCH_ALL_TAG_SUCCESS,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  UPDATE_TAG,
  UPDATE_TAG_FAILED,
  FETCH_UPDATE_TAG_SUCCESS,
  CREATE_TAG,
  CREATE_TAG_FAILED,
  FETCH_CREATE_TAG_SUCCESS,
  GET_DETAIL_TAG,
  GET_DETAIL_TAG_SUCCESS
} from "../../constants/ActionTypes";
import cloneDeep from "lodash/cloneDeep";
const INIT_STATE = {
  tagsList: [],
  idTag: "",
  data: {}
};

function deleteTag(state, payload) {
  const idTag = payload;
  const tagsList = cloneDeep(state.tagsList);
  const index = tagsList.data.findIndex(item => item.id === idTag);
  index !== -1 && tagsList.data.splice(index, 1);
  return {
    ...state,
    tagsList,
    loading: false
  };
}

export default (state = INIT_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case GET_DETAIL_TAG: {
      return {
        ...state,
        data: {},
        idTag: action.idTag,
        loading: true
      };
    }
    case GET_DETAIL_TAG_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    }
    case GET_TAG: {
      return {
        ...state,
        loading: true,
        actions: { searchText: action.payload.name }
      };
    }
    case FETCH_ALL_TAG_SUCCESS: {
      return {
        ...state,
        tagsList: action.payload,
        loading: false
      };
    }
    case DELETE_TAG: {
      return {
        ...state,
        idTag: action.idTag,
        loading: true
      };
    }
    case DELETE_TAG_SUCCESS: {
      return deleteTag(state, payload);
    }
    case UPDATE_TAG: {
      return {
        ...state,
        updating: true
      };
    }
    case FETCH_UPDATE_TAG_SUCCESS: {
      return {
        ...state,
        updating: false
      };
    }
    case UPDATE_TAG_FAILED: {
      return {
        ...state,
        updating: false
      };
    }
    case CREATE_TAG: {
      return {
        ...state,
        data: action.data,
        creating: true
      };
    }
    case FETCH_CREATE_TAG_SUCCESS: {
      return {
        ...state,
        tagsList: action.payload,
        creating: false
      };
    }
    case CREATE_TAG_FAILED: {
      return {
        ...state,
        creating: false
      };
    }
    default:
      return state;
  }
};
