import {
  FETCH_ALL_MOVIE_SUCCESS,
  DELETE_MOVIE_SUCCESS,
  UPDATE_MOVIE,
  FETCH_UPDATE_MOVIE_SUCCESS,
  UPDATE_MOVIE_FAILED,
  CREATE_MOVIE,
  CREATE_MOVIE_FAILED,
  FETCH_CREATE_MOVIE_SUCCESS,
  GET_DETAIL_MOVIE_SUCCESS,
  GET_DETAIL_MOVIE,
  DELETE_MOVIE,
  GET_MOVIE,
  GET_EPISODE,
  GET_EPISODE_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  moviesList: [],
  idMovie: "",
  dataResponse: {},
  movieDetail: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DETAIL_MOVIE: {
      return {
        ...state,
        movieDetail: {},
        idMovie: action.idMovie,
        loadingDetail: true
      };
    }
    case GET_DETAIL_MOVIE_SUCCESS: {
      return {
        ...state,
        movieDetail: action.payload,
        loadingDetail: false
      };
    }
    case FETCH_ALL_MOVIE_SUCCESS: {
      return {
        ...state,
        moviesList: action.payload,
        loadingList: false
      };
    }
    case DELETE_MOVIE: {
      return {
        ...state,
        idMovie: action.idMovie,
        loadingList: true
      };
    }
    case DELETE_MOVIE_SUCCESS: {
      return {
        ...state,
        loadingList: false
      };
    }
    case UPDATE_MOVIE: {
      return {
        ...state,
        updating: true
      };
    }
    case FETCH_UPDATE_MOVIE_SUCCESS: {
      return {
        ...state,
        dataResponse: action.payload,
        updating: false
      };
    }
    case UPDATE_MOVIE_FAILED: {
      return {
        ...state,
        updating: false
      };
    }
    case GET_MOVIE: {
      return {
        ...state,
        loadingList: true,
        actions: {
          searchText: action.payload.name,
          selectedTab: action.payload.movie_type,
          limitItem: action.payload.limit,
          filteredInfo: action.payload.channelIdsProps,
          sortedInfo: {
            field: action.payload.sort_by,
            columnKey: action.payload.sort_by,
            order: action.payload.order_by
          }
        }
      };
    }
    case CREATE_MOVIE: {
      return {
        ...state,
        creating: true
      };
    }
    case FETCH_CREATE_MOVIE_SUCCESS: {
      return {
        ...state,
        dataResponse: action.payload,
        creating: false
      };
    }
    case CREATE_MOVIE_FAILED: {
      return {
        ...state,
        creating: false
      };
    }
    case GET_EPISODE: {
      return {
        ...state,
        loadingEpisode: true,
      };
    }
    case GET_EPISODE_SUCCESS: {
      return {
        ...state,
        loadingEpisode: false,
        episode: action.payload
      }
    }
    default:
      return state;
  }
};
