import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  onGetShortLinkSuccess,
  onGetShortLinkFailed
} from "../actions/ShortLink";
import {
  GET_SHORT_LINK
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import api from "../drivers";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";

const feedShortLink = async params => {
  const url = `${apisDetail.apisFireBaseDynamicLink}`;
  const dataSend = {
    dynamicLinkInfo: {
      domainUriPrefix: "https://app.weallnet.com",
      link: params.url,
      androidInfo: {
        androidPackageName: "com.weallnet.android"
      },
      iosInfo: {
        iosBundleId: "com.weallnet.ios"
      },
      socialMetaTagInfo: {
        socialTitle: params.title,
        socialDescription:
          "Weallnet là siêu ứng dụng tổng hợp, nhắn tin, giải trí , đọc báo và mua sắm độc đáo cho toàn cầu",
        socialImageLink: params.image
      }
    }
  };

  return await api
    .post(url, dataSend)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.feedShortLinkSuccess" />
      );
      return resData.data;
    })
    .catch(error => error);
};

// -------------------------
// Define Saga actions
// -------------------------
function* fetchShortLinkRequest({ payload, callback }) {
  try {
    const fetchFeedShortLink = yield call(feedShortLink, payload);
    yield put(onGetShortLinkSuccess(fetchFeedShortLink));
    callback && callback();
  } catch (error) {
    yield put(onGetShortLinkFailed(error));
  }
}

/**
 * Receive redux action
 */
function* fetchShortLink() {
  yield takeEvery(GET_SHORT_LINK, fetchShortLinkRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchShortLink)
  ]);
}
