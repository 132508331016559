// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const GET_MY_PROFILE = "GET_MY_PROFILE";
export const GET_MY_PROFILE_SUCCESS = "GET_MY_PROFILE_SUCCESS";
export const RANDOM_PROFILE = "RANDOM_PROFILE";
export const RANDOM_PROFILE_SUCCESS = "RANDOM_PROFILE_SUCCESS";

//Sticky
export const GET_STICKY = "GET_STICKY";
export const NOTES_UPDATE = "NOTES_UPDATE";
export const FETCH_ALL_NOTES_SUCCESS = "FETCH_ALL_NOTES_SUCCESS";
export const UPDATE_ALL_NOTES_SUCCESS = "UPDATE_ALL_NOTES_SUCCESS";

//Contact
export const GET_All_CONTACT_SUCCESS = "GET_All_CONTACT_SUCCESS";
export const ON_ADD_CONTACT_SUCCESS = "ON_ADD_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";

//Movie
export const GET_MOVIE = "GET_MOVIE";
export const FETCH_ALL_MOVIE_SUCCESS = "FETCH_ALL_MOVIE_SUCCESS";
export const DELETE_MOVIE = "DELETE_MOVIE";
export const DELETE_MOVIE_SUCCESS = "DELETE_MOVIE_SUCCESS";
export const UPDATE_MOVIE = "UPDATE_MOVIE";
export const FETCH_UPDATE_MOVIE_SUCCESS = "FETCH_UPDATE_MOVIE_SUCCESS";
export const UPDATE_MOVIE_FAILED = "UPDATE_MOVIE_FAILED";
export const CREATE_MOVIE = "CREATE_MOVIE";
export const CREATE_MOVIE_FAILED = "CREATE_MOVIE_FAILED";
export const FETCH_CREATE_MOVIE_SUCCESS = "FETCH_CREATE_MOVIE_SUCCESS";
export const GET_DETAIL_MOVIE = "GET_DETAIL_MOVIE";
export const GET_DETAIL_MOVIE_SUCCESS = "GET_DETAIL_MOVIE_SUCCESS";
export const GET_EPISODE = "GET_EPISODE";
export const GET_EPISODE_SUCCESS = "GET_EPISODE_SUCCESS";

// Interactive Movie
export const GET_INTERACTIVE_MOVIE = "GET_INTERACTIVE_MOVIE";
export const GET_INTERACTIVE_MOVIE_SUCCESS = "GET_INTERACTIVE_MOVIE_SUCCESS";
export const CREATE_INTERACTIVE_PART_LIST = "CREATE_INTERACTIVE_PART_LIST";
export const CREATE_INTERACTIVE_PART_LIST_SUCCESS =
  "CREATE_INTERACTIVE_PART_LIST_SUCCESS";
export const CREATE_INTERACTIVE_PART_LIST_FAILED =
  "CREATE_INTERACTIVE_PART_LIST_FAILED";
export const CREATE_INTERACTIVE_MOVIE = "CREATE_INTERACTIVE_MOVIE";
export const CREATE_INTERACTIVE_MOVIE_SUCCESS =
  "CREATE_INTERACTIVE_MOVIE_SUCCESS";
export const CREATE_INTERACTIVE_MOVIE_FAILED =
  "CREATE_INTERACTIVE_MOVIE_FAILED";
export const GET_DETAIL_INTERACTIVE_MOVIE = "GET_DETAIL_INTERACTIVE_MOVIE";
export const GET_DETAIL_INTERACTIVE_MOVIE_SUCCESS =
  "GET_DETAIL_INTERACTIVE_MOVIE_SUCCESS";
export const UPDATE_INTERACTIVE_MOVIE = "UPDATE_INTERACTIVE_MOVIE";
export const UPDATE_INTERACTIVE_MOVIE_SUCCESS =
  "UPDATE_INTERACTIVE_MOVIE_SUCCESS";
export const UPDATE_INTERACTIVE_MOVIE_FAILED =
  "UPDATE_INTERACTIVE_MOVIE_FAILED";
export const DELETE_INTERACTIVE_MOVIE = "DELETE_INTERACTIVE_MOVIE";
export const DELETE_INTERACTIVE_MOVIE_SUCCESS =
  "DELETE_INTERACTIVE_MOVIE_SUCCESS";
export const GET_DETAIL_INTERACTIVE_PART_MOVIE =
  "GET_DETAIL_INTERACTIVE_PART_MOVIE";
export const GET_DETAIL_INTERACTIVE_PART_MOVIE_SUCCESS =
  "GET_DETAIL_INTERACTIVE_PART_MOVIE_SUCCESS";

//Page
export const GET_PAGE = "GET_PAGE";
export const FETCH_ALL_PAGE_SUCCESS = "FETCH_ALL_PAGE_SUCCESS";
export const DELETE_PAGE = "DELETE_PAGE";
export const DELETE_PAGE_SUCCESS = "DELETE_PAGE_SUCCESS";
export const UPDATE_PAGE = "UPDATE_PAGE";
export const UPDATE_PAGE_FAILED = "UPDATE_PAGE_FAILED";
export const FETCH_UPDATE_PAGE_SUCCESS = "FETCH_UPDATE_PAGE_SUCCESS";
export const CREATE_PAGE = "CREATE_PAGE";
export const FETCH_CREATE_PAGE_SUCCESS = "FETCH_CREATE_PAGE_SUCCESS";
export const GET_DETAIL_PAGE = "GET_DETAIL_PAGE";
export const GET_DETAIL_PAGE_SUCCESS = "GET_DETAIL_PAGE_SUCCESS";

//Tag
export const GET_TAG = "GET_TAG";
export const FETCH_ALL_TAG_SUCCESS = "FETCH_ALL_TAG_SUCCESS";
export const DELETE_TAG = "DELETE_TAG";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const UPDATE_TAG = "UPDATE_TAG";
export const UPDATE_TAG_FAILED = "UPDATE_TAG_FAILED";
export const FETCH_UPDATE_TAG_SUCCESS = "FETCH_UPDATE_TAG_SUCCESS";
export const CREATE_TAG = "CREATE_TAG";
export const CREATE_TAG_FAILED = "CREATE_TAG_FAILED";
export const FETCH_CREATE_TAG_SUCCESS = "FETCH_CREATE_TAG_SUCCESS";
export const GET_DETAIL_TAG = "GET_DETAIL_TAG";
export const GET_DETAIL_TAG_SUCCESS = "GET_DETAIL_TAG_SUCCESS";

//Video
export const GET_VIDEO = "GET_VIDEO";
export const FETCH_ALL_VIDEO_SUCCESS = "FETCH_ALL_VIDEO_SUCCESS";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS";
export const UPDATE_VIDEO = "UPDATE_VIDEO";
export const UPDATE_VIDEO_FAILED = "UPDATE_VIDEO_FAILED";
export const FETCH_UPDATE_VIDEO_SUCCESS = "FETCH_UPDATE_VIDEO_SUCCESS";
export const CREATE_VIDEO = "CREATE_VIDEO";
export const CREATE_VIDEO_FAILED = "CREATE_VIDEO_FAILED";
export const FETCH_CREATE_VIDEO_SUCCESS = "FETCH_CREATE_VIDEO_SUCCESS";
export const GET_DETAIL_VIDEO = "GET_DETAIL_VIDEO";
export const GET_DETAIL_VIDEO_SUCCESS = "GET_DETAIL_VIDEO_SUCCESS";
export const VIDEO_REQUEST_FAILED = "VIDEO_REQUEST_FAILED";

//Music
export const GET_MUSIC = "GET_MUSIC";
export const FETCH_ALL_MUSIC_SUCCESS = "FETCH_ALL_MUSIC_SUCCESS";
export const DELETE_MUSIC = "DELETE_MUSIC";
export const DELETE_MUSIC_SUCCESS = "DELETE_MUSIC_SUCCESS";
export const UPDATE_MUSIC = "UPDATE_MUSIC";
export const FETCH_UPDATE_MUSIC_SUCCESS = "FETCH_UPDATE_MUSIC_SUCCESS";
export const UPDATE_MUSIC_FAILED = "UPDATE_MUSIC_FAILED";
export const CREATE_MUSIC = "CREATE_MUSIC";
export const CREATE_MUSIC_FAILED = "CREATE_MUSIC_FAILED";
export const CREATE_LIST_SONG = "CREATE_LIST_SONG";
export const FETCH_CREATE_LIST_SONG_SUCCESS = "FETCH_CREATE_LIST_SONG_SUCCESS";
export const FETCH_CREATE_MUSIC_SUCCESS = "FETCH_CREATE_MUSIC_SUCCESS";
export const GET_DETAIL_MUSIC = "GET_DETAIL_MUSIC";
export const GET_DETAIL_MUSIC_SUCCESS = "GET_DETAIL_MUSIC_SUCCESS";

//Television
export const GET_TELEVISION = "GET_TELEVISION";
export const FETCH_ALL_TELEVISION_SUCCESS = "FETCH_ALL_TELEVISION_SUCCESS";
export const DELETE_TELEVISION = "DELETE_TELEVISION";
export const DELETE_TELEVISION_SUCCESS = "DELETE_TELEVISION_SUCCESS";
export const UPDATE_TELEVISION = "UPDATE_TELEVISION";
export const UPDATE_TELEVISION_FAILED = "UPDATE_TELEVISION_FAILED";
export const FETCH_UPDATE_TELEVISION_SUCCESS =
  "FETCH_UPDATE_TELEVISION_SUCCESS";
export const CREATE_TELEVISION = "CREATE_TELEVISION";
export const CREATE_TELEVISION_FAILED = "CREATE_TELEVISION_FAILED";
export const FETCH_CREATE_TELEVISION_SUCCESS =
  "FETCH_CREATE_TELEVISION_SUCCESS";
export const GET_DETAIL_TELEVISION = "GET_DETAIL_TELEVISION";
export const GET_DETAIL_TELEVISION_SUCCESS = "GET_DETAIL_TELEVISION_SUCCESS";

//Collection
export const GET_COLLECTION = "GET_COLLECTION";
export const FETCH_ALL_COLLECTION_SUCCESS = "FETCH_ALL_COLLECTION_SUCCESS";
export const DELETE_COLLECTION = "DELETE_COLLECTION";
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS";
export const UPDATE_COLLECTION = "UPDATE_COLLECTION";
export const UPDATE_COLLECTION_FAILED = "UPDATE_COLLECTION_FAILED";
export const FETCH_UPDATE_COLLECTION_SUCCESS =
  "FETCH_UPDATE_COLLECTION_SUCCESS";
export const CREATE_COLLECTION = "CREATE_COLLECTION";
export const CREATE_COLLECTION_FAILED = "CREATE_COLLECTION_FAILED";
export const FETCH_CREATE_COLLECTION_SUCCESS =
  "FETCH_CREATE_COLLECTION_SUCCESS";
export const GET_DETAIL_COLLECTION = "GET_DETAIL_COLLECTION";
export const GET_DETAIL_COLLECTION_SUCCESS = "GET_DETAIL_COLLECTION_SUCCESS";

//People
export const GET_PEOPLE = "GET_PEOPLE";
export const FETCH_ALL_PEOPLE_SUCCESS = "FETCH_ALL_PEOPLE_SUCCESS";
export const DELETE_PEOPLE = "DELETE_PEOPLE";
export const DELETE_PEOPLE_SUCCESS = "DELETE_PEOPLE_SUCCESS";
export const UPDATE_PEOPLE = "UPDATE_PEOPLE";
export const UPDATE_PEOPLE_FAILED = "UPDATE_PEOPLE_FAILED";
export const FETCH_UPDATE_PEOPLE_SUCCESS = "FETCH_UPDATE_PEOPLE_SUCCESS";
export const CREATE_PEOPLE = "CREATE_PEOPLE";
export const CREATE_PEOPLE_FAILED = "CREATE_PEOPLE_FAILED";
export const FETCH_CREATE_PEOPLE_SUCCESS = "FETCH_CREATE_PEOPLE_SUCCESS";
export const GET_DETAIL_PEOPLE = "GET_DETAIL_PEOPLE";
export const GET_DETAIL_PEOPLE_SUCCESS = "GET_DETAIL_PEOPLE_SUCCESS";

// Users
export const GET_USERS = "GET_USERS";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const GET_LIST_PERMISSIONS = "GET_LIST_PERMISSIONS";
export const GET_LIST_PERMISSIONS_SUCCESS = "GET_LIST_PERMISSIONS_SUCCESS";
export const BAN_USER = "BAN_USER";
export const BAN_USER_SUCCESS = "BAN_USER_SUCCESS";
export const BAN_USER_FAILED = "BAN_USER_FAILED";

//Base returned actions
export const baseReturnedAction = {
  isAuthRequestApi: false
};

// Feeds
export const GET_FEEDS = "GET_FEEDS";
export const FETCH_ALL_FEEDS_SUCCESS = "FETCH_ALL_FEEDS_SUCCESS";
export const DELETE_FEED = "DELETE_FEED";
export const DELETE_FEED_SUCCESS = "DELETE_FEED_SUCCESS";
export const UPDATE_FEED = "UPDATE_FEED";
export const FETCH_UPDATE_FEED_SUCCESS = "FETCH_UPDATE_FEED_SUCCESS";
export const CREATE_FEED = "CREATE_FEED";
export const FETCH_CREATE_FEED_SUCCESS = "FETCH_CREATE_FEED_SUCCESS";
export const GET_DETAIL_FEED = "GET_DETAIL_FEED";
export const GET_DETAIL_FEED_SUCCESS = "GET_DETAIL_FEED_SUCCESS";
export const SEND_FEED = "SEND_FEED";
export const SEND_FEED_SUCCESS = "SEND_FEED_SUCCESS";
export const SEND_FEED_FAILED = "SEND_FEED_FAILED";
export const GET_FEEDS_COLLECTION = "GET_FEEDS_COLLECTION";
export const GET_FEEDS_COLLECTION_SUCCESS = "GET_FEEDS_COLLECTION_SUCCESS";
export const GET_FEEDS_COLLECTION_FAILED = "GET_FEEDS_COLLECTION_FAILED";
export const UPDATE_FEEDS_COLLECTION = "UPDATE_FEEDS_COLLECTION";
export const UPDATE_FEEDS_COLLECTION_SUCCESS =
  "UPDATE_FEEDS_COLLECTION_SUCCESS";
export const UPDATE_FEEDS_COLLECTION_FAILED = "UPDATE_FEEDS_COLLECTION_FAILED";
export const GET_FEEDS_SHORT_LINK = "GET_FEEDS_SHORT_LINK";
export const GET_FEEDS_SHORT_LINK_SUCCESS = "GET_FEEDS_SHORT_LINK_SUCCESS";
export const GET_FEEDS_SHORT_LINK_FAILED = "GET_FEEDS_SHORT_LINK_FAILED";

// Categories
export const GET_CATEGORIES = "GET_CATEGORIES";
export const FETCH_ALL_CATEGORIES_SUCCESS = "FETCH_ALL_CATEGORIES_SUCCESS";

// Feedback Platform
export const GET_FEEDBACKS = "GET_FEEDBACKS";
export const FETCH_ALL_FEEDBACKS_SUCCESS = "FETCH_ALL_FEEDBACKS_SUCCESS";
export const DELETE_FEEDBACK = "DELETE_FEEDBACK";
export const DELETE_FEEDBACK_SUCCESS = "DELETE_FEEDBACK_SUCCESS";
export const UPDATE_FEEDBACK = "UPDATE_FEEDBACK";
export const FETCH_UPDATE_FEEDBACK_SUCCESS = "FETCH_UPDATE_FEEDBACK_SUCCESS";
export const GET_DETAIL_FEEDBACK = "GET_DETAIL_FEEDBACK";
export const GET_DETAIL_FEEDBACK_SUCCESS = "GET_DETAIL_FEEDBACK_SUCCESS";

// Comment
export const GET_COMMENTS = "GET_COMMENTS";
export const FETCH_ALL_COMMENTS_SUCCESS = "FETCH_ALL_COMMENTS_SUCCESS";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const FETCH_UPDATE_COMMENT_SUCCESS = "FETCH_UPDATE_COMMENT_SUCCESS";
export const GET_DETAIL_COMMENT = "GET_DETAIL_COMMENT";
export const GET_DETAIL_COMMENT_SUCCESS = "GET_DETAIL_COMMENT_SUCCESS";
export const APPROVE_COMMENT = "APPROVE_COMMENT";
export const APPROVE_COMMENT_SUCCESS = "APPROVE_COMMENT_SUCCESS";

// Review
export const GET_REVIEWS = "GET_REVIEWS";
export const FETCH_ALL_REVIEWS_SUCCESS = "FETCH_ALL_REVIEWS_SUCCESS";
export const DELETE_REVIEW = "DELETE_REVIEW";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const UPDATE_REVIEW = "UPDATE_REVIEW";
export const FETCH_UPDATE_REVIEW_SUCCESS = "FETCH_UPDATE_REVIEW_SUCCESS";
export const GET_DETAIL_REVIEW = "GET_DETAIL_REVIEW";
export const GET_DETAIL_REVIEW_SUCCESS = "GET_DETAIL_REVIEW_SUCCESS";
export const APPROVE_REVIEW = "APPROVE_REVIEW";
export const APPROVE_REVIEW_SUCCESS = "APPROVE_REVIEW_SUCCESS";

// Blacklist
export const GET_BLACKLIST = "GET_BLACKLIST";
export const GET_BLACKLIST_SUCCESS = "GET_BLACKLIST_SUCCESS";
export const CREATE_BLACKLIST = "CREATE_BLACKLIST";
export const CREATE_BLACKLIST_SUCCESS = "CREATE_BLACKLIST_SUCCESS";
export const DELETE_BLACKLIST = "DELETE_BLACKLIST";
export const DELETE_BLACKLIST_SUCCESS = "DELETE_BLACKLIST_SUCCESS";

// Advertises
export const GET_ADVERTISES = "GET_ADVERTISES";
export const FETCH_ALL_ADVERTISES_SUCCESS = "FETCH_ALL_ADVERTISES_SUCCESS";
export const GET_ADVERTISERS = "GET_ADVERTISERS";
export const GET_ADVERTISERS_SUCCESS = "GET_ADVERTISERS_SUCCESS";
export const GET_USER_LIST_CAMPAIGNS = "GET_USER_LIST_CAMPAIGNS";
export const GET_USER_LIST_CAMPAIGNS_SUCCESS =
  "GET_USER_LIST_CAMPAIGNS_SUCCESS";
export const GET_DETAIL_CAMPAIGN = "GET_DETAIL_CAMPAIGN";
export const GET_DETAIL_CAMPAIGN_SUCCESS = "GET_DETAIL_CAMPAIGN_SUCCESS";
export const GET_LIST_CAMPAIGN_ADS = "GET_LIST_CAMPAIGN_ADS";
export const GET_LIST_CAMPAIGN_ADS_SUCCESS = "GET_LIST_CAMPAIGN_ADS_SUCCESS";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const GET_CAMPAIGN_CHART = "GET_CAMPAIGN_CHART";
export const GET_CAMPAIGN_CHART_SUCCESS = "GET_CAMPAIGN_CHART_SUCCESS";

// Zones
export const GET_ZONES = "GET_ZONES";
export const FETCH_ALL_ZONES_SUCCESS = "FETCH_ALL_ZONES_SUCCESS";
export const DELETE_ZONE = "DELETE_ZONE";
export const DELETE_ZONE_SUCCESS = "DELETE_ZONE_SUCCESS";
export const UPDATE_ZONE = "UPDATE_ZONE";
export const FETCH_UPDATE_ZONE_SUCCESS = "FETCH_UPDATE_ZONE_SUCCESS";
export const CREATE_ZONE = "CREATE_ZONE";
export const FETCH_CREATE_ZONE_SUCCESS = "FETCH_CREATE_ZONE_SUCCESS";
export const GET_DETAIL_ZONE = "GET_DETAIL_ZONE";
export const GET_DETAIL_ZONE_SUCCESS = "GET_DETAIL_ZONE_SUCCESS";

// Matomo analytics
export const GET_TOTAL_USER = "GET_TOTAL_USER";
export const GET_TOTAL_USER_SUCCESS = "GET_TOTAL_USER_SUCCESS";
export const GET_TOTAL_USER_FAILED = "GET_TOTAL_USER_FAILED";

// Send notification
export const SEND_MEDIA_NOTIFICATION = "SEND_MEDIA_NOTIFICATION";
export const SEND_MEDIA_NOTIFICATION_SUCCESS =
  "SEND_MEDIA_NOTIFICATION_SUCCESS";

// Update status of media item
export const UPDATE_STATUS = "UPDATE_STATUS";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const UPDATE_STATUS_FAILED = "UPDATE_STATUS_FAILED";

// Get media information
export const GET_MEDIA_INFORMATION = "GET_MEDIA_INFORMATION";
export const GET_MEDIA_INFORMATION_SUCCESS = "GET_MEDIA_INFORMATION_SUCCESS";
export const GET_MEDIA_INFORMATION_ERROR = "GET_MEDIA_INFORMATION_ERROR";

// Channel list
export const GET_CHANNEL_LIST = "GET_CHANNEL_LIST";
export const GET_CHANNEL_LIST_SUCCESS = "GET_CHANNEL_LIST_SUCCESS";
export const GET_CHANNEL_LIST_FAILED = "GET_CHANNEL_LIST_FAILED";

// Media Blacklist
export const GET_MOVIE_BLACKLIST = "GET_MOVIE_BLACKLIST";
export const GET_MOVIE_BLACKLIST_SUCCESS = "GET_MOVIE_BLACKLIST_SUCCESS";
export const GET_MUSIC_BLACKLIST = "GET_MUSIC_BLACKLIST";
export const GET_MUSIC_BLACKLIST_SUCCESS = "GET_MUSIC_BLACKLIST_SUCCESS";
export const GET_VIDEO_BLACKLIST = "GET_VIDEO_BLACKLIST";
export const GET_VIDEO_BLACKLIST_SUCCESS = "GET_VIDEO_BLACKLIST_SUCCESS";
export const APPROVE_MEDIA_BLACKLIST = "APPROVE_MEDIA_BLACKLIST";
export const APPROVE_MEDIA_BLACKLIST_SUCCESS ="APPROVE_MEDIA_BLACKLIST_SUCCESS";
export const GET_REPORT_CONTENT = "GET_REPORT_CONTENT";
export const GET_REPORT_CONTENT_SUCCESS = "GET_REPORT_CONTENT_SUCCESS";

// Lastest active media page
export const ACTIVE_MEDIA_PAGE = "ACTIVE_MEDIA_PAGE";

// Change media type
export const CHANGE_MEDIA_TYPE = "CHANGE_MEDIA_TYPE";
export const CHANGE_MEDIA_TYPE_SUCCESS = "CHANGE_MEDIA_TYPE_SUCCESS";
export const CHANGE_MEDIA_TYPE_FAILED = "CHANGE_MEDIA_TYPE_FAILED";

// Short link
export const GET_SHORT_LINK = "GET_SHORT_LINK";
export const GET_SHORT_LINK_SUCCESS = "GET_SHORT_LINK_SUCCESS";
export const GET_SHORT_LINK_FAILED = "GET_SHORT_LINK_FAILED";


// Config
export const GET_CONFIG = "GET_CONFIG";
export const FETCH_ALL_CONFIG_SUCCESS = "FETCH_ALL_CONFIG_SUCCESS";

export const GET_EVENT_RESULT = "GET_EVENT_RESULT";
export const GET_EVENT_RESULT_SUCCESS = "GET_EVENT_RESULT_SUCCESS";

export const UPDATE_EVENT_RESULT_HISTORY = "UPDATE_EVENT_RESULT_HISTORY";
export const UPDATE_EVENT_RESULT_HISTORY_SUCCESS = "UPDATE_EVENT_RESULT_HISTORY_SUCCESS";
export const UPDATE_EVENT_RESULT_HISTORY_FAILED = "UPDATE_EVENT_RESULT_HISTORY_FAILED";


export const UPDATE_EVENT_KEY = "UPDATE_EVENT_KEY";
export const UPDATE_EVENT_KEY_SUCCESS = "UPDATE_EVENT_KEY_SUCCESS";
export const UPDATE_EVENT_KEY_FAILED= "UPDATE_EVENT_KEY_FAILED";


export const UPDATE_EVENT_LINK = "UPDATE_EVENT_LINK";
export const UPDATE_EVENT_LINK_SUCCESS = "UPDATE_EVENT_LINK_SUCCESS";
export const UPDATE_EVENT_LINK_FAILED= "UPDATE_EVENT_LINK_FAILED";


export const UPDATE_EVENT_BACKGROUND = "UPDATE_EVENT_BACKGROUND";
export const UPDATE_EVENT_BACKGROUND_SUCCESS = "UPDATE_EVENT_BACKGROUND_SUCCESS";
export const UPDATE_EVENT_BACKGROUND_FAILED= "UPDATE_EVENT_BACKGROUND_FAILED";


// Greetings
export const GET_GREETINGS = "GET_GREETINGS";
export const FETCH_ALL_GREETINGS_SUCCESS = "FETCH_ALL_GREETINGS_SUCCESS";

export const CREATE_GREETINGS_= "CREATE_GREETINGS";
export const CREATE_GREETINGS_SUCCESS= "CREATE_GREETINGS_SUCCESS";
export const CREATE_GREETINGS_FAILED= "CREATE_GREETINGS_FAILED";

export const DELETE_GREETINGS = "DELETE_GREETINGS";
export const DELETE_GREETINGS_SUCCESS= "DELETE_GREETINGS_SUCCESS";
export const DELETE_GREETINGS_FAILED= "DELETE_GREETINGS_FAILED";

