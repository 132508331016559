import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import movieSagas from "./Movie";
import interactiveMovieSagas from "./InteractiveMovie";
import pageSagas from "./Page";
import collectionSagas from "./Collection";
import musicSagas from "./Music";
import peopleSagas from "./People";
import tagSagas from "./Tag";
import televisionSagas from "./Television";
import videoSagas from "./Video";
import transcodeSagas from "./Transcode";
import userSagas from "./User";
import feedSagas from "./Feed";
import categorySagas from "./Category";
import feedbackSagas from "./Feedback";
import commentSagas from "./Comment";
import reviewSagas from "./Review";
import blacklistSagas from "./Blacklist";
import advertiseSagas from "./Advertise";
import zoneSagas from "./adbutler/Zones";
import userAnalyticSagas from "./UserAnalytic";
import sendNotificationSagas from "./SendNotification";
import mediaInformationSagas from "./MediaInformation";
import channelSaga from "./Channel";
import channelListSaga from "./ChannelList";
import updateStatusSage from "./UpdateStatus";
import mediaBlacklist from "./MediaBlacklist";
import mediaType from "./MediaType";
import shortLink from "./ShortLink";
import config from "./Config";
import greetings from "./Greetings";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    movieSagas(),
    interactiveMovieSagas(),
    pageSagas(),
    collectionSagas(),
    musicSagas(),
    peopleSagas(),
    tagSagas(),
    televisionSagas(),
    videoSagas(),
    transcodeSagas(),
    userSagas(),
    feedSagas(),
    categorySagas(),
    feedbackSagas(),
    commentSagas(),
    reviewSagas(),
    blacklistSagas(),
    advertiseSagas(),
    zoneSagas(),
    userAnalyticSagas(),
    sendNotificationSagas(),
    mediaInformationSagas(),
    channelSaga(),
    channelListSaga(),
    updateStatusSage(),
    mediaBlacklist(),
    mediaType(),
    shortLink(),
    config(),
    greetings()
  ]);
}
