import {
  GET_VIDEO,
  FETCH_ALL_VIDEO_SUCCESS,
  DELETE_VIDEO,
  DELETE_VIDEO_SUCCESS,
  UPDATE_VIDEO,
  UPDATE_VIDEO_FAILED,
  FETCH_UPDATE_VIDEO_SUCCESS,
  CREATE_VIDEO,
  CREATE_VIDEO_FAILED,
  FETCH_CREATE_VIDEO_SUCCESS,
  GET_DETAIL_VIDEO,
  GET_DETAIL_VIDEO_SUCCESS
} from "../../constants/ActionTypes";

export const onGetDetailVideo = idVideo => {
  return {
    type: GET_DETAIL_VIDEO,
    idVideo
  };
};

export const getDetailVideoSuccess = videosList => {
  return {
    type: GET_DETAIL_VIDEO_SUCCESS,
    payload: videosList
  };
};

export const onGetVideo = queries => {
  return {
    isAuthRequestApi: true,
    type: GET_VIDEO,
    payload: queries
  };
};

export const fetchVideosSuccess = videosList => {
  return {
    type: FETCH_ALL_VIDEO_SUCCESS,
    payload: videosList
  };
};

export const onDeleteVideo = (idVideo, callback) => {
  return {
    type: DELETE_VIDEO,
    idVideo,
    callback
  };
};

export const deleteVideoSuccess = videosList => {
  return {
    type: DELETE_VIDEO_SUCCESS,
    payload: videosList
  };
};

export const onUpdateVideo = (idVideo, data, callback) => {
  return {
    type: UPDATE_VIDEO,
    idVideo,
    data,
    callback
  };
};

export const updateVideoSuccess = payload => {
  return {
    type: FETCH_UPDATE_VIDEO_SUCCESS,
    payload
  };
};

export const updateVideoFailed = error => {
  return {
    type: UPDATE_VIDEO_FAILED,
    error
  };
};

export const onCreateVideo = (data, callback) => {
  return {
    type: CREATE_VIDEO,
    data,
    callback
  };
};

export const createVideoSuccess = videosList => {
  return {
    type: FETCH_CREATE_VIDEO_SUCCESS,
    payload: videosList
  };
};

export const createVideoFailed = error => {
  return {
    type: CREATE_VIDEO_FAILED,
    error
  };
};
