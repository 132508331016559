import { FETCH_ALL_ZONES_SUCCESS } from "../../constants/ActionTypes";
const INIT_STATE = {
  zonesList: [],
  data: {}
}
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_ZONES_SUCCESS:
      return {
        ...state,
        zonesList: action.payload.data
      }
  
    default:
      return state;
  }
}