import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    fetchGreetingsSuccess,
    onDeleteGreetingsSuccess
} from "../actions/Greetings";
import {
    GET_GREETINGS,
    FETCH_ALL_GREETINGS_SUCCESS,
    DELETE_GREETINGS,
    DELETE_GREETINGS_SUCCESS,
    DELETE_GREETINGS_FAILED
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import api from "../drivers";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import get from "lodash/get";

const getGreetings = async params =>
  await api
    .get(apisDetail.apisGreetings, params)
    .then(resData => {
        return resData;
    })
    .catch(error => error);

const deleteGreetingsRequest = async greetings_id =>
    await api
      .delete(`${apisDetail.apisGreetings}/${greetings_id}`)
      .then(() => {
        NotificationManager.success(
          <FormattedMessage id="notification.deleteData" />,
          <FormattedMessage id="notification.success" />
        );
      })
      .catch(error => {
        NotificationManager.error(
          get(error, "response.data.error.message"),
          <FormattedMessage id="notification.deleteDataError" />
        );
        throw error;
      });


// -------------------------
// Define Saga actions
// -------------------------
function* fetchGreetingsRequest({ payload}) {
  try {
    const fetchedGreetings = yield call(getGreetings, payload);
    yield put(fetchGreetingsSuccess(fetchedGreetings));
  } catch (error) {
    yield put(fetchError(error));
  }
}
function* deleteGreetings({ greetings_id, callback }) {
    try {
      yield call(deleteGreetingsRequest, greetings_id);
      yield put(onDeleteGreetingsSuccess());
      callback && callback();
    } catch (error) {
      yield put(fetchError(error));
    }
  }
// receive action
function* fetchGreetings() {
  yield takeEvery(GET_GREETINGS, fetchGreetingsRequest);
}
export function* fetchDeleteGreetings() {
    yield takeEvery(DELETE_GREETINGS, deleteGreetings);
}


export default function* rootSaga() {
  yield all([
    fork(fetchGreetings),
    fork(fetchDeleteGreetings)
  ]);
}
