import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchMediaInformationSuccess,
  fetchMediaInformationError
} from "../actions/MediaInformation";
import { GET_MEDIA_INFORMATION } from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import api from "../drivers";
import get from "lodash/get";

const getMediaInformation = async playUrl =>
  await api
    .post(`${apisDetail.apisMediaInformation}`, playUrl)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.getInformation" />,
        <FormattedMessage id="notification.success" />
      );
      return resData.data;
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.getInformationError" />
      );
      throw error;
    });
/**
 * Define Saga action
 */
function* fetchMediaInformationRequest({ playUrl, callback }) {
  try {
    const fetched = yield call(getMediaInformation, playUrl);
    yield put(fetchMediaInformationSuccess(fetched));
    callback && callback();
  } catch (error) {
    yield put(fetchMediaInformationError(error));
  }
}
/**
 * Define Saga action
 */

/**
 * Receive Redux Action
 */

export function* fetchMediaInformation() {
  yield takeEvery(GET_MEDIA_INFORMATION, fetchMediaInformationRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchMediaInformation)]);
}
