import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchCommentsSuccess,
  getDetailCommentSuccess,
  deleteCommentSuccess,
  approveCommentSuccess
} from "../actions/Comment";
import {
  GET_COMMENTS,
  GET_DETAIL_COMMENT,
  DELETE_COMMENT,
  UPDATE_COMMENT,
  APPROVE_COMMENT
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import api from "../drivers";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import get from "lodash/get";

const getComments = async params => {
  const url = apisDetail.apisComments;
  return await api.get(url, params).then(resData => resData.data);
};

const getDetailCommentRequest = async idComment => {
  const url = `${apisDetail.apisComments}/${idComment}`;
  return await api.get(url).then(resData => resData.data);
};

const deleteComment = async action => {
  const { idComment } = action;
  const url = `${apisDetail.apisComments}/decline`;
  return await api
    .post(url, idComment)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.deleteData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message", "Error"),
        <FormattedMessage id="notification.deleteDataError" />
      );
      throw error;
    });
};

const updateComment = async action => {
  const { idComment, data } = action;
  const url = `${apisDetail.apisComments}/${idComment}`;
  return await api
    .put(url, data)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.successMessage" />,
        <FormattedMessage id="notification.updateData" />
      );
    })
    .catch(error => error);
};

const approveComment = async action => {
  const { idComment, approveType } = action;
  const url =
    approveType === "blacklist"
      ? `${apisDetail.apisComments}/approve`
      : `${apisDetail.apisComments}/approve_report`;
  return await api
    .post(url, idComment)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.approveData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message", "Error"),
        <FormattedMessage id="notification.approveDataError" />
      );
      throw error;
    });
};

// -------------------------
// Define Saga actions
// -------------------------
function* fetchCommentsRequest({ payload }) {
  try {
    const fetchedComments = yield call(getComments, payload);
    yield put(fetchCommentsSuccess(fetchedComments));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getDetailComment({ idComment }) {
  try {
    const fetchedComment = yield call(getDetailCommentRequest, idComment);
    yield put(getDetailCommentSuccess(fetchedComment));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* deleteCommentEffect(action) {
  try {
    yield call(deleteComment, action);
    yield put(deleteCommentSuccess());
    action.callback && action.callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* approveCommentRequest(action) {
  try {
    yield call(approveComment, action);
    yield put(approveCommentSuccess());
    action.callback && action.callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Receive redux action
 */

function* fetchComments() {
  yield takeEvery(GET_COMMENTS, fetchCommentsRequest);
}

function* fetchDetailComment() {
  yield takeEvery(GET_DETAIL_COMMENT, getDetailComment);
}

function* fetchApproveComment() {
  yield takeEvery(APPROVE_COMMENT, approveCommentRequest);
}

function* fetchDeleteComment() {
  yield takeEvery(DELETE_COMMENT, deleteCommentEffect);
}

function* fetchUpdateComment() {
  yield takeEvery(UPDATE_COMMENT, updateComment);
}

export default function* rootSaga() {
  yield all([
    fork(fetchComments),
    fork(fetchDetailComment),
    fork(fetchDeleteComment),
    fork(fetchUpdateComment),
    fork(fetchApproveComment)
  ]);
}
