import React from "react";
import ReactDOM from "react-dom";
import NextApp from "./NextApp";
import { unregister } from "./registerServiceWorker";
import "./styles/wieldy.less";
import "video-react/dist/video-react.css";
import "./wan-styles/styles.scss";

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <>
      <NextApp />
    </>,
    document.getElementById("root")
  );
};
// Do this once
// registerServiceWorker();
unregister();
// Render once
render(NextApp);
// Webpack Hot Module Replacement API
// if (module.hot) {
//   module.hot.accept("./NextApp", () => {
//     render(NextApp);
//   });
// }
