import {
  GET_ADVERTISES,
  FETCH_ALL_ADVERTISES_SUCCESS,
  DELETE_ADVERTISE_SUCCESS,
  FETCH_UPDATE_ADVERTISE_SUCCESS,
  FETCH_CREATE_ADVERTISE_SUCCESS,
  GET_DETAIL_ADVERTISE_SUCCESS,
  GET_DETAIL_ADVERTISE,
  GET_ADVERTISERS_SUCCESS,
  GET_ADVERTISERS,
  GET_USER_LIST_CAMPAIGNS,
  GET_USER_LIST_CAMPAIGNS_SUCCESS,
  GET_LIST_CAMPAIGN_ADS_SUCCESS,
  GET_DETAIL_CAMPAIGN_SUCCESS,
  GET_DETAIL_CAMPAIGN,
  GET_CAMPAIGN_CHART,
  GET_CAMPAIGN_CHART_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  advertisesList: [],
  data: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADVERTISES:
      return {
        ...state,
        advertisesList: action.advertisesList,
        gettingList: true
      };
    case FETCH_ALL_ADVERTISES_SUCCESS:
      return {
        ...state,
        advertisesList: action.payload,
        gettingList: false
      };
    case GET_ADVERTISERS:
      return {
        ...state,
        gettingList: true
      };
    case GET_ADVERTISERS_SUCCESS:
      return {
        ...state,
        gettingList: false,
        advertiseUsers: action.payload
      }
    case GET_USER_LIST_CAMPAIGNS:
      return {
        ...state,
        gettingList: true
      }
    case GET_USER_LIST_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        gettingList: false,
        listCampaigns: action.payload
      }
    case GET_DETAIL_CAMPAIGN: 
      return {
        ...state,
        gettingList: true,
        campaignDetail: null,
        listCampaignAds: []
      }
    case GET_DETAIL_CAMPAIGN_SUCCESS:
      return {
        ...state,
        gettingList: false,
        campaignDetail: action.payload
      }
    case GET_LIST_CAMPAIGN_ADS_SUCCESS:
      return {
        ...state,
        listCampaignAds: action.payload
      }
    case GET_CAMPAIGN_CHART:
      return {
        ...state,
        campaignChart: null
      }
    case GET_CAMPAIGN_CHART_SUCCESS:
      return {
        ...state,
        campaignChart: action.payload
      }
    default:
      return state;
  }
};
