import {
  GET_BLACKLIST,
  GET_BLACKLIST_SUCCESS,
  CREATE_BLACKLIST,
  CREATE_BLACKLIST_SUCCESS,
  DELETE_BLACKLIST,
  DELETE_BLACKLIST_SUCCESS,
} from "../../constants/ActionTypes";

export const onGetBlacklist = queries => {
  return {
    type: GET_BLACKLIST,
    payload: queries
  };
};

export const getBlacklistSuccess = blacklist => {
  return {
    type: GET_BLACKLIST_SUCCESS,
    payload: blacklist
  };
};

export const onCreateBlacklist = data => {
  return {
    type: CREATE_BLACKLIST,
    data
  };
};

export const createBlacklistSuccess = () => {
  return {
    type: CREATE_BLACKLIST_SUCCESS,
  };
};

export const onDeleteBlacklist = words => {
  return {
    type: DELETE_BLACKLIST,
    words
  }
}

export const deleteBlacklistSuccess = () => {
  return {
    type: DELETE_BLACKLIST_SUCCESS
  }
}
