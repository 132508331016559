import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { onGetChannelListSuccess, onGetChannelListFail } from "../actions/ChannelList";
import {
  GET_CHANNEL_LIST
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import api from "../drivers";


const getChannelList = async () => await api
    .get(`${apisDetail.apisChannel}/list`)
    .then(resData => resData.data);

/**
 * Define Saga action
 */
function* fetchChannelListRequest() {
  try {
    const fetched = yield call(getChannelList);
    yield put(onGetChannelListSuccess(fetched));
  } catch (error) {
    yield put(onGetChannelListFail(error));
  }
}
/**
 * Define Saga action
 */

/**
 * Receive Redux Action
 */
export function* fetchChannelList() {
  yield takeEvery(GET_CHANNEL_LIST, fetchChannelListRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchChannelList),
  ]);
}
