export const GET_CHANNEL_DETAIL = "GET_CHANNEL_DETAIL";
export const GET_CHANNEL_DETAIL_SUCCESS = "GET_CHANNEL_DETAIL_SUCCESS";
export const GET_CHANNEL_DETAIL_FAILED = "GET_CHANNEL_DETAIL_FAILED";
export const UPDATE_CHANNEL = "UPDATE_CHANNEL";
export const UPDATE_CHANNEL_SUCCESS = "UPDATE_CHANNEL_SUCCESS";
export const UPDATE_CHANNEL_FAILED = "UPDATE_CHANNEL_FAILED";
export const GET_VIEWER_REPORT = "GET_VIEWER_REPORT";
export const GET_VIEWER_REPORT_SUCCESS = "GET_VIEWER_REPORT_SUCCESS";
export const GET_VIEWER_REPORT_FAILED = "GET_VIEWER_REPORT_FAILED";
export const GET_MATOMO_EMAIL = "GET_MATOMO_EMAIL";
export const GET_MATOMO_EMAIL_SUCCESS = "GET_MATOMO_EMAIL_SUCCESS";
export const GET_MATOMO_EMAIL_FAIL = "GET_MATOMO_EMAIL_FAIL";
export const UPDATE_MATOMO_EMAIL = "UPDATE_MATOMO_EMAIL";
export const UPDATE_MATOMO_EMAIL_SUCCESS = "UPDATE_MATOMO_EMAIL_SUCCESS";
export const UPDATE_MATOMO_EMAIL_FAIL = "UPDATE_MATOMO_EMAIL_FAIL";

export const onGetChannelDetail = () => ({
  type: GET_CHANNEL_DETAIL
});

export const onGetChannelDetailSuccess = payload => ({
  type: GET_CHANNEL_DETAIL_SUCCESS,
  payload
});

export const onGetChannelDetailFailed = error => ({
  type: GET_CHANNEL_DETAIL_FAILED,
  error
});

export const onUpdateChannel = payload => ({
  type: UPDATE_CHANNEL,
  payload
});

export const onUpdateChannelSuccess = payload => ({
  type: UPDATE_CHANNEL_SUCCESS,
  payload
});

export const onUpdateChannelFailed = error => ({
  type: UPDATE_CHANNEL_FAILED,
  error
});

export const onGetViewerReport = payload => ({
  type: GET_VIEWER_REPORT,
  payload
});

export const onGetViewerReportSuccess = payload => ({
  type: GET_VIEWER_REPORT_SUCCESS,
  payload
});

export const onGetViewerReportFailed = error => ({
  type: GET_VIEWER_REPORT_FAILED,
  error
});


export const onGetMatomoEmail = payload => ({
  type: GET_MATOMO_EMAIL,
  payload
});

export const onGetMatomoEmailSuccess = payload => ({
  type: GET_MATOMO_EMAIL_SUCCESS,
  payload
});

export const onGetMatomoEmailFail = error => ({
  type: GET_MATOMO_EMAIL_FAIL,
  error
});

export const onUpdateMatomoEmail = payload => ({
  type: UPDATE_MATOMO_EMAIL,
  payload
});

export const onUpdateMatomoEmailSuccess = payload => ({
  type: UPDATE_MATOMO_EMAIL_SUCCESS,
  payload
});

export const onUpdateMatomoEmailFail = error => ({
  type: UPDATE_MATOMO_EMAIL_FAIL,
  error
});
