import {
  GET_COLLECTION,
  FETCH_ALL_COLLECTION_SUCCESS,
  DELETE_COLLECTION,
  DELETE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION,
  FETCH_UPDATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_FAILED,
  CREATE_COLLECTION,
  FETCH_CREATE_COLLECTION_SUCCESS,
  CREATE_COLLECTION_FAILED,
  GET_DETAIL_COLLECTION,
  GET_DETAIL_COLLECTION_SUCCESS
} from "../../constants/ActionTypes";

export const onGetDetailCollection = idCollection => {
  return {
    type: GET_DETAIL_COLLECTION,
    idCollection
  };
};

export const getDetailCollectionSuccess = collectionsList => {
  return {
    type: GET_DETAIL_COLLECTION_SUCCESS,
    payload: collectionsList
  };
};

export const onGetCollection = queries => {
  return {
    type: GET_COLLECTION,
    payload: queries
  };
};

export const fetchCollectionsSuccess = collectionsList => {
  return {
    type: FETCH_ALL_COLLECTION_SUCCESS,
    payload: collectionsList
  };
};

export const onDeleteCollection = (idCollection, callback) => {
  return {
    type: DELETE_COLLECTION,
    idCollection,
    callback
  };
};

export const deleteCollectionSuccess = collectionsList => {
  return {
    type: DELETE_COLLECTION_SUCCESS,
    payload: collectionsList
  };
};

export const onUpdateCollection = (idCollection, data, callback) => {
  return {
    type: UPDATE_COLLECTION,
    idCollection,
    data,
    callback
  };
};

export const updateCollectionSuccess = (collectionsList, callback) => {
  return {
    type: FETCH_UPDATE_COLLECTION_SUCCESS,
    payload: collectionsList,
    callback
  };
};

export const updateCollectionFailed = error => {
  return {
    type: UPDATE_COLLECTION_FAILED,
    error
  };
};

export const onCreateCollection = (data, callback) => {
  return {
    type: CREATE_COLLECTION,
    data,
    callback
  };
};

export const createCollectionSuccess = collectionsList => {
  return {
    type: FETCH_CREATE_COLLECTION_SUCCESS,
    payload: collectionsList
  };
};

export const createCollectionFailed = error => {
  return {
    type: CREATE_COLLECTION_FAILED,
    error
  };
};
