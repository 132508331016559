import {
  GET_TOTAL_USER,
  GET_TOTAL_USER_SUCCESS,
  GET_TOTAL_USER_FAILED
} from "../../constants/ActionTypes";

export const getTotalUser = () => ({
  type: GET_TOTAL_USER,
});

export const getTotalUserSuccess = payload => ({
  type: GET_TOTAL_USER_SUCCESS,
  payload
});

export const getTotalUserFailed = (error) => ({
  type: GET_TOTAL_USER_FAILED,
  error
});
