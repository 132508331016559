import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Contact from "./Contact";
import Common from "./Common";
import Movie from "./Movie";
import interactiveMovie from "./InteractiveMovie";
import Page from "./Page";
import Tag from "./Tag";
import Video from "./Video";
import Collection from "./Collection";
import Music from "./Music";
import People from "./People";
import Television from "./Television";
import Transcode from "./Transcode";
import User from "./User";
import Feed from "./Feed";
import Category from "./Category";
import Feedback from "./Feedback";
import Comment from "./Comment";
import Review from "./Review";
import Blacklist from "./Blacklist";
import Advertise from "./Advertise";
import Zone from "./Zone";
import UserAnalytic from "./UserAnalytic";
import SendNotification from "./SendNotification";
import MediaInformation from "./MediaInformation";
import channel from "./Channel";
import channelList from "./ChannelList";
import language from "./Language";
import updateStatus from "./UpdateStatus";
import mediaBlacklist from "./MediaBlacklist";
import activeMediaPage from "./ActiveMediaPage";
import mediaType from "./MediaType";
import shortLink from "./ShortLink";
import config from "./Config";
import greetings from "./Greetings";

const reducers = history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    contact: Contact,
    common: Common,
    movie: Movie,
    interactiveMovie,
    collection: Collection,
    music: Music,
    video: Video,
    people: People,
    television: Television,
    tag: Tag,
    page: Page,
    transcode: Transcode,
    user: User,
    feed: Feed,
    category: Category,
    feedback: Feedback,
    comment: Comment,
    review: Review,
    blacklist: Blacklist,
    advertise: Advertise,
    zone: Zone,
    analytic: UserAnalytic,
    sendNotification: SendNotification,
    mediaInformation: MediaInformation,
    channel,
    channelList,
    language,
    updateStatus,
    mediaBlacklist,
    activeMediaPage,
    mediaType,
    shortLink,
    config,
    greetings
  });

export default reducers;
