import { ACTIVE_MEDIA_PAGE } from "../../constants/ActionTypes";

const INIT_STATE = {
  pageName: "movie"
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTIVE_MEDIA_PAGE: {
      return {
        pageName: action.payload.page
      };
    }
    default:
      return state;
  }
};
