import {
  GET_PAGE,
  FETCH_ALL_PAGE_SUCCESS,
  DELETE_PAGE,
  DELETE_PAGE_SUCCESS,
  UPDATE_PAGE,
  UPDATE_PAGE_FAILED,
  FETCH_UPDATE_PAGE_SUCCESS,
  CREATE_PAGE,
  FETCH_CREATE_PAGE_SUCCESS,
  GET_DETAIL_PAGE,
  GET_DETAIL_PAGE_SUCCESS
} from "../../constants/ActionTypes";

export const onGetDetailPage = idPage => {
  return {
    type: GET_DETAIL_PAGE,
    idPage
  };
};

export const getDetailPageSuccess = pagesList => {
  return {
    type: GET_DETAIL_PAGE_SUCCESS,
    payload: pagesList
  };
};

export const onGetPage = queries => {
  return {
    type: GET_PAGE,
    payload: queries
  };
};

export const fetchPagesSuccess = pagesList => {
  return {
    type: FETCH_ALL_PAGE_SUCCESS,
    payload: pagesList
  };
};

export const onDeletePage = idPage => {
  return {
    type: DELETE_PAGE,
    idPage
  };
};

export const deletePageSuccess = pagesList => {
  return {
    type: DELETE_PAGE_SUCCESS,
    payload: pagesList
  };
};

export const onUpdatePage = (idPage, data, callback) => {
  return {
    type: UPDATE_PAGE,
    idPage,
    data,
    callback
  };
};

export const updatePageSuccess = pagesList => {
  return {
    type: FETCH_UPDATE_PAGE_SUCCESS,
    payload: pagesList
  };
};

export const updatePageFailed = error => {
  return {
    type: UPDATE_PAGE_FAILED,
    error
  };
};

export const onCreatePage = (data, callback) => {
  return {
    type: CREATE_PAGE,
    data,
    callback
  };
};

export const createPageSuccess = pagesList => {
  return {
    type: FETCH_CREATE_PAGE_SUCCESS,
    payload: pagesList
  };
};
