import {
  GET_TELEVISION,
  FETCH_ALL_TELEVISION_SUCCESS,
  DELETE_TELEVISION,
  DELETE_TELEVISION_SUCCESS,
  UPDATE_TELEVISION,
  UPDATE_TELEVISION_FAILED,
  FETCH_UPDATE_TELEVISION_SUCCESS,
  CREATE_TELEVISION,
  CREATE_TELEVISION_FAILED,
  FETCH_CREATE_TELEVISION_SUCCESS,
  GET_DETAIL_TELEVISION,
  GET_DETAIL_TELEVISION_SUCCESS
} from "../../constants/ActionTypes";

export const onGetDetailTelevision = idTelevision => {
  return {
    type: GET_DETAIL_TELEVISION,
    idTelevision
  };
};

export const getDetailTelevisionSuccess = televisionsList => {
  return {
    type: GET_DETAIL_TELEVISION_SUCCESS,
    payload: televisionsList
  };
};

export const onGetTelevision = queries => {
  return {
    type: GET_TELEVISION,
    payload: queries
  };
};

export const fetchTelevisionsSuccess = televisionsList => {
  return {
    type: FETCH_ALL_TELEVISION_SUCCESS,
    payload: televisionsList
  };
};

export const onDeleteTelevision = (idTelevision, callback) => {
  return {
    type: DELETE_TELEVISION,
    idTelevision,
    callback
  };
};

export const deleteTelevisionSuccess = televisionsList => {
  return {
    type: DELETE_TELEVISION_SUCCESS,
    payload: televisionsList
  };
};

export const onUpdateTelevision = (idTelevision, data, callback) => {
  return {
    type: UPDATE_TELEVISION,
    idTelevision,
    data,
    callback
  };
};

export const updateTelevisionSuccess = televisionsList => {
  return {
    type: FETCH_UPDATE_TELEVISION_SUCCESS,
    payload: televisionsList
  };
};

export const updateTelevisionFailed = error => {
  return {
    type: UPDATE_TELEVISION_FAILED,
    error
  };
};

export const onCreateTelevision = (data, callback) => {
  return {
    type: CREATE_TELEVISION,
    data,
    callback
  };
};

export const createTelevisionSuccess = televisionsList => {
  return {
    type: FETCH_CREATE_TELEVISION_SUCCESS,
    payload: televisionsList
  };
};

export const createTelevisionFailed = error => {
  return {
    type: CREATE_TELEVISION_FAILED,
    error
  };
};
