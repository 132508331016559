import React from "react";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  onGetMovieBlacklistSuccess,
  onGetMusicBlacklistSuccess,
  onGetVideoBlacklistSuccess,
  onGetReportContentSuccess,
  onApproveMediaBlacklistSuccess
} from "../../appRedux/actions/MediaBlacklist";
import {
  GET_MOVIE_BLACKLIST,
  GET_MUSIC_BLACKLIST,
  GET_VIDEO_BLACKLIST,
  GET_REPORT_CONTENT,
  APPROVE_MEDIA_BLACKLIST
} from "constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import api from "../drivers";
import get from "lodash/get";
import convertOrderBy from "../../util/convertOrderBy";

export const getMovieBlacklist = async params => {
  if (params.order_by) params.order_by = convertOrderBy(params.order_by);
  delete params.channelIdsProps; // remove unused params
  delete params.content_type; // remove unused params

  return await api
    .get(apisDetail.apisMovie, params)
    .then(resData => resData.data);
};

export const getMusicBlacklist = async params => {
  if (params.order_by) params.order_by = convertOrderBy(params.order_by);
  delete params.channelIdsProps; // remove unused params
  delete params.content_type; // remove unused params

  return await api
    .get(apisDetail.apisMusic, params)
    .then(resData => resData.data);
};

export const getVideoBlacklist = async params => {
  if (params.order_by) params.order_by = convertOrderBy(params.order_by);
  delete params.channelIdsProps; // remove unused params
  delete params.content_type; // remove unused params

  return await api
    .get(apisDetail.apisVideo, params)
    .then(resData => resData.data);
};

export const getReportContent = async params => {
  return await api
    .get(apisDetail.apisReportContent, params)
    .then(resData => resData.data);
};

export const onApproveMediaBlacklist = async params => {
  return await api
    .post(apisDetail.apisMediaApprove, params)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.approveSuccess" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.approveFailed" />
      );
      throw error;
    });
};

function* fetchMovieBlackListRequest({ payload }) {
  try {
    const fetchedMovie = yield call(getMovieBlacklist, payload);
    yield put(onGetMovieBlacklistSuccess(fetchedMovie));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchMusicBlackListRequest({ payload }) {
  try {
    const fetchedMusic = yield call(getMusicBlacklist, payload);
    yield put(onGetMusicBlacklistSuccess(fetchedMusic));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchVideoBlackListRequest({ payload }) {
  try {
    const fetchedVideo = yield call(getVideoBlacklist, payload);
    yield put(onGetVideoBlacklistSuccess(fetchedVideo));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchReportContentRequest({ payload }) {
  try {
    const fetchedReportContent = yield call(getReportContent, payload);
    yield put(onGetReportContentSuccess(fetchedReportContent));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchApproveMediaRequest({ payload, callback }) {
  try {
    const approveMedia = yield call(onApproveMediaBlacklist, payload);
    yield put(onApproveMediaBlacklistSuccess(approveMedia));
    callback && callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* fetchMovieBlackList() {
  yield takeEvery(GET_MOVIE_BLACKLIST, fetchMovieBlackListRequest);
}

export function* fetchMusicBlackList() {
  yield takeEvery(GET_MUSIC_BLACKLIST, fetchMusicBlackListRequest);
}

export function* fetchVideoBlackList() {
  yield takeEvery(GET_VIDEO_BLACKLIST, fetchVideoBlackListRequest);
}

export function* fetchReportContent() {
  yield takeEvery(GET_REPORT_CONTENT, fetchReportContentRequest);
}

export function* fetchApproveMediaBlackList() {
  yield takeEvery(APPROVE_MEDIA_BLACKLIST, fetchApproveMediaRequest);
}

export default function* rootSaga() {
  yield all([fork(fetchMovieBlackList)]);
  yield all([fork(fetchMusicBlackList)]);
  yield all([fork(fetchVideoBlackList)]);
  yield all([fork(fetchReportContent)]);
  yield all([fork(fetchApproveMediaBlackList)]);
}
