import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import get from "lodash/get";

class UserProfile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      avatar: ""
    };
  }

  componentDidMount() {
    // this._getName(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // this._getName(nextProps);
  }

  // _getName = props => {
  //   const uName = _.get(props, "authUser.user_profile.name");
  //   if (!_.isEmpty(uName)) {
  //     this.setState({
  //       name: `${uName}`
  //     });
  //   }
  // };

  _renderUserMenuOptions = () => (
    <ul className="gx-user-popover">
      {/* <li onClick={() => {}}>{"My Account"}</li> */}
      {/* <li>{"Connections"}</li> */}
      <li onClick={() => this.props.userSignOut()}>{"Logout"}</li>
    </ul>
  );

  render() {
    const {authUser} = this.props
    const avatar = get(authUser, 'user_profile.avatar') || ''
    const name = get(authUser, 'user_profile.name') || get(authUser, 'user_profile.display_name') || get(authUser, 'user_profile.email')
    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover
          placement="bottomRight"
          content={this._renderUserMenuOptions()}
          trigger="click"
        >
          <Avatar
            src={avatar}
            className="gx-size-40 gx-pointer gx-mr-3"
            alt={name}
          />
          <span className="gx-avatar-name">
            <span>{name}</span>
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          </span>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return {
    authUser
  };
};

export default connect(
  mapStateToProps,
  { userSignOut }
)(UserProfile);
