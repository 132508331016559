import {
  GET_MEDIA_INFORMATION,
  GET_MEDIA_INFORMATION_SUCCESS,
  GET_MEDIA_INFORMATION_ERROR
} from "../../constants/ActionTypes";

const INIT_STATE = {
  data: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MEDIA_INFORMATION: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_MEDIA_INFORMATION_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    }
    case GET_MEDIA_INFORMATION_ERROR: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
};
