import React from "react";
import { FormattedMessage } from "react-intl";

export const MOVIE_TYPE = "movie";
export const VIDEO_TYPE = "video";
export const IMAGE_TYPE = "image";
export const AD_IMAGE_TYPE = "image_ad";
export const AD_TEXT_TYPE = "text";
export const AD_VAST_TYPE = "vast";

export const formItemLayout = {
  labelCol: { xs: 24, sm: 6 },
  wrapperCol: { xs: 24, sm: 14 }
};

export const configTable = {
  bordered: false,
  loading: false,
  pagination: { position: "bottom" },
  size: "default",
  expandedRowRender: undefined,
  title: undefined,
  showHeader: true,
  footer: undefined,
  rowSelection: {},
  scroll: undefined
};

export const quotesTextList = [
  {
    text:
      "None of us, including me, ever do great things. But we can all do small things, with great love, and together we can do something wonderful.",
    author: "Mother Teresa"
  },
  {
    text: "Alone we can do so little; together we can do so much.",
    author: "Helen Keller"
  },
  {
    text: "Talent wins games, but teamwork and intelligence win championships.",
    author: "Michael Jordan"
  },
  {
    text:
      "It is literally true that you can succeed best and quickest by helping others to succeed.",
    author: "Napolean Hill"
  },
  {
    text:
      "Teamwork begins by building trust. And the only way to do that is to overcome our need for invulnerability.",
    author: "Patrick Lencioni"
  },
  {
    text: "If you want to lift yourself up, lift up someone else.",
    author: "Booker T. Washington"
  },
  {
    text:
      "If everyone is moving forward together, then success takes care of itself.",
    author: "Henry Ford"
  },
  {
    text:
      "Many ideas grow better when transplanted into another mind than the one where they sprang up.",
    author: "Oliver Wendell Holmes"
  },
  {
    text:
      "No one can whistle a symphony. It takes a whole orchestra to play it.",
    author: "H.E. Luccock"
  },
  {
    text:
      "Teamwork is the ability to work together toward a common vision. The ability to direct individual accomplishments toward organizational objectives. It is the fuel that allows common people to attain uncommon results.",
    author: "Andrew Carnegie"
  },
  {
    text:
      "Coming together is a beginning, staying together is progress, and working together is success.",
    author: "Henry Ford"
  },
  {
    text: "Politeness is the poison of collaboration.",
    author: "Edwin Land"
  },
  {
    text:
      "There is no such thing as a self-made man. You will reach your goals only with the help of others.",
    author: "George Shinn"
  },
  {
    text:
      "No matter how brilliant your mind or strategy, if you’re playing a solo game, you’ll always lose out to a team.",
    author: "Reid Hoffman"
  },
  {
    text:
      "Great things in business are never done by one person; they're done by a team of people.",
    author: "Steve Jobs"
  },
  {
    text: "Individually, we are one drop. Together, we are an ocean.",
    author: "Ryunosuke Satoro"
  },
  {
    text:
      "Diamonds are nothing more than chunks of coal that stuck to their jobs.",
    author: "Malcolm Forbes"
  }
];

export const labelList = [
  {
    value: "",
    title: <FormattedMessage id="routes.media.labelDefault" />,
    color: "#aaaaaa"
  },
  {
    value: "hot",
    title: <FormattedMessage id="routes.media.labelHot" />,
    color: "#EB5757"
  },
  {
    value: "live",
    title: <FormattedMessage id="routes.media.labelLive" />,
    color: "#27AE60"
  },
  {
    value: "exclusive",
    title: <FormattedMessage id="routes.media.labelExclusive" />,
    color: "#2F80ED"
  }
];

export const listLanguage = [
  {
    language: "vietnamese",
    text: <FormattedMessage id="routes.language.vietnamese" />,
    value: "vi"
  },
  {
    language: "english",
    text: <FormattedMessage id="routes.language.english" />,
    value: "en"
  },
  {
    language: "chinese",
    text: <FormattedMessage id="routes.language.chinese" />,
    value: "zh"
  },
  {
    language: "korean",
    text: <FormattedMessage id="routes.language.korean" />,
    value: "ko"
  },
  {
    language: "japanese",
    text: <FormattedMessage id="routes.language.japanese" />,
    value: "ja"
  },
  {
    language: "french",
    text: <FormattedMessage id="routes.language.french" />,
    value: "fr"
  },
  {
    language: "german",
    text: <FormattedMessage id="routes.language.german" />,
    value: "de"
  },
  {
    language: "italian",
    text: <FormattedMessage id="routes.language.italian" />,
    value: "it"
  },
  {
    language: "russian",
    text: <FormattedMessage id="routes.language.russian" />,
    value: "ru"
  },
  {
    language: "dutch",
    text: <FormattedMessage id="routes.language.dutch" />,
    value: "nl"
  },
  {
    language: "spanish",
    text: <FormattedMessage id="routes.language.spanish" />,
    value: "es"
  }
];
