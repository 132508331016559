import {
  GET_USERS,
  FETCH_ALL_USERS_SUCCESS,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE,
  GET_LIST_PERMISSIONS_SUCCESS,
  BAN_USER,
  BAN_USER_SUCCESS,
  BAN_USER_FAILED,
  RANDOM_PROFILE,
  RANDOM_PROFILE_SUCCESS,
  GET_EVENT_RESULT,
  GET_EVENT_RESULT_SUCCESS,
  UPDATE_EVENT_RESULT_HISTORY,
  UPDATE_EVENT_RESULT_HISTORY_SUCCESS,
  UPDATE_EVENT_RESULT_HISTORY_FAILED
} from "../../constants/ActionTypes";

const INIT_STATE = {
  usersList: [],
  selectedUserID: "",
  data: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        gettingList: true,
        actions: { searchText: action.payload.name }
      };
    case RANDOM_PROFILE:
      return{
        ...state,
        isLoading: true,
      }
    case RANDOM_PROFILE_SUCCESS:
      return{
        ...state,
        isLoading: false,
        actions: action.data
      }

    case GET_EVENT_RESULT:
      return{
        ...state,
        isLoading: true,
      }
    case GET_EVENT_RESULT_SUCCESS:
      return{
        ...state,
        isLoading: false,
        eventResult: action.payload
      }

    case FETCH_ALL_USERS_SUCCESS: {
      return {
        ...state,
        gettingList: false,
        usersList: action.payload
      };
    }
    case GET_USER_PROFILE:
      return {
        ...state,
        profile: null,
        loading: true,
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        loading: false,
      };
    case GET_LIST_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload
      }

    case BAN_USER:
      return {
        ...state,
        banning: true
      }
    case BAN_USER_SUCCESS:
      return {
        ...state,
        banning: false
      }
    case BAN_USER_FAILED:
      return {
        ...state,
        banning: false
      }

      case UPDATE_EVENT_RESULT_HISTORY: {
        console.log("action", action);
        return {
          ...state,
          creating: true,
          data: action
        };
      }
      case UPDATE_EVENT_RESULT_HISTORY_SUCCESS: {
        return {
          ...state,
          creating: false,
          dataResponse: action.data.data
        };
      }
      case UPDATE_EVENT_RESULT_HISTORY_FAILED: {
        return {
          ...state,
          creating: false,
        };
      }
    default:
      return state;
  }
};
