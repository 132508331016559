import { FETCH_ALL_ZONES_SUCCESS, GET_ZONES } from "../../../constants/ActionTypes";

export const onGetZones = queries => {
  return {
    type: GET_ZONES,
    payload: queries
  };
};

export const fetchZonesSuccess = zones => {
  return {
    type: FETCH_ALL_ZONES_SUCCESS,
    payload: zones
  };
};