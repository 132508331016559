import {
  GET_COMMENTS,
  FETCH_ALL_COMMENTS_SUCCESS,
  DELETE_COMMENT_SUCCESS,
  FETCH_UPDATE_COMMENT_SUCCESS,
  GET_DETAIL_COMMENT_SUCCESS,
  GET_DETAIL_COMMENT,
  FETCH_ERROR,
  DELETE_COMMENT
} from "../../constants/ActionTypes";

const INIT_STATE = {
  commentsList: [],
  commentDetail: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DETAIL_COMMENT: {
      return {
        ...state,
        commentDetail: null
      }
    }
    case GET_DETAIL_COMMENT_SUCCESS: {
      return {
        ...state,
        commentDetail: action.payload
      };
    }
    case GET_COMMENTS:
      return {
        ...state,
        loading: true
      };
    case FETCH_ALL_COMMENTS_SUCCESS: {
      return {
        ...state,
        commentsList: action.payload,
        loading: false
      };
    }
    case DELETE_COMMENT: {
      return {
        ...state,
        status: 'DELETING'
      };
    }
    case DELETE_COMMENT_SUCCESS: {
      return {
        ...state,
        status: 'DELETED'
      };
    }
    case FETCH_UPDATE_COMMENT_SUCCESS: {
      return {
        ...state,
        commentsList: action.payload
      };
    }
    case FETCH_ERROR: {
      return {...state, loading: false, error: action.payload, message: ''};
    }
    default:
      return state;
  }
};
