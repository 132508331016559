import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchFeedsSuccess,
  getDetailFeedSuccess,
  createFeedSuccess,
  sendFeedSuccess,
  sendFeedFailed,
  onGetFeedsCollectionSuccess,
  onGetFeedsCollectionFailed,
  onUpdateFeedsCollectionSuccess,
  onUpdateFeedsCollectionFailed,
  onGetFeedShortLinkSuccess,
  onGetFeedShortLinkFailed
} from "../actions/Feed";
import {
  GET_FEEDS,
  GET_DETAIL_FEED,
  DELETE_FEED,
  UPDATE_FEED,
  CREATE_FEED,
  SEND_FEED,
  GET_FEEDS_COLLECTION,
  UPDATE_FEEDS_COLLECTION,
  GET_FEEDS_SHORT_LINK
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import get from "lodash/get";
import api from "../drivers";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";

const getFeeds = async params => {
  return await api
    .get(apisDetail.apisFeeds, params)
    .then(resData => {
      console.log("data", resData);
      return resData.data
    })
    .catch(error => error);
};

const getDetailFeedRequest = async idFeed => {
  const url = `${apisDetail.apisFeeds}/${idFeed}`;
  return await api
    .get(url)
    .then(resData => resData.data)
    .catch(error => error);
};

const getFeedsCollection = async params => {
  const url = `${apisDetail.apisFeedsGetCollection}`;
  return await api
    .get(url, params)
    .then(resData => resData.data)
    .catch(error => error);
};

const deleteFeed = async action => {
  const { idFeed, callback } = action;
  const url = `${apisDetail.apisFeeds}/${idFeed}`;
  return await api
    .delete(url)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.deleteData" />
      );
      callback && callback();
    })
    .catch(error => error);
};

const updateFeed = async action => {
  const { idFeed, data } = action;
  const url = `${apisDetail.apisFeeds}/${idFeed}`;
  return await api
    .put(url, data)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.updateData" />
      );
    })
    .catch(error => error);
};

const updateFeedsCollection = async params => {
  const url = `${apisDetail.apisFeedsUpdateCollection}`;

  return await api
    .post(url, params)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.publishSuccess" />
      );
    })
    .catch(error => error);
};

const createFeed = async data => {
  const url = `${apisDetail.apisFeeds}`;
  return await api
    .post(url, data)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.createData" />
      );
    })
    .catch(error => error);
};

const sendFeed = async ({ id, text }) => {
  const url = `${apisDetail.apisSendFeeds}/${id}`;
  const params = { text };

  return await api
    .post(url, params)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.sendFeedSuccess" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.sendFeedError" />
      );
      throw error;
    });
};

const feedShortLink = async params => {
  const url = `${apisDetail.apisFireBaseDynamicLink}`;

  return await api
    .post(url, params)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.feedShortLinkSuccess" />
      );
      return resData.data;
    })
    .catch(error => error);
};

// -------------------------
// Define Saga actions
// -------------------------
function* fetchFeedsRequest({ payload }) {
  try {
    const fetchedFeeds = yield call(getFeeds, payload);
    yield put(fetchFeedsSuccess(fetchedFeeds));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getDetailFeed({ payload }) {
  const { idFeed } = payload;
  try {
    const fetchedFeed = yield call(getDetailFeedRequest, idFeed);
    yield put(getDetailFeedSuccess(fetchedFeed));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* createFeedRequest({ payload }) {
  const { data } = payload;
  try {
    const fetchedFeed = yield call(createFeed, data);
    yield put(createFeedSuccess(fetchedFeed));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchSendFeedRequest({ payload }) {
  try {
    const fetchSendFeed = yield call(sendFeed, payload);
    yield put(sendFeedSuccess(fetchSendFeed));
  } catch (error) {
    yield put(sendFeedFailed(error));
  }
}

function* fetchFeedsCollectionRequest({ payload, callback }) {
  try {
    const fetchFeedsCollection = yield call(getFeedsCollection, payload);
    yield put(onGetFeedsCollectionSuccess(fetchFeedsCollection));
    callback && callback();
  } catch (error) {
    yield put(onGetFeedsCollectionFailed(error));
  }
}

function* fetchUpdateFeedsCollectionRequest({ payload }) {
  try {
    const fetchUpdateFeedsCollection = yield call(
      updateFeedsCollection,
      payload
    );
    yield put(onUpdateFeedsCollectionSuccess(fetchUpdateFeedsCollection));
  } catch (error) {
    yield put(onUpdateFeedsCollectionFailed(error));
  }
}

function* fetchFeedShortLinkRequest({ payload, callback }) {
  try {
    const fetchFeedShortLink = yield call(feedShortLink, payload);
    yield put(onGetFeedShortLinkSuccess(fetchFeedShortLink));
    callback && callback();
  } catch (error) {
    yield put(onGetFeedShortLinkFailed(error));
  }
}

/**
 * Receive redux action
 */

function* fetchFeeds() {
  yield takeEvery(GET_FEEDS, fetchFeedsRequest);
}

function* fetchDetailFeed() {
  yield takeEvery(GET_DETAIL_FEED, getDetailFeed);
}

function* fetchDeleteFeed() {
  yield takeEvery(DELETE_FEED, deleteFeed);
}

function* fetchUpdateFeed() {
  yield takeEvery(UPDATE_FEED, updateFeed);
}

function* fetchCreateFeed() {
  yield takeEvery(CREATE_FEED, createFeedRequest);
}

function* fetchSendFeed() {
  yield takeEvery(SEND_FEED, fetchSendFeedRequest);
}

function* fetchFeedsCollection() {
  yield takeEvery(GET_FEEDS_COLLECTION, fetchFeedsCollectionRequest);
}

function* fetchUpdateFeedsCollection() {
  yield takeEvery(UPDATE_FEEDS_COLLECTION, fetchUpdateFeedsCollectionRequest);
}

function* fetchFeedShortLink() {
  yield takeEvery(GET_FEEDS_SHORT_LINK, fetchFeedShortLinkRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchFeeds),
    fork(fetchDetailFeed),
    fork(fetchDeleteFeed),
    fork(fetchUpdateFeed),
    fork(fetchCreateFeed),
    fork(fetchSendFeed),
    fork(fetchFeedsCollection),
    fork(fetchUpdateFeedsCollection),
    fork(fetchFeedShortLink)
  ]);
}
