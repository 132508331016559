import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchMusicsSuccess,
  getDetailMusicSuccess,
  updateMusicSuccess,
  updateMusicFailed,
  deleteMusicSuccess,
  createMusicSuccess,
  createMusicFailed,
  createListSongSuccess
} from "../actions/Music";
import {
  GET_MUSIC,
  DELETE_MUSIC,
  UPDATE_MUSIC,
  CREATE_MUSIC,
  CREATE_LIST_SONG,
  GET_DETAIL_MUSIC
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import api from "../drivers";
import get from "lodash/get";
import convertOrderBy from "../../util/convertOrderBy";

export const getMusic = async params => {
  if (params.order_by) params.order_by = convertOrderBy(params.order_by);
  if (params.music_type === "onContent") delete params.music_type;
  delete params.channelIdsProps; // remove unused params

  return await api
    .get(apisDetail.apisMusic, params)
    .then(resData => resData.data);
};

const getDetailMusic = async ({ idMusic }) =>
  await api
    .get(`${apisDetail.apisMusic}/${idMusic}`)
    .then(resData => resData.data);

const deleteMusicRequest = async idMusic =>
  await api
    .delete(`${apisDetail.apisMusic}/${idMusic}`)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.deleteData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.deleteDataError" />
      );
      throw error;
    });

const updateMusic = async ({ idMusic, data }) =>
  await api
    .put(`${apisDetail.apisMusic}/${idMusic}`, data)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.updateData" />,
        <FormattedMessage id="notification.success" />
      );
      return resData;
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.updateDataError" />
      );
      throw error;
    });

const createMusicRequest = async data =>
  await api
    .post(`${apisDetail.apisMusic}`, data)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.createData" />,
        <FormattedMessage id="notification.success" />
      );
      return resData;
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.createDataError" />
      );
      throw error;
    });

const createListSongRequest = async data =>
  await api
    .post(`${apisDetail.apisMusicList}`, data)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.createData" />,
        <FormattedMessage id="notification.success" />
      );
      return resData.data;
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message"),
        <FormattedMessage id="notification.createDataError" />
      );
      throw error;
    });

/**
 * Define Saga action
 */
function* fetchMusicRequest({ payload }) {
  try {
    const fetchedMusic = yield call(getMusic, payload);
    yield put(fetchMusicsSuccess(fetchedMusic));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* fetchMusicDetailRequest(action) {
  try {
    const musicDetail = yield call(getDetailMusic, action);
    yield put(getDetailMusicSuccess(musicDetail));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* updateMusicRequest({ idMusic, data, callback }) {
  try {
    const updatedMusic = yield call(updateMusic, { idMusic, data });
    yield put(updateMusicSuccess(updatedMusic));
    callback && callback();
  } catch (error) {
    yield put(updateMusicFailed(error));
  }
}

function* deleteMusic({ idMusic, callback }) {
  try {
    yield call(deleteMusicRequest, idMusic);
    yield put(deleteMusicSuccess());
    callback && callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* createMusic({ data, callback }) {
  try {
    const createdMusic = yield call(createMusicRequest, data);
    yield put(createMusicSuccess(createdMusic));
    callback && callback();
  } catch (error) {
    yield put(createMusicFailed(error));
  }
}

function* createListSong({ data, callback }) {
  try {
    const listSong = yield call(createListSongRequest, data);
    yield put(createListSongSuccess(listSong));
    callback && callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Define Saga action
 */

// Get redux action
export function* fetchMusic() {
  yield takeEvery(GET_MUSIC, fetchMusicRequest);
}

export function* fetchDetailMusic() {
  yield takeEvery(GET_DETAIL_MUSIC, fetchMusicDetailRequest);
}

export function* fetchDeleteMusic() {
  yield takeEvery(DELETE_MUSIC, deleteMusic);
}

export function* fetchUpdateMusic() {
  yield takeEvery(UPDATE_MUSIC, updateMusicRequest);
}

export function* fetchCreateMusic() {
  yield takeEvery(CREATE_MUSIC, createMusic);
}

export function* fetchCreateListSong() {
  yield takeEvery(CREATE_LIST_SONG, createListSong);
}

export default function* rootSaga() {
  yield all([
    fork(fetchMusic),
    fork(fetchDetailMusic),
    fork(fetchUpdateMusic),
    fork(fetchCreateMusic),
    fork(fetchCreateListSong),
    fork(fetchDeleteMusic)
  ]);
}
