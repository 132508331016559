import {
  GET_CATEGORIES,
  FETCH_ALL_CATEGORIES_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  categoriesList: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categoriesList: action.categoriesList,
        gettingList: true
      };
    case FETCH_ALL_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categoriesList: action.payload,
        gettingList: false
      };
    }
    default:
      return state;
  }
};
