import {
  GET_INTERACTIVE_MOVIE,
  GET_INTERACTIVE_MOVIE_SUCCESS,
  CREATE_INTERACTIVE_PART_LIST,
  CREATE_INTERACTIVE_PART_LIST_SUCCESS,
  CREATE_INTERACTIVE_PART_LIST_FAILED,
  CREATE_INTERACTIVE_MOVIE,
  CREATE_INTERACTIVE_MOVIE_FAILED,
  CREATE_INTERACTIVE_MOVIE_SUCCESS,
  GET_DETAIL_INTERACTIVE_MOVIE,
  GET_DETAIL_INTERACTIVE_MOVIE_SUCCESS,
  DELETE_INTERACTIVE_MOVIE,
  DELETE_INTERACTIVE_MOVIE_SUCCESS,
  UPDATE_INTERACTIVE_MOVIE,
  UPDATE_INTERACTIVE_MOVIE_SUCCESS,
  UPDATE_INTERACTIVE_MOVIE_FAILED,
  GET_DETAIL_INTERACTIVE_PART_MOVIE,
  GET_DETAIL_INTERACTIVE_PART_MOVIE_SUCCESS
} from "../../constants/ActionTypes";

export const getDetailInteractiveMovie = idMovie => {
  return {
    type: GET_DETAIL_INTERACTIVE_MOVIE,
    payload: { idMovie }
  };
};

export const getDetailInteractiveMovieSuccess = movieDetail => {
  return {
    type: GET_DETAIL_INTERACTIVE_MOVIE_SUCCESS,
    payload: movieDetail
  };
};

export const getDetailInteractivePartMovie = idMovie => {
  return {
    type: GET_DETAIL_INTERACTIVE_PART_MOVIE,
    payload: { idMovie }
  };
};

export const getDetailInteractivePartMovieSuccess = movieDetail => {
  return {
    type: GET_DETAIL_INTERACTIVE_PART_MOVIE_SUCCESS,
    payload: movieDetail
  };
};

export const onGetInteractiveMovie = queries => {
  return {
    type: GET_INTERACTIVE_MOVIE,
    payload: queries
  };
};

export const onGetInteractiveMovieSuccess = moviesList => {
  return {
    type: GET_INTERACTIVE_MOVIE_SUCCESS,
    payload: moviesList
  };
};

export const onCreateInteractivePartList = (data, callback) => {
  return {
    type: CREATE_INTERACTIVE_PART_LIST,
    payload: data,
    callback
  };
};

export const onCreateInteractivePartListSuccess = data => {
  return {
    type: CREATE_INTERACTIVE_PART_LIST_SUCCESS,
    payload: data
  };
};

export const onCreateInteractivePartFailed = error => {
  return {
    type: CREATE_INTERACTIVE_PART_LIST_FAILED,
    error
  };
};

export const createInteractiveMovie = (data, callback) => {
  return {
    type: CREATE_INTERACTIVE_MOVIE,
    payload: { data },
    callback
  };
};

export const createInteractiveMovieSuccess = data => {
  return {
    type: CREATE_INTERACTIVE_MOVIE_SUCCESS,
    payload: data
  };
};

export const createInteractiveMovieFailed = error => {
  return {
    type: CREATE_INTERACTIVE_MOVIE_FAILED,
    error
  };
};

export const updateInteractiveMovie = (idMovie, data, callback) => {
  return {
    type: UPDATE_INTERACTIVE_MOVIE,
    idMovie,
    data,
    callback
  };
};

export const updateInteractiveMovieSuccess = moviesList => {
  return {
    type: UPDATE_INTERACTIVE_MOVIE_SUCCESS,
    payload: moviesList
  };
};

export const updateInteractiveMovieFailed = error => {
  return {
    type: UPDATE_INTERACTIVE_MOVIE_FAILED,
    error
  };
};

export const deleteInteractiveMovie = (idMovie, callback) => {
  return {
    type: DELETE_INTERACTIVE_MOVIE,
    idMovie,
    callback
  };
};

export const deleteInteractiveMovieSuccess = () => {
  return {
    type: DELETE_INTERACTIVE_MOVIE_SUCCESS
  };
};
