import {
  GET_SHORT_LINK,
  GET_SHORT_LINK_SUCCESS,
  GET_SHORT_LINK_FAILED
} from "../../constants/ActionTypes";

export const onGetShortLink = (queries, callback) => {
  return {
    type: GET_SHORT_LINK,
    payload: queries,
    callback
  };
};

export const onGetShortLinkSuccess = data => {
  return {
    type: GET_SHORT_LINK_SUCCESS,
    payload: data
  };
};

export const onGetShortLinkFailed = err => {
  return {
    type: GET_SHORT_LINK_FAILED,
    err
  };
};