import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchReviewsSuccess,
  getDetailReviewSuccess,
  deleteReviewSuccess,
  onApproveReviewSuccess
} from "../actions/Review";
import {
  GET_REVIEWS,
  GET_DETAIL_REVIEW,
  DELETE_REVIEW,
  UPDATE_REVIEW,
  APPROVE_REVIEW
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import api from "../drivers";
import { NotificationManager } from "react-notifications";
import { FormattedMessage } from "react-intl";
import get from "lodash/get";

const getReviews = async ({ params, blacklist }) => {
  const url = blacklist
    ? apisDetail.apisBlacklistReviews
    : apisDetail.apisReviews;
  return await api.get(url, params).then(resData => resData.data);
};

const getDetailReviewRequest = async idReview => {
  const url = `${apisDetail.apisReviews}/${idReview}`;
  return await api.get(url).then(resData => resData.data);
};

const deleteReview = async action => {
  const { idReview } = action;
  const url = `${apisDetail.apisReviews}/decline`;
  return await api
    .post(url, idReview)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.deleteData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message", "Error"),
        <FormattedMessage id="notification.deleteDataError" />
      );
      throw error;
    });
};

const approveReviewRequest = async action => {
  const { idReview, approveType } = action;
  const url =
    approveType === "blacklist"
      ? `${apisDetail.apisReviews}/approve`
      : `${apisDetail.apisReviews}/approve_report`;
  return await api
    .post(url, idReview)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.approveData" />,
        <FormattedMessage id="notification.success" />
      );
    })
    .catch(error => {
      NotificationManager.error(
        get(error, "response.data.error.message", "Error"),
        <FormattedMessage id="notification.deleteDataError" />
      );
      throw error;
    });
};

const updateReview = async action => {
  const { idReview, data } = action;
  const url = `${apisDetail.apisReviews}/${idReview}`;
  return await api
    .put(url, data)
    .then(() => {
      NotificationManager.success(
        <FormattedMessage id="notification.successMessage" />,
        <FormattedMessage id="notification.updateData" />
      );
    })
    .catch(error => error);
};

// -------------------------
// Define Saga actions
// -------------------------
function* fetchReviewsRequest({ payload, blacklist }) {
  try {
    const fetchedReviews = yield call(getReviews, {
      params: payload,
      blacklist
    });
    yield put(fetchReviewsSuccess(fetchedReviews));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* getDetailReview({ idReview }) {
  try {
    const fetchedReview = yield call(getDetailReviewRequest, idReview);
    yield put(getDetailReviewSuccess(fetchedReview));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* approveReview(action) {
  try {
    const fetchedApproveReview = yield call(approveReviewRequest, action);
    yield put(onApproveReviewSuccess(fetchedApproveReview));
    action.callback && action.callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* deleteReviewEffect(action) {
  try {
    yield call(deleteReview, action);
    yield put(deleteReviewSuccess());
    action.callback && action.callback();
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Receive redux action
 */

function* fetchReviews() {
  yield takeEvery(GET_REVIEWS, fetchReviewsRequest);
}

function* fetchDetailReview() {
  yield takeEvery(GET_DETAIL_REVIEW, getDetailReview);
}

function* fetchDeleteReview() {
  yield takeEvery(DELETE_REVIEW, deleteReviewEffect);
}

function* fetchApproveReview() {
  yield takeEvery(APPROVE_REVIEW, approveReview);
}

function* fetchUpdateReview() {
  yield takeEvery(UPDATE_REVIEW, updateReview);
}

export default function* rootSaga() {
  yield all([
    fork(fetchReviews),
    fork(fetchDetailReview),
    fork(fetchDeleteReview),
    fork(fetchUpdateReview),
    fork(fetchApproveReview)
  ]);
}
