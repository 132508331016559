import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
const renderView = ({ style, ...props }) => {
  const customStyle = {
    overflow: 'auto'
  };
  return (
    <div {...props} style={{ ...style, ...customStyle }}/>
  )
}
const CustomScrollbars = props => (
  <Scrollbars
    {...props}
    autoHide
    renderView={renderView}
    renderTrackHorizontal={props => (
      <div
        {...props}
        style={{ display: 'none' }}
        className='track-horizontal'
      />
    )}
  />
)

export default CustomScrollbars
