import React from "react";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  fetchAdvertisesSuccess,
  getListAdvertisersSuccess,
  getUserListCampaignsSuccess,
  getDetailCampaignSuccess,
  createCampaignSuccess,
  getListCampaignAdsSuccess,
  getCampaignChartSuccess
} from "../actions/Advertise";
import {
  GET_ADVERTISES,
  GET_ADVERTISERS,
  GET_USER_LIST_CAMPAIGNS,
  GET_DETAIL_CAMPAIGN,
  CREATE_CAMPAIGN,
  GET_LIST_CAMPAIGN_ADS,
  GET_CAMPAIGN_CHART
} from "../../constants/ActionTypes";
import { apisDetail } from "../../constants/APIsURL";
import { fetchError } from "../actions/Common";
import api from "../drivers";
import { NotificationManager } from "react-notifications";
import get from "lodash/get";
import { FormattedMessage } from "react-intl";

export const getReports = async params => {
  return await api
    .get(`${apisDetail.apisAdbutler}/reports`, params)
    .then(resData => resData.data);
};

const getAdvertises = async params => {
  return await api
    .get(apisDetail.apisAdvertises, params)
    .then(resData => resData.data)
    .catch(error => error);
};

const getListAdvertisers = async params => {
  return await api
    .get(`${apisDetail.apisAdvertisement}/advertiser`, params)
    .then(resData => resData.data);
};

const apiGetUserListCampaigns = async ({ userId, params }) => {
  return await api
    .get(
      `${apisDetail.apisAdvertisement}/advertiser/${userId}/campaign`,
      params
    )
    .then(resData => resData.data);
};

const apiGetDetailCampaign = async ({ campaignId }) => {
  return await api
    .get(`${apisDetail.apisAdvertisement}/campaign/${campaignId}`)
    .then(resData => resData.data)
    .catch(error => error);
};

const apiGetCampaignChart = async ({ campaignId }) => {
  return await api
    .get(`${apisDetail.apisAdvertisement}/campaign/${campaignId}/chart`)
    .then(resData => resData.data)
    .catch(error => error);
};

const apiGetListCampaignAds = async ({ campaignId }) => {
  return await api
    .get(`${apisDetail.apisAdvertisement}/campaign/${campaignId}/ads`)
    .then(resData => resData.data)
    .catch(error => error);
};

const apiCreateCampaign = async data => {
  return await api
    .post(`${apisDetail.apisAdvertisement}/campaign/submit_new_campaign`, data)
    .then(resData => {
      NotificationManager.success(
        <FormattedMessage id="notification.createData" />,
        <FormattedMessage id="notification.success" />
      );
      return resData.data
    })
    .catch(error => {
      NotificationManager.error(
        get(error, 'response.data.error.message'),
        <FormattedMessage id="notification.createDataError" />
      );
      throw error
    });
};

// // -------------------------
// // Define Saga actions
// // -------------------------
function* fetchAdvertisesRequest({ payload }) {
  try {
    const fetchedAdvertises = yield call(getAdvertises, payload);
    yield put(fetchAdvertisesSuccess(fetchedAdvertises));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* onGetListAdvertisers({ payload }) {
  try {
    const result = yield call(getListAdvertisers, payload);
    yield put(getListAdvertisersSuccess(result));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* onGetListCampaigns({ payload }) {
  try {
    const result = yield call(apiGetUserListCampaigns, payload);
    yield put(getUserListCampaignsSuccess(result));
  } catch (error) {
    yield put(fetchError(error));
  }
}

function* onGetDetailCampaign({ payload }) {
  try {
    const result = yield call(apiGetDetailCampaign, payload);
    yield put(getDetailCampaignSuccess(result));
  } catch (error) {
    yield put(fetchError(error));
  }
}


function* onGetListCampaignAds({ payload }) {
  try {
    const result = yield call(apiGetListCampaignAds, payload);
    yield put(getListCampaignAdsSuccess(result));
  } catch (error) {
    yield put(fetchError(error));
  }
}

// create campaign
function* onCreateCampaign({ payload, callback }) {
  try {
    const result = yield call(apiCreateCampaign, payload);
    callback && callback();
    yield put(createCampaignSuccess(result));
  } catch (error) {
    yield put(fetchError(error));
  }
}
// create campaign

// get campaign chart
function* onGetCampaignChart({ payload }) {
  try {
    const result = yield call(apiGetCampaignChart, payload);
    yield put(getCampaignChartSuccess(result));
  } catch (error) {
    yield put(fetchError(error));
  }
}
// get campaign chart

/**
 * Receive redux action
 */

// get report
function* fetchAdvertises() {
  yield takeEvery(GET_ADVERTISES, fetchAdvertisesRequest);
}
// get report

// get list ad users
function* fetchListAdvertisers() {
  yield takeEvery(GET_ADVERTISERS, onGetListAdvertisers);
}
// get list ad users

// get user list campaigns
function* fetchListCampaigns() {
  yield takeEvery(GET_USER_LIST_CAMPAIGNS, onGetListCampaigns);
}
// get user list campaigns

function* fetchDetailCampaign() {
  yield takeEvery(GET_DETAIL_CAMPAIGN, onGetDetailCampaign);
}

function* fetchListCampaignAds() {
  yield takeEvery(GET_LIST_CAMPAIGN_ADS, onGetListCampaignAds);
}

function* fetchCreateCampaign() {
  yield takeEvery(CREATE_CAMPAIGN, onCreateCampaign);
}

function* watchGetCampaignChart() {
  yield takeEvery(GET_CAMPAIGN_CHART, onGetCampaignChart);
}

export default function* rootSaga() {
  yield all([
    fork(fetchListAdvertisers),
    fork(fetchAdvertises),
    fork(fetchListCampaigns),
    fork(fetchDetailCampaign),
    fork(fetchListCampaignAds),
    fork(fetchCreateCampaign),
    fork(watchGetCampaignChart)
  ]);
}
