import {
  GET_FEEDS,
  FETCH_ALL_FEEDS_SUCCESS,
  DELETE_FEED_SUCCESS,
  FETCH_UPDATE_FEED_SUCCESS,
  FETCH_CREATE_FEED_SUCCESS,
  GET_DETAIL_FEED_SUCCESS,
  GET_DETAIL_FEED,
  SEND_FEED,
  SEND_FEED_SUCCESS,
  SEND_FEED_FAILED,
  GET_FEEDS_COLLECTION,
  GET_FEEDS_COLLECTION_SUCCESS,
  GET_FEEDS_COLLECTION_FAILED,
  UPDATE_FEEDS_COLLECTION,
  UPDATE_FEEDS_COLLECTION_SUCCESS,
  UPDATE_FEEDS_COLLECTION_FAILED,
  GET_FEEDS_SHORT_LINK,
  GET_FEEDS_SHORT_LINK_SUCCESS,
  GET_FEEDS_SHORT_LINK_FAILED
} from "../../constants/ActionTypes";

const INIT_STATE = {
  feedsList: [],
  feedDetail: {},
  updatingCollection: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DETAIL_FEED: {
      return {
        ...state,
        feedDetail: null
      };
    }
    case GET_DETAIL_FEED_SUCCESS: {
      return {
        ...state,
        feedDetail: action.payload
      };
    }
    case GET_FEEDS:
      return {
        ...state,
        feedsList: action.feedsList,
        gettingList: true
      };
    case FETCH_ALL_FEEDS_SUCCESS: {
      return {
        ...state,
        feedsList: action.payload,
        gettingList: false
      };
    }
    case DELETE_FEED_SUCCESS: {
      return {
        ...state,
        feedsList: action.payload
      };
    }
    case FETCH_UPDATE_FEED_SUCCESS: {
      return {
        ...state,
        feedsList: action.payload
      };
    }
    case FETCH_CREATE_FEED_SUCCESS: {
      return {
        ...state,
        feedDetail: action.payload
      };
    }
    case SEND_FEED:
      return {
        ...state,
        sending: true
      };
    case SEND_FEED_SUCCESS: {
      return {
        ...state,
        sending: false
      };
    }
    case SEND_FEED_FAILED: {
      return {
        ...state,
        sending: false
      };
    }
    case GET_FEEDS_COLLECTION: {
      return {
        ...state,
        gettingCollection: true
      };
    }
    case GET_FEEDS_COLLECTION_SUCCESS: {
      return {
        ...state,
        feedsCollection: action.payload,
        gettingCollection: false
      };
    }
    case GET_FEEDS_COLLECTION_FAILED: {
      return {
        ...state,
        gettingCollection: false
      };
    }

    case UPDATE_FEEDS_COLLECTION: {
      return {
        ...state,
        updatingCollection: true
      };
    }
    case UPDATE_FEEDS_COLLECTION_SUCCESS: {
      return {
        ...state,
        updatingCollection: false
      };
    }
    case UPDATE_FEEDS_COLLECTION_FAILED: {
      return {
        ...state,
        updatingCollection: false
      };
    }

    case GET_FEEDS_SHORT_LINK: {
      return {
        ...state,
        gettingList: true
      };
    }
    case GET_FEEDS_SHORT_LINK_SUCCESS: {
      return {
        ...state,
        dataShortLink: action.payload,
        gettingList: false
      };
    }
    case GET_FEEDS_SHORT_LINK_FAILED: {
      return {
        ...state,
        gettingList: false
      };
    }

    default:
      return state;
  }
};
